import React, { useState, useContext, useCallback, useEffect } from "react";
import { SocketContext } from "../context/socket";
import "./admin.css";
import "./adminResponsive.css";
import Header from "./Header";
import MiniGames from "./MiniGames";
import Footer from "./Footer";
import moment from "moment";
import Countdown from "react-countdown";
import CircularProgress from '@material-ui/core/CircularProgress';
import Logo from "../assets/line_logo_home_page.png";

import NotFound from "./NotFound";
import {
  makeStyles,
  DialogContent,
  DialogActions,
  DialogTitle,
  Paper,
  Grid,
  Dialog,
  Tab,
  Backdrop,
  AppBar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

import { useHistory } from "react-router-dom";

import axios from "axios";
import Appconfig from "../config/config";
import { useParams } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    marginTop: 107,
    marginBottom: 44,
  },
}));

function createData(
  event_id,
  name,
  value,
  action,
  backFirst = 0,
  layFirst = 0,
  backSecond = 0,
  laySecond = 0,
  selection1Id,
  selection2Id
) {
  return { event_id, name, value, action, backFirst, layFirst, backSecond, laySecond, selection1Id, selection2Id };
}

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}
const Completionist = () => <span>You are good to go!</span>;
const EventList = () => {
  const socket = useContext(SocketContext);
  const params = useParams();
  const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
  const classes = useStyles();
  const [chipList, setchipList] = React.useState([]);

  const [eventListData, setEventListData] = React.useState([]);
  const [eventName, setEventName] = React.useState("");
  const [stopLoader, setStopLoader] = React.useState(true);
  const [blocked, setBlocked] = React.useState(false);
  const [dialouge, setDialouge] = React.useState(false);
  const [completedCoundown, setCompletedCoundown] = React.useState(1);

  const eventid = 30775612;
  const backandlay1 = 10301;
  const backandlay2 = 414464;


  const handleClose = () => {
    setDialouge(false);
  }
  useEffect(() => {
    getDashboardData();
    socket.on("dashboard_market_update", (data) => {
      // return false;
      data.marketodds.map((events, index) => {
        var j = 0;
        if (events.market_types) {
          events.market_types.map((market_type, index) => {
            if (j === 0) {
              var i = 0;
              if (market_type.runners) {
                market_type.runners.map((runner, index) => {
                  if (document.getElementById('back-cell-' + runner.event_id + '-' + runner.selection_id) && document.getElementById('lay-cell-' + runner.event_id + '-' + runner.selection_id)) {
                    if (runner.event_id == eventid && (runner.selection_id == backandlay1 || runner.selection_id == backandlay2)) {
                      if (i === 0) {

                        document.getElementById('back-cell-' + runner.event_id + '-' + runner.selection_id).innerHTML = runner.back_1_price;
                        document.getElementById('lay-cell-' + runner.event_id + '-' + runner.selection_id).innerHTML = runner.lay_1_price;
                      } else if (i === 1) {


                        document.getElementById('back-cell-' + runner.event_id + '-' + runner.selection_id).innerHTML = runner.back_1_price;
                        document.getElementById('lay-cell-' + runner.event_id + '-' + runner.selection_id).innerHTML = runner.lay_1_price;
                      }
                      i++;
                    }
                  }
                });
              }

            }
            j++;
          });
        }
      });
    });
  }, [params.event_type_id, completedCoundown]);





  function getDashboardData() {
    var data = JSON.stringify({
      user_id: userInfo._id,
      event_type_id: params.event_type_id,
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}eventsDashboard/getDashboardData`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

        setBlocked(response.data.blocked);
        setDialouge(response.data.blocked);

        setEventName(response.data.resultData[0].name);
        renderEventData(response.data.resultData);
      })
      .catch(function (error) {

      });
  }
  // const [nav, setNav] = React.useState(2);
  const [value, setValue] = React.useState(isMobile ? "0" : "1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderEventData = (eventData) => {
    let tempData = [];
    eventData.map((Crick, index) => {

      Crick.competitions.map((competition, index) => {

        competition.events.map((event, index) => {
          if (event.marketTypes.length) {
            if (event.marketTypes[0].marketRunners.length) {

              if (event.marketTypes[0].marketRunners[0].selection_id) {

                tempData.push(
                  createData(
                    event.event_id,
                    event.event_name,
                    moment(event.open_date, "MM/DD/YYYY hh:mm:ss A").format("YYYY-MM-DD h:mm:ss A"),
                    event.is_inplay == "True" ? "Inplay" : "Going in",
                    event.marketTypes[0].marketRunners[0].back_1_price,
                    event.marketTypes[0].marketRunners[0].lay_1_price,
                    event.marketTypes[0].marketRunners[1].back_1_price,
                    event.marketTypes[0].marketRunners[1].lay_1_price,
                    event.marketTypes[0].marketRunners[0].selection_id,
                    event.marketTypes[0].marketRunners[1].selection_id,
                  )
                );
              }
            }
            else {

            }
          }


        });
      });
    });

    setEventListData(tempData);
    setTimeout(function () { setStopLoader(false) }, 5000);

  };

  const rows = [];

  const history = useHistory();
  const logout = () => {
    sessionStorage.clear();
    sessionStorage.setItem("loggedIn", false);
    history.push("/");
  };



  return (
    <div>
      <Header />
      <Grid className="bodypart custom-container grid-row">
        <Grid item lg={2} xs={2} className="xs_none tl-hide">
          <List
            component="nav"
            className="side_list"
            aria-label="secondary mailbox folders"
          >
            <ListItemLink href="#simple-list" className="active">
              <ListItemText primary="Sports" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="In-Play" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="Cricket" />
            </ListItemLink>
          </List>
        </Grid>
        <Grid item lg={5} xs={12} className="mini-games-grid">
          {

            eventListData.length <= 0 ? (!blocked ? <><Backdrop className={classes.backdrop} open={eventListData.length <= 0 && stopLoader ? true : false}>
             
              <CircularProgress size={40}

                status={'loading'}

              />
            </Backdrop> <div className="load-logo"><img src={Logo} /></div> </>: "") :


              <>

                <BrowserView>
                  <TableContainer component={Paper}>

                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow className="tbl_head">
                          <TableCell>

                            <span className="event-name" >
                              {eventName}
                            </span>
                          </TableCell>
                          <TableCell align="right" colSpan="2">
                            1
                          </TableCell>
                          <TableCell align="right" colSpan="2">
                            x
                          </TableCell>
                          <TableCell align="right" colSpan="2">
                            2
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="tbl_body">

                        {eventListData.map((row) => (
                          // <Link to={"/event-detail/" + row.event_id}>
                          <TableRow key={row.name} onClick={() => history.push(`/event-detail/${row.event_id}`)} className="odd_even_clr">
                            <TableCell className="table_first_row">
                              <div className="text_left" >
                                <p style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width: '273px'

                                }}>{row.name}</p>
                                <p>
                                  <strong>{row.value}</strong>
                                </p>
                              </div>
                              <div>
                                {row.action != "Inplay" ? (
                                  <div className="going-to-play">
                                    <p className="countdown-head going-to-play">
                                      Starts in
                                    </p>

                                    <p className="countdown going-to-play">
                                      <Countdown onStop={() => setCompletedCoundown(completedCoundown + 1)} date={Date.now() + (moment(row.value).format("x") - Date.now())}>
                                        <Completionist />
                                      </Countdown>
                                    </p>

                                    <p className="countdown-foot going-to-play">
                                      {" "}
                                      HH:MM:SS{" "}
                                    </p>
                                  </div>
                                ) : (
                                  <p className="inplay">{row.action}</p>
                                )}
                              </div>
                            </TableCell>


                            <TableCell align="right" className="td-cell" id={`back-cell-${row.event_id}-${row.selection1Id}`}>
                              {row.backFirst}
                            </TableCell>

                            <TableCell align="right" className="td-cell" id={`lay-cell-${row.event_id}-${row.selection1Id}`}>
                              <div>{row.layFirst}</div>
                            </TableCell>


                            <TableCell align="right" className="td-cell">
                              <div>{0}</div>
                            </TableCell>


                            <TableCell align="right" className="td-cell">
                              <div>{0}</div>
                            </TableCell>


                            <TableCell align="right" className="td-cell" id={`back-cell-${row.event_id}-${row.selection2Id}`}>
                              <div>{row.backSecond}</div>
                            </TableCell>

                            <TableCell align="right" className="td-cell" id={`lay-cell-${row.event_id}-${row.selection2Id}`}>
                              <div>{row.laySecond}</div>
                            </TableCell>


                          </TableRow>
                          // </Link>
                        ))}
                      </TableBody>
                    </Table>

                  </TableContainer>

                </BrowserView>

                <MobileView>
                  {eventListData.map((row, index) => (
                    <div className="xs_tbl_block ">
                      <TableContainer component={Paper}>
                        <Link to={"/event-detail/" + row.event_id}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow className="tbl_head">
                                <TableCell
                                  align="right"
                                  colSpan="2"
                                  className="th-mobile"
                                >
                                  <div>
                                    {row.action != "Inplay" ? (
                                      <p className="going-to-play">
                                        <p className="countdown-head going-to-play">
                                          Starts in
                                        </p>

                                        <p
                                          className="countdown going-to-play"
                                          style={{
                                            borderRight: "2px solid #8080807d",
                                          }}
                                        >
                                          <Countdown onStop={() => setCompletedCoundown(completedCoundown + 1)} date={Date.now() + (moment(row.value).format("x") - Date.now())}>
                                            <Completionist />
                                          </Countdown>
                                        </p>

                                        <p className="countdown-foot going-to-play">
                                          {" "}
                                          HH:MM:SS{" "}
                                        </p>
                                      </p>
                                    ) : (
                                      <p
                                        className="inplay"
                                        style={{ borderRight: "2px solid #8080807d" }}
                                      >
                                        {row.action}
                                      </p>
                                    )}
                                  </div>
                                </TableCell>

                                <TableCell align="right" colSpan="5">
                                  <div className="text_left">
                                    <p className="match-name">{row.name}</p>
                                    <p className="match-time">
                                      <strong>{row.value}</strong>
                                    </p>
                                  </div>
                                </TableCell>


                                <TableCell align="right" colSpan="1">
                                  <div className="text_right">
                                    {" "}
                                    <span>F</span> <span>F1</span> <span>BM</span>
                                  </div>
                                </TableCell>


                              </TableRow>
                            </TableHead>
                            <TableBody className="tbl_body">
                              {/* <TableRow key={row.name} className="xs_tbl_first">
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                1
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="4"
                                className="text_center"
                              >
                                X
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                2
                              </TableCell>
                            </TableRow> */}
                              <TableRow key={row.value} className="odd_even_clr">

                                <TableCell align="right">
                                  <p id={`back-cell-${eventid}-${backandlay1}`}>{row.backFirst}</p>
                                </TableCell>

                                <TableCell align="right">
                                  <p id={`lay-cell-${eventid}-${backandlay1}`}>{row.layFirst}</p>
                                </TableCell>
                                <TableCell align="right" >
                                  <span>&nbsp;</span>
                                </TableCell>
                                <TableCell align="right">
                                  <p className="even">0</p>
                                </TableCell>
                                <TableCell align="right">
                                  <p className="odd">0</p>
                                </TableCell>
                                <TableCell align="right" >
                                  <span >&nbsp;</span>
                                </TableCell>

                                <TableCell align="right">
                                  <p id={`back-cell-${eventid}-${backandlay2}`}>{row.backSecond}</p>
                                </TableCell>

                                <TableCell align="right" >
                                  <p id={`lay-cell-${eventid}-${backandlay2}`}>{row.laySecond}</p>
                                </TableCell>

                              </TableRow>
                            </TableBody>
                          </Table>
                        </Link>
                      </TableContainer>
                    </div>
                  ))}
                </MobileView>

              </>

          }
        </Grid>


        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={dialouge}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Blocked
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ width: 400 }}>
              Event blocked by upline
            </Typography>

          </DialogContent>
          <DialogActions>
            <Button autoFocus variant="contained" onClick={handleClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>

      <Footer />
    </div>
  );
};

export default EventList;
