import React, { useState, useEffect } from "react";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Grid,
  List,
  ListItem,
  Switch,
  TextareaAutosize,
  Chip,
  ListItemText,
  FormControl,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Header from "./Header";
import Footer from "./Footer";
// import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  MenuItem,
  InputLabel,
  Select,
  Menu,
  Checkbox,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Dialog,
  TableRow,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Button,
  Modal,
  Typography,
  Fade,
  Backdrop,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { ToastContainer, toast } from "react-toastify";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Appconfig from "../config/config";

import axios from "axios";
import moment from "moment";
import { spacing } from "@material-ui/system";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import "./admin.css";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function MatchSetting() {
  const classes = useStyles();

  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  const columns = [

    { field: 'id', width: 180, headerName: 'S.No.', width: 100 },
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      width: 180,
    },
    {
      field: 'date',
      headerName: 'Date',
      editable: false,
      width: 180,
    },
    {
      field: 'bet_delay',
      headerName: 'Bet Delay',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (
          <TextField size="medium" type="number" value={6} variant="outlined" />

        );
      },
    },
    {
      field: 'f_bet_delay',
      headerName: 'F Bet Delay',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (
          <TextField size="medium" type="number" value={5} variant="outlined" />

        );
      },
    },
    {
      field: 'going_profit',
      headerName: 'GoingProfit',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (
          <TextField size="medium" type="number" value={10000} variant="outlined" />

        );
      },
    },
    {
      field: 'profit_limit',
      headerName: 'ProfitLimit',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (
          <TextField size="medium" type="number" value={10000} variant="outlined" />

        );
      },
    },
    {
      field: 'loss_limit',
      headerName: 'LossLimit',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (
          <TextField size="medium" type="number" value={10000} variant="outlined" />

        );
      },
    },
    {
      field: 'stake_limit',
      headerName: 'StakeLimit',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (
          <TextField size="medium" type="number" value={10000} variant="outlined" />

        );
      },
    },
    {
      field: 'min_stake',
      headerName: 'Min Stake',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (
          <TextField size="medium" type="number" value={1} variant="outlined" />

        );
      },
    },
    {
      field: 'range_matched',
      headerName: 'RangeMatched',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (
          <TextField size="medium" type="number" value={10000} variant="outlined" />

        );
      },
    },
    {
      field: 'range_profit',
      headerName: 'RangeMatched',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (
          <TextField size="medium" type="number" value={10000} variant="outlined" />

        );
      },
    },
    {
      field: 'range_stake',
      headerName: 'RangeStake',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (
          <TextField size="medium" type="number" value={10000} variant="outlined" />

        );
      },
    },
    {
      field: 'is_unmatchbet',
      headerName: 'IsUnmatchbet',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (
          <Checkbox
            defaultChecked
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />

        );
      },
    },
    {
      field: 'tirty_min_check',
      headerName: '30MinCheck',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (
          <Checkbox
            defaultChecked
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />

        );
      },
    },
    {
      field: 'is_apply_all',
      headerName: 'isApplyAll',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (
          <Checkbox
            defaultChecked
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />

        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (

          <Switch
            // checked={rowData.row.is_blocked === "Yes" ? false : true}
            // onChange={() =>
            //   blockUnblockEvent(rowData.row.event_id, rowData.row.event_type)
            // }
            color="secondary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />


        );
      },
    },


  ];

  const rows = [
    { id: 1, name: 'Kolkata T10 (Virtual) v Hyderabad T10 (Virtual)', date: '7/29/2021 6:00:00 PM', bet_delay: 'Free Chip Deposit By MasterGreen	', f_bet_delay: '1000.00', going_profit: '0.00', going_stake: '1000', profit_limit: '1000', loss_limit: '1000', stake_limit: '1000', min_stake: '1000', range_matched: '1000', range_profit: '1000', range_stake: '1000', is_unmatchbet: '1000', tirty_min_check: '1000', is_apply_all: '1000', action: '1000' },
    { id: 2, name: 'Kolkata T10 (Virtual) v Hyderabad T10 (Virtual)', date: '7/29/2021 6:00:00 PM', bet_delay: 'Free Chip Deposit By MasterGreen	', f_bet_delay: '1000.00', going_profit: '0.00', going_stake: '1000', profit_limit: '1000', loss_limit: '1000', stake_limit: '1000', min_stake: '1000', range_matched: '1000', range_profit: '1000', range_stake: '1000', is_unmatchbet: '1000', tirty_min_check: '1000', is_apply_all: '1000', action: '1000' },
    { id: 3, name: 'Kolkata T10 (Virtual) v Hyderabad T10 (Virtual)', date: '7/29/2021 6:00:00 PM', bet_delay: 'Free Chip Deposit By MasterGreen	', f_bet_delay: '1000.00', going_profit: '0.00', going_stake: '1000', profit_limit: '1000', loss_limit: '1000', stake_limit: '1000', min_stake: '1000', range_matched: '1000', range_profit: '1000', range_stake: '1000', is_unmatchbet: '1000', tirty_min_check: '1000', is_apply_all: '1000', action: '1000' },
    { id: 4, name: 'Kolkata T10 (Virtual) v Hyderabad T10 (Virtual)', date: '7/29/2021 6:00:00 PM', bet_delay: 'Free Chip Deposit By MasterGreen	', f_bet_delay: '1000.00', going_profit: '0.00', going_stake: '1000', profit_limit: '1000', loss_limit: '1000', stake_limit: '1000', min_stake: '1000', range_matched: '1000', range_profit: '1000', range_stake: '1000', is_unmatchbet: '1000', tirty_min_check: '1000', is_apply_all: '1000', action: '1000' },
    { id: 5, name: 'Kolkata T10 (Virtual) v Hyderabad T10 (Virtual)', date: '7/29/2021 6:00:00 PM', bet_delay: 'Free Chip Deposit By MasterGreen	', f_bet_delay: '1000.00', going_profit: '0.00', going_stake: '1000', profit_limit: '1000', loss_limit: '1000', stake_limit: '1000', min_stake: '1000', range_matched: '1000', range_profit: '1000', range_stake: '1000', is_unmatchbet: '1000', tirty_min_check: '1000', is_apply_all: '1000', action: '1000' },

  ];

  return (
    <div className={classes.root}>
      <Header />
      <Grid className="bodypart custom-container grid-row">
        <Grid item lg={2} xs={2} className="xs_none tl-hide">
          <List component="nav" className="side_list" aria-label="secondary mailbox folders">
            <ListItemLink href="#simple-list" className="active">
              <ListItemText primary="Sports" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="In-Play" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="Cricket" />
            </ListItemLink>
          </List>
        </Grid>

        <Grid item lg={10} xs={12} className="report-section">

          <div className="datatable_heading">
            Match Setting
          </div>


          <div className="datatable_sort">
            <div className="show_data">

              <span>
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    value={10}
                    onChange={handleChange}
                    name="age"
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'age' }}
                  >
                    <option value={10}>Active</option>
                    <option value={25}>Inactive</option>

                  </NativeSelect>
                </FormControl>
              </span>
              <span>
                <FormControlLabel
                  control={<Checkbox checked={state.checkedA} onChange={handleChange} name="checkedA" />}
                  label="Check to update for all Hypers"
                />
              </span>
            </div>
            <div className="search_data">
              <Button size="small" variant="contained" style={{ margin: 2 }}>All</Button>
              <Button size="small" variant="contained" style={{ margin: 2 }}>Cricket</Button>
              <Button size="small" variant="contained" style={{ margin: 2 }}> Tennis</Button>
              <Button size="small" variant="contained" style={{ margin: 2 }}>Soccer</Button>
            </div>
          </div>



          <div className="datatable_sort">
            <div className="show_data">
              <span>Show</span>
              <span>
                <FormControl className={classes.formControl}>
                  <NativeSelect
                    value={state.age}
                    onChange={handleChange}
                    name="age"
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'age' }}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={500}>500</option>
                  </NativeSelect>
                </FormControl>
              </span>
              <span>entries</span>
            </div>
            <div className="search_data">
              <span>Search : </span>
              <span>
                <form className={classes.root} noValidate autoComplete="off">
                  <TextField id="standard-basic" />
                </form>
              </span>
            </div>
          </div>
          <div style={{ display: 'flex', height: '100vh' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                components={{
                  Toolbar: GridToolbar,
                }}
                filterModel={{
                  items: [{ columnField: 'commodity', operatorValue: 'contains', value: 'rice' }],
                }}
                rows={rows}
                columns={columns}
                pageSize={10}
                disableSelectionOnClick
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Footer />

    </div >
  );
}