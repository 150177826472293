import React from 'react';
import { makeStyles, Grid, List, ListItem, ListItemText, FormControl, NativeSelect, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from './Header'
import Footer from './Footer'
import Appconfig from '../config/config';
import axios from 'axios';
import './admin.css';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function FancyStack() {
  const classes = useStyles();
  const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));

  const [accountList, SetAccountList] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(10);
  const [formData, setFormData] = React.useState({
    "user_id": userInfo._id,
    "type": "0",
    "from_date": moment().add(-1, 'days').format('YYYY-MM-DD'),
    "to_date": moment().format('YYYY-MM-DD'),
  })
  React.useEffect(() => {  window.scroll(0,0);
    getFancy();
  }, []);
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  const columns = [

    { field: 'id', flex: 1, headerName: 'S.No.', width: 100 },
    {
      field: 'user_name',
      headerName: 'User Name',
      editable: true,
      flex: 1,
    },
    {
      field: 'totalStake',
      headerName: 'Total Stake',
      editable: true,
      flex: 1,
    },

  ];

  // const rows = [
  //   { id: 1, date: '2021-07-05 09:11:09', description: 'Free Chip Deposit By MasterGreen	', credit: '1000.00', debit: '0.00', balance: '1000' },
  //   { id: 2, date: '2022-07-05 09:11:09', description: 'Free Chip Deposit By MasterGreen	', credit: '1000.00', debit: '0.00', balance: '1000' },
  //   { id: 3, date: '2021-07-05 09:11:09', description: 'Free Chip Deposit By MasterGreen	', credit: '1000.00', debit: '0.00', balance: '1000' },
  //   { id: 4, date: '2021-07-05 09:11:09', description: 'Free Chip Deposit By MasterGreen	', credit: '1000.00', debit: '0.00', balance: '1000' },
  //   { id: 5, date: '2021-07-05 09:11:09', description: 'Free Chip Deposit By MasterGreen	', credit: '1000.00', debit: '0.00', balance: '1000' },
  //   { id: 6, date: '2021-07-05 09:11:09', description: 'Free Chip Deposit By MasterGreen	', credit: '1000.00', debit: '0.00', balance: '1000' },
  //   { id: 7, date: '2021-07-05 09:11:09', description: 'Free Chip Deposit By MasterGreen	', credit: '1000.00', debit: '0.00', balance: '1000' },
  // ];

  function getFancy() {
    var data = JSON.stringify({
      "user_id": userInfo._id,
    });

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}reports/fancyStack`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        SetAccountList(response.data.resultData);
        let tmpAccountList = response.data.resultData;
        const newChipList = tmpAccountList.map((accountList, index) => {
          tmpAccountList[index]["id"] = index + 1;
        });
        SetAccountList(tmpAccountList);
        //console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div className={classes.root}>
      <Header />
      <Grid className="bodypart custom-container grid-row">
        <Grid item lg={2} xs={2} className="xs_none tl-hide">
          <List component="nav" className="side_list" aria-label="secondary mailbox folders">
            <ListItemLink href="#simple-list" className="active">
              <ListItemText primary="Sports" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="In-Play" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="Cricket" />
            </ListItemLink>
          </List>
        </Grid>

        <Grid item lg={10} xs={12} className="report-section">

          <div className="datatable_heading">
            Fancy Stack
          </div>
          <Grid container spacing={0} >
            <Grid item sm={6} xs={5} md>
              <div className="show_data">
                <span className="showentries">Show</span>
                <span>
                  <FormControl className={classes.formControl}>
                    <NativeSelect
                      value={pageCount}
                      onChange={(e) => setPageCount(e.target.value)}
                      name="age"
                      className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'age' }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </NativeSelect>
                  </FormControl>
                </span>
                <span className="showentries">entries</span>
              </div>
            </Grid>
            <Grid item sm={6} xs={6} md>
              <div className="search_data" style={{ display: 'flex', placeItems: 'center' }}>
                <span className="showentries">Search:</span>
                <span>
                  <form className={classes.root} noValidate autoComplete="off">
                    <TextField variant="outlined" size="small" id="standard-basic" />
                  </form>
                </span>
              </div>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', height: '100vh' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                // components={{
                //   Toolbar: GridToolbar,
                // }}
                // filterModel={{
                //   items: [{ columnField: 'commodity', operatorValue: 'contains', value: 'rice' }],
                // }}
                rows={accountList}
                columns={columns}
                pageSize={pageCount}
                disableSelectionOnClick
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Footer />

    </div >
  );
}