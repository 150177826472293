import React, { useEffect } from "react";
import { makeStyles, Chip, Grid, Button } from "@material-ui/core";

import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import StarBorder from "@material-ui/icons/StarBorder";
import virtual from "../assets/a.png";
import tennis from "../assets/b.png";
import cricket from "../assets/c.png";
import soccer from "../assets/d.png";
import { Router, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import t20 from "../assets/casino-icons/12.png";
import ltp from "../assets/casino-icons/7.png";
import l7ud from "../assets/casino-icons/2.png";
import dt20 from "../assets/casino-icons/10.png";
import aaa from "../assets/casino-icons/1.png";
import ab from "../assets/casino-icons/16.png";
import bac from "../assets/casino-icons/13.png";
import c32 from "../assets/casino-icons/14.png";
import lm from "../assets/casino-icons/18.png";
import vtp from "../assets/casino-icons/6.png";
import hr from "../assets/casino-icons/3.png";
import rul from "../assets/casino-icons/5.png";
import ezugi from "../assets/casino-icons/16.png";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Slide from "@material-ui/core/Slide";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  toolbar: {
    minHeight: '8%'
  },
}));

export default function SideDrawer() {
  const history = useHistory();
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);
  const [path, setpath] = React.useState("");

  const [reportOpen, setReportOpen] = React.useState(false);
  const [state, setState] = React.useState({
    left: false,
  });

  const [openCListMenu, setopenCListMenu] = React.useState(false);
  const [openSettings, setopenSettings] = React.useState(false);
  const [openBettingList, setopenBettingList] = React.useState(false);
  const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));

  const [dialougeOpen, setdialougeOpen] = React.useState(false);
  const [fancyMessage, setfancyMessage] = React.useState(false);

  const logout = () => {
    sessionStorage.clear();
    sessionStorage.setItem("loggedIn", false);
    history.push("/");
  };

  function handleDialougeOpen(val) {
    setopenSettings(null);
    setdialougeOpen(true);
    setfancyMessage(val);
    setState({ ...state, ["left"]: false });
  }

  const handleDialogeClose = () => {
    setdialougeOpen(false);
  };

  const handleDialougeSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    window.scroll(0, 0)
    setState({ ...state, ["left"]: false });
    // logic to close sidebar
  }, [history.location]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleBettingList = (event) => {
    setopenBettingList(!openBettingList);
  };

  const handleCloseBettingList = (event) => {
    setopenBettingList(null);
  };

  const handleSettings = (event) => {
    setopenSettings(!openSettings);
  };

  const handleCloseSetting = () => {
    setopenSettings(null);
  };
  const handleCloseCList = () => {
    setopenCListMenu(null);
  };

  const handleCListClick = (event) => {
    setopenCListMenu(!openCListMenu);
  };

  const handleReportClick = () => {
    setReportOpen(!reportOpen);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      {userInfo.user_type == "User" ? (
        <>
          <Grid container spacing={0} className="drawer-icon-play-section">
            <Grid item xs={3} onClick={() => history.push("/inplay")}>
              <div className="drawer-icon-play-block">
                {/* <p>BLUE EX</p> */}
                <p>inplay</p>
              </div>
            </Grid>
            <Grid item xs={3} onClick={() => history.push("/sports")}>
              <div className="drawer-icon-play-block">
                {/* <p>BLUE EX</p> */}
                <p>sports</p>
              </div>
            </Grid>
            <Grid item xs={3} onClick={() => history.push("/casinos")}>
              <div className="drawer-icon-play-block">
                {/* <p>BLUE EX</p> */}
                <p>casino</p>
              </div>
            </Grid>
            <Grid
              item
              xs={3}
              onClick={() => {
                history.push("/settlement");
              }}
            >
              <div className="drawer-icon-play-block">
                {/* <p>BLUE EX</p> */}
                <p>home</p>
              </div>
            </Grid>
          </Grid>

          <Chip label="Sports" className="drawer-heading" />

          <Grid container spacing={0} className="drawer-icon-sports-section">
            <Grid
              item
              xs={6}
              className="drawer-icon-sports-block"
              onClick={() => history.push("/event-list/4")}
            >
              <img alt="sgBetImage" src={cricket} />
              <p>Cricket</p>
            </Grid>
            <Grid
              item
              xs={6}
              className="drawer-icon-sports-block"
              onClick={() => history.push("/event-list/1")}
            >
              <img alt="sgBetImage" src={soccer} />
              <p>Soccer</p>
            </Grid>
            <Grid
              item
              xs={6}
              className="drawer-icon-sports-block"
              onClick={() => history.push("/event-list/2")}
            >
              <img alt="sgBetImage" src={tennis} />
              <p>Tennis</p>
            </Grid>
            <Grid
              item
              xs={6}
              className="drawer-icon-sports-block"
            // onClick={() => history.push("/inplay")}
            >
              <img alt="sgBetImage" src={virtual} />
              <p>Virtual Sports</p>
            </Grid>
          </Grid>

          {/* <Chip label="Casino"  onClick={handleClick} className="drawer-heading" /> */}
          <Divider />
          <List
            // component="nav"
            aria-labelledby="nested-list-subheader"
            className="drawer-heading"
          >
            <ListItem button onClick={handleClick} className="drawer-heading">
              <ListItemText primary="Casino" />

              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List className="drawer-listing">
                <ListItem button onClick={() => history.push("/casino-bets/t20")}>
                  <ListItemIcon>
                    <img alt="sgBetImage" src={t20} className="drawer-icon" />
                  </ListItemIcon>
                  <ListItemText primary="Teenpatti T20" />
                </ListItem>
                <Divider />

                <ListItem button onClick={() => history.push("/casino-bets/t20")}>
                  <ListItemIcon>
                    <img alt="sgBetImage" src={ltp} className="drawer-icon" />
                  </ListItemIcon>
                  <ListItemText primary="Teenpatti One Day" />
                </ListItem>
                <Divider />

                <ListItem button onClick={() => history.push("/casino-bets/t20")}>
                  <ListItemIcon>
                    <img alt="sgBetImage" src={l7ud} className="drawer-icon" />
                  </ListItemIcon>
                  <ListItemText primary="7 Up Down" />
                </ListItem>
                <Divider />

                <ListItem button onClick={() => history.push("/casino-bets/t20")}>
                  <ListItemIcon>
                    <img alt="sgBetImage" src={dt20} className="drawer-icon" />
                  </ListItemIcon>
                  <ListItemText primary="Dragon Tiger" />
                </ListItem>
                <Divider />

                <ListItem button onClick={() => history.push("/casino-bets/t20")}>
                  <ListItemIcon>
                    <img alt="sgBetImage" src={aaa} className="drawer-icon" />
                  </ListItemIcon>
                  <ListItemText primary="Amar Akbar Anthony" />
                </ListItem>
                <Divider />

                <ListItem button onClick={() => history.push("/casino-bets/t20")}>
                  <ListItemIcon>
                    <img alt="sgBetImage" src={ab} className="drawer-icon" />
                  </ListItemIcon>
                  <ListItemText primary="Andar Bahar" />
                </ListItem>
                <Divider />

                <ListItem button onClick={() => history.push("/casino-bets/t20")}>
                  <ListItemIcon>
                    <img alt="sgBetImage" src={bac} className="drawer-icon" />
                  </ListItemIcon>
                  <ListItemText primary="Baccarat" />
                </ListItem>
                <Divider />

                <ListItem button onClick={() => history.push("/casino-bets/t20")}>
                  <ListItemIcon>
                    <img alt="sgBetImage" src={c32} className="drawer-icon" />
                  </ListItemIcon>
                  <ListItemText primary="32 Card Casino" />
                </ListItem>
                {/* <Divider /> */}
              </List>
            </Collapse>
          </List>
          <Divider />
        </>
      ) : (
        
          <List className="drawer-listing">
            <ListItem button>
              <ListItemText primary="Dashboard"
                onClick={() => history.push("/dashboard")}
              />
            </ListItem>
            <Divider />





            <ListItem button>
              <ListItemText
                primary="Settlement"
                onClick={() => history.push("/settlement")}
              />
            </ListItem>

            <Divider />
            <ListItem button>
              <ListItemText
                primary="Settled"
                onClick={() => history.push("/settled")}
              />
            </ListItem>
        
        </List>
      )}
    </div>
  );

  return (
    <>
      <Button onClick={toggleDrawer("left", true)} className="hamburgerIcon">
        <MenuIcon />
      </Button>

      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        className={`left-side-bar ${userInfo.user_type != "User" ? "side-bar-admin" : ""}`}
      >

        {userInfo.user_type != "User" ?
          <>
            <div style={{ padding: 5, textAlign: 'end' }}>
              <CloseIcon onClick={() => setState({ left: false })} />
            </div>
            <div className={classes.toolbar} /></> : ""}

        {list("left")}
      </Drawer>
      <Dialog
        open={dialougeOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogeClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">ON/OFF Setting</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {fancyMessage
              ? " Are you sure you want to STOP showing the FANCY"
              : "Are you sure you want to STOP showing the ODDS"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleDialogeClose}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleDialougeSubmit}
            color="primary"
            autoFocus
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
