import React from 'react'

import { Grid, Button, Link, Card, CardActionArea, CardContent, Typography } from '@material-ui/core'



const MiniGames = (props) => {


    return (
        <div>
            <Grid container spacing={1}>
                <Grid item sm={12} md={12} xs={12} lg={12}>
                    <Card className="mini-games">
                        <CardActionArea>
                            <img alt="sgBetImage" className="event_image" src={props.image} />
                            <CardContent className="mini-games-content">
                                <Typography>
                                    <Link href="javascript:void(0)" className="mini-games-link">
                                        <Button variant="contained" color="primary" className="mini-games-btn" >
                                            {props.event}
                                        </Button><p>Play Now</p>
                                    </Link>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default MiniGames;