import React from 'react';
import { makeStyles, Grid, List, TextField, ListItem, ListItemText, Select, InputLabel, FormControl, NativeSelect, Button, MenuItem } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from './Header'
import Footer from './Footer'
import Appconfig from '../config/config';
import axios from 'axios';
import './admin.css';
import moment from 'moment';
import { useParams } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function LiveBethistory() {
  const params = useParams();
  const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
  const classes = useStyles();
  const [pageCount, setPageCount] = React.useState(10);
  const [formData, setFormData] = React.useState({
    "user_id":userInfo._id,
    'event_type': "",
    'status': "Open",
    'from_date': moment().subtract(1, 'days').format("YYYY-MM-DD"),
    'to_date': moment().format("YYYY-MM-DD"),
  })
  const [betHistoryList, SetBetHistoryList] = React.useState([]);

  const [startStopLoader, setStartStopLoader] = React.useState(false);
  React.useEffect(() => {
    window.scroll(0, 0);
    getBetHistory();
  }, [params.user_id]);
  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  const columns = [
    { field: 'id', width: 91, headerName: 'S.No.' },
    {
      field: 'createdAt',
      headerName: 'Datetime',
      editable: true,
      width: 200,
      renderCell: rowData => {
        return (
          moment(rowData.row.createdAt).format("DD-MMM-YYYY hh:mm:ss")

        );
      }

    },

    {
      field: '_id',
      headerName: 'Round Id',
      editable: true,
      width: 113,
      renderCell: rowData => {
        return (
          (rowData.row._id).substr(-6)
        );
      }
    },
    {
      field: 'event_name',
      headerName: 'Bet details',
      editable: true,
      width: 180,
    },


    {
      field: 'runner_name',
      headerName: 'Selection',
      editable: true,
      width: 180,
    },
    {
      field: 'betting_type',
      headerName: 'Bet type',
      editable: true,
      width: 108,
    },
    {
      field: 'is_back',
      headerName: 'Type',
      editable: true,
      width: 87,
      renderCell: rowData => {
        return (
          rowData.row.is_back ? "Back" : "Lay"

        );
      },


    },
    {
      field: 'price_val',
      headerName: 'Match Odds',
      editable: true,
      width: 134,
    },
    {
      field: 'stake',
      headerName: 'Stake',
      editable: true,
      width: 92,
    },
    {
      field: 'p_l',
      headerName: 'P&L',
      editable: true,
      width: 82,
      renderCell: rowData => {
        return (
          (rowData.row.p_l).toFixed(2)
        );
      }
    },
  ];

  // const rows = [
  //   { id: 1, chips: '', description: '0', liability: '00', wallet: '0.00', up: '0.00', down: '0.00', },
  // ];

  const [state, setState] = React.useState({
    from_date: moment().format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    game_type: "4",
    event_name: ""
  });

  console.clear();
  // console.log('state', state);

  const handleChange = (event) => {
    // alert(event.target.value);
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  function getBetHistory() {
    setStartStopLoader(true);
    var data = {
      "user_id": params.user_id == 0 ? userInfo._id : params.user_id,
      'event_type': state.game_type,
      'event_name': state.event_name,
      'status': "Open",

    }


    // console.log('data', data)
    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}reports/livebetHistory`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // //console.log(JSON.stringify(response.data));
        SetBetHistoryList(response.data.resultData);
        let tmpAccountList = response.data.resultData;
        const newChipList = tmpAccountList.map((betHistoryList, index) => {
          tmpAccountList[index]["id"] = index + 1;
        });
        SetBetHistoryList(tmpAccountList);
        // //console.log(JSON.stringify(response.data));
        setStartStopLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div className={classes.root}>
      <Header />
      <Grid className="bodypart custom-container grid-row">
        <Grid item lg={2} xs={2} className="xs_none tl-hide">
          <List component="nav" className="side_list" aria-label="secondary mailbox folders">
            <ListItemLink href="#simple-list" className="active">
              <ListItemText primary="Sports" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="In-Play" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="Cricket" />
            </ListItemLink>
          </List>
        </Grid>

        <Grid item lg={10} xs={12} className="report-section">

          <div className="datatable_heading">
            Client Live Bet Report &nbsp;

          </div>








          <Grid container spacing={0} >
            {/* <Grid item sm={3} xs={6} md>

                <TextField className="dates" value={state.from_date } label="From Date" variant="outlined" size="small" type="date" onChange={handleChange} name="from_date" />
              </Grid>
              <Grid item sm={3} xs={6} md>
                <TextField className="dates" value={state.to_date } label="To Date" variant="outlined" size="small" type="date" onChange={handleChange} name="to_date" />
              </Grid> */}
            <Grid item sm={3} xs={6} md>
              <FormControl style={{ width: "100%" }} size="small" variant="outlined" >
                <InputLabel id="demo-simple-select-outlined-label">Game Type</InputLabel>
                <Select
                  value={state.game_type}
                  onChange={handleChange}
                  name="game_type"
                  // className={classes.selectEmpty}
                  inputProps={{ 'aria-label': 'game_type' }}
                  label="Game Type"
                // className="gametype"
                >
                  <MenuItem value={''} selected="">All Game</MenuItem>
                  <MenuItem value={1}>Soccer</MenuItem>
                  <MenuItem value={2}>Tennis</MenuItem>
                  <MenuItem value={4}>Cricket</MenuItem>
                  <MenuItem value={30}>7ud</MenuItem>
                  <MenuItem value={40}>Andar-Bahar</MenuItem>
                  <MenuItem value={50}>Teen Patti One Day</MenuItem>
                  <MenuItem value={60}>Teen Patti T20</MenuItem>
                  <MenuItem value={70}>Dragon Tiger</MenuItem>
                  <MenuItem value={80}>Amar Akbar Anthony</MenuItem>
                  <MenuItem value={90}>32 Cards</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={3} xs={6} md>
              <FormControl className={classes.formControl}>
                <TextField onChange={handleChange} name="event_name" variant="outlined" label="Event name" size="small" type="text" />
              </FormControl>
            </Grid>


            <Grid item sm={3} xs={6} md>
              <Button variant="contained" color="secondary">
                Clear
              </Button>
              &nbsp;
              <Button variant="contained" onClick={() => getBetHistory()} color="primary" >
                Filter
              </Button>


            </Grid>

          </Grid>
          <Grid container spacing={0} >
            <Grid item sm={6} xs={5} md>
              <div className="show_data">
                <span className="showentries">Show</span>
                <span>
                  <FormControl className={classes.formControl}>
                    <NativeSelect
                      value={pageCount}
                      onChange={(e) => setPageCount(e.target.value)}
                      name="age"
                      className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'age' }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </NativeSelect>
                  </FormControl>
                </span>
                <span className="showentries">entries</span>
              </div>
            </Grid>
            {/* <Grid item sm={6} xs={6} md>
              <div className="search_data" style={{ display: 'flex', placeItems: 'center' }}>
                <span className="showentries">Search:</span>
                <span>
                  <form className={classes.root} noValidate autoComplete="off">
                    <TextField variant="outlined" size="small" id="standard-basic" />
                  </form>
                </span>
              </div>
            </Grid> */}
          </Grid>


          <div style={{ display: 'flex', height: '100vh' }}>
            <div style={{ flexGrow: 1 }}>
              {startStopLoader ? <div className="loaderDiv" > <CircularProgress /></div> : <DataGrid
                // components={{
                //   Toolbar: GridToolbar,
                // }}
                // filterModel={{
                //   items: [{ columnField: 'commodity', operatorValue: 'contains', value: 'rice' }],
                // }}

                rows={betHistoryList}
                columns={columns}
                pageSize={pageCount}
                disableSelectionOnClick


                getRowClassName={(params) =>
                  `${params.row.is_back == 1 ? 'back-row' : 'lay-row'}`
                }

              />
              }
            </div>
          </div>


        </Grid>
      </Grid>

      <Footer />
    </div >
  );
}