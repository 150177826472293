import React, { useState, useEffect } from "react";
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import SearchBar from "material-ui-search-bar";

import {
    Table,
    Link,
    IconButton,
    TableBody,
    MenuItem,
    FormControl,
    ButtonGroup,
    InputLabel,
    Select,
    Menu,
    NativeSelect,
    Checkbox,
    TableCell,
    TableContainer,
    TableHead,
    Paper,
    Dialog,
    TableRow,
    DialogActions,
    DialogTitle,
    DialogContentText,
    DialogContent,
    Grid,
    Button,
    List,
    ListItemText,
    Modal,
    Typography,
    ListItem,
    Fade,
    Backdrop,
    TextField,
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Appconfig from "../config/config";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import moment from "moment";
import CancelIcon from "@material-ui/icons/Cancel";
import { spacing } from "@material-ui/system";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";

import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}
const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        // minWidth: 340,
    },

    root: {
        // width: '100%',
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 10,
    },
    headerdiv: {
        // marginTop: 10,
        padding: 15,
    },
    button: {
        margin: theme.spacing(1),
    },
}));
let CancelToken;
const AccountList = () => {

    const history = useHistory();
    const params = useParams();
    const userId = params.user_id;
    const user_type = params.user_type;
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [userTypeArr, setUserTypeArr] = React.useState(Appconfig.userTypes);
    const [opens, setOpens] = React.useState(false);
    const [anchorEl, setanchorEl] = React.useState(false);
    const [userList, setUserList] = React.useState([]);
    const [passwordModalOpen, setpasswordModalOpen] = React.useState(false);
    const [chipDrawModal, setChipDrawModal] = React.useState(false);
    const [masterBalance, setMasterBalance] = React.useState("");
    const [childBalance, setChildBalance] = React.useState("");
    const [pageCount, setPageCount] = React.useState(10);
    const [action, setAction] = React.useState("");
    const [chekeduser, setSelectedUser] = React.useState(Array);
    const [selectedIndex, setSelectedUserIndex] = React.useState([]);
    const [filterUserList, setFilterUserList] = React.useState([]);
    const [searched, setSearched] = React.useState("");
    const [passwordData, setPasswordData] = useState({
        name: "",
        user_name: "",
        id: "",
        password: "",
        confirm_password: "",
    });

    const [chipDraw, setchipDrawData] = useState({
        id: "",
        password: "",
        free_chips: 0,
        Deposite: "",
        userName: "",
    });


    const [anchorElMore, setAnchorElMore] = React.useState(null);
    const [isBalanceUpdated, setIsBalanceUpdated] = React.useState(false);
    const [tempitems, setTempItem] = React.useState("");
    function handleClickMore(event, _id, id, user_type) {
        const items = <>
            <MenuItem onClick={() => history.push(`/accountinfo/${_id}`)}>Statement</MenuItem>
            <MenuItem onClick={() => history.push(`/profitloss/${_id}`)}>Profit Loss</MenuItem>
            <MenuItem onClick={() => history.push(`/chips/${_id}/${user_type.toLowerCase().replace(" ", "_")}`)}>Stake</MenuItem>
            <MenuItem onClick={() => history.push(`/user_gen_settings/${_id}`)}>User Genral Setting</MenuItem>
            {/* <MenuItem onClick={() => history.push('/matchgensetting')}>Match Setting</MenuItem> */}
            <MenuItem onClick={() => history.push('/blockmarket')}>Block Market</MenuItem>
            <MenuItem onClick={() => changePassswordModalOpen(id)}>Change Password</MenuItem></>;
        setTempItem(items)
        setAnchorElMore(event.currentTarget);
    };

    const handleCloseMore = () => {
        setAnchorElMore(null);
    };

    const handleActionChange = (e) => {
        setAction(e.target.value);
    };
    function calculateParentFreeChipDep() {
        return parseInt(masterBalance) - parseInt(chipDraw.free_chips ? chipDraw.free_chips : 0);
    }

    function calculateChildFreeChipDep() {
        return parseInt(childBalance) + parseInt(chipDraw.free_chips ? chipDraw.free_chips : 0);
    }

    function calculateParentFreeChipDraw() {
        return parseInt(masterBalance) + parseInt(chipDraw.free_chips ? chipDraw.free_chips : 0);
    }

    function calculateChildFreeChipDraw() {
        return parseInt(childBalance) - parseInt(chipDraw.free_chips ? chipDraw.free_chips : 0);
    }
    const chipDrawInputChange = (event) => {
        setchipDrawData({
            ...chipDraw,
            [event.target.name]: event.target.value,
        });
        console.log(chipDraw);
    };

    const chipDrawModalClose = () => {
        setChipDrawModal(false);
    };

    function chipDrawModalOpen(index, deposite) {

        chipDraw.id = userList[index]._id;
        chipDraw.userName = userList[index].name;
        chipDraw.Deposite = deposite;
        chipDraw.free_chips = "";
        chipDraw.password = "";
        setChipDrawModal(true);
        setanchorEl(false);
        getUserBalance(userInfo._id).then((value) => setMasterBalance(value));
        getUserBalance(chipDraw.id).then((value) => setChildBalance(value));
    }

    const handleChipDrawSubmit = (e) => {

        e.preventDefault();
        var data = JSON.stringify({
            action: "Debit",
            user_id: chipDraw.id,
            master_id: userInfo._id,
            master_password: chipDraw.password,
            amount: chipDraw.free_chips,
            remarks: "Free chip deposit",
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/chipsDeposit&withdraw`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setIsBalanceUpdated(!isBalanceUpdated);
                if (response.data.result) {
                    notify(response.data.message, "success");
                } else {
                    notify(response.data.message, "error");
                }
                //console.log(JSON.stringify(response.data));
                chipDrawModalClose();
                setchipDrawData({
                    id: "",
                    password: "",
                    free_chips: Number,
                    Deposite: "",
                    userName: "",
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const handleChipDepositeSubmit = (e) => {
        e.preventDefault();
        var data = JSON.stringify({
            action: "Credit",
            user_id: chipDraw.id,
            master_id: userInfo._id,
            master_password: chipDraw.password,
            amount: chipDraw.free_chips,
            remarks: "Free chip deposit",
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/chipsDeposit&withdraw`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setIsBalanceUpdated(!isBalanceUpdated);
                if (response.data.result) {
                    console.log(response.data.message);
                    notify(response.data.message, "success");
                } else {
                    notify(response.data.message, "error");
                }
                //console.log(JSON.stringify(response.data));
                chipDrawModalClose();
                setchipDrawData({
                    id: "",
                    password: "",
                    free_chips: 0,
                    Deposite: "",
                    userName: "",
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const handlePasswordChange = (event) => {
        setPasswordData({
            ...passwordData,
            [event.target.name]: event.target.value,
        });
        let nextkey = event.target.name == "confirm_password" ? "password" : "confirm_password";
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            // const { formData } = this.state;
            console.log("dData.confirm_password != passwordData.password", [passwordData[nextkey], event.target.value])
            if (passwordData[nextkey] != event.target.value) {
                return false;
            }
            return true;
        });
    };

    function changePassswordModalOpen(index) {
        handleCloseMore();
        setPasswordData({
            ...passwordData,
            name: userList[index - 1].name,
            user_name: userList[index - 1].user_name,
            id: userList[index - 1]._id,
        })

        setpasswordModalOpen(true);
        setanchorEl(false);
    }

    const changePassswordModalClose = () => {
        setpasswordModalOpen(false);
    };
    const modalOpen = () => {
        if (userId != 0) {
            setuserTypeArray(Appconfig.userTypesAlias[user_type]);
        }
        else {
            setuserTypeArray(userInfo.user_type);
        }

        setOpens(true);

    };

    const modalClose = () => {
        setOpens(false);
    };
    const handleOpenMenu = () => {
        setanchorEl(true);
    };

    const handleCloseMenu = () => {
        setanchorEl(false);
    };

    const [formData, setFormData] = useState({
        master_id: userInfo._id,
        name: "",
        user_name: "",
        password: "",
        website: userInfo.website,
        user_type: "",
        is_closed: userInfo.is_closed,
        is_betting_open: userInfo.is_betting_open,
        is_locked: userInfo.is_locked,
        sessional_commision: "",
        casino_partnership: "",
        partnership: "",
        teenpati_partnership: "",
        master_commision: "",
        casino_commision: "",
        credit_reference: "",
        exposer_limit: "",
        phone: "",
        city: "",
        registration_date: moment().format("DD-MMM-YYYY"),
        is_delete: userInfo.is_delete,
        site_code: userInfo.site_code,
    });

    const handleInputChange = (event) => {
        if (event.target.name === "user_name") {
            checkUserNameExist(event.target.value)
        }
        if (event.target.name === "deposite_bal") {
            checkBal(event.target.value)
        }
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        formData.registration_date = moment(formData.registration_date).format(
            "YYYY-MM-DD"
        );
        if (userId != 0) {
            formData.master_id = userId;
        }

        var axios = require("axios");
        var data = JSON.stringify(formData);

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/addUsers`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                console.log('fdsf', response);
                getUsers();

                if (response.data.result) {
                    notify(response.data.resultMessage, "success");
                    formData.name = "";
                    formData.user_name = "";
                    formData.password = "";
                    formData.master_commision = "";
                    formData.deposite_bal = "";
                    formData.partnership = "";
                    formData.casino_partnership = "";
                    setOpens(false);
                }
                else {
                    notify(response.data.resultMessage, "error");
                }

            })
            .catch(function (error) {
                notify("Some Error Occured", "error");
                console.log(error);
            });
        console.log(JSON.stringify(formData));
    };
    const classes = useStyles();

    useEffect(() => {
        window.scroll(0, 0)
        if (ValidatorForm.hasValidationRule('isPasswordMatch')) {
            ValidatorForm.removeValidationRule('isPasswordMatch');
        }
        getUsers();
    }, [params.userType,params.user_id,isBalanceUpdated]);

    function getUsers() {
        let id = userInfo._id;
        if (userId != 0) {
            id = userId;
        }
        // var user_type = params.user_type;
        var data = JSON.stringify({
            master_id: id,
            page: "AddAccount"
            // user_type: user_type,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/getUsers`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setUserList(response.data);

                let tmpUserList = response.data;
                const newUserList = tmpUserList.map((chipList, index) => {
                    tmpUserList[index]["id"] = index + 1;
                });
                console.log("data new", tmpUserList);
                setUserList(tmpUserList);
                setFilterUserList(tmpUserList)
                console.log('userList', response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function addUserByuser(id, index) {
        setanchorEl(false);
        setFormData({
            ...formData,
            ['master_id']: id,
        });
        console.log('masete', id);
        setuserTypeArray(userList[index].user_type);
        setOpens(true);
    }

    const handlePassword = (e) => {
        e.preventDefault();
        var data = JSON.stringify({

            "new_password": passwordData.password,
            "user_id": passwordData.id
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}users/changePasswordDirect`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                if (response.data.result) {
                    notify(response.data.message, 'success');
                }
                else {
                    notify(response.data.message, 'error');
                }
                //console.log(JSON.stringify(response.data));
                changePassswordModalClose();
                setPasswordData({
                    old_password: '',
                    new_password: '',
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    async function getUserBalance(id) {

        var data = JSON.stringify({
            user_id: id,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getUserBalance`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        let val = await axios(config)
            .then(async function (response) {
                return response.data.resultData.balance;
                // //console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
        return val;
    }

    function setuserTypeArray(userType) {
        var tempArr = [];
        const limit = Appconfig.userTypes.indexOf(userType);

        Appconfig.userTypes.map((value, index) => {
            if (Appconfig.userTypes.indexOf(value) > limit) {
                tempArr.push(value);
            }

        })
        setUserTypeArr(tempArr);
    }


    async function checkUserNameExist(user_name) {
        console.log("CancelToken old", CancelToken)
        if (typeof CancelToken != typeof undefined) {
            CancelToken.cancel("canceled by user");
        }
        CancelToken = axios.CancelToken.source();
        console.log("CancelToken", CancelToken)

        var data = JSON.stringify({
            user_name: user_name,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/checkExistUserName`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        let val = await axios(config, {
            cancelToken: CancelToken.token
        })
            .then(async function (response) {
                if (!response.data.result) {

                    ValidatorForm.addValidationRule('isUserNameExist', (value) => {
                        return false;
                    });

                }
                else {
                    ValidatorForm.addValidationRule('isUserNameExist', (value) => {
                        return true;
                    });

                }

                // return response.data.resultData.balance;
                //console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
        return val;
    }

    async function checkBal(bal) {
        console.log("CancelToken old", CancelToken)
        if (typeof CancelToken != typeof undefined) {
            CancelToken.cancel("canceled by user");
        }
        CancelToken = axios.CancelToken.source();
        console.log("CancelToken", CancelToken)
        let id = formData.master_id;
        if (userId != 0) {
            id = userId;
        }
        var data = JSON.stringify({
            deposite_bal: bal,
            master_id: id
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/checkBal`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        let val = await axios(config, {
            cancelToken: CancelToken.token
        })
            .then(async function (response) {
                if (!response.data.result) {

                    ValidatorForm.addValidationRule('hasBalance', (value) => {
                        return false;
                    });

                }
                else {
                    ValidatorForm.addValidationRule('hasBalance', (value) => {
                        return true;
                    });

                }

                // return response.data.resultData.balance;
                //console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
        return val;
    }


    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }

    const columns = [
        { field: "id", width: 58, headerName: "S.No." },
        {
            field: "user_name",
            headerName: "User Name",
            editable: false,
            width: 140,
            renderCell: (rowData) => {
                return (
                    <>
                        <div className="username-container"> {
                            rowData.row.user_type != "User" ? <Link href="javascript:void(0)" variant="body2" onClick={() => history.push("/userlist/" + rowData.row._id + "/" + getNextUserType(rowData.row.user_type.toLowerCase().replace(" ", "_")))}>{rowData.row.user_name}</Link> : rowData.row.user_name

                        }
                            <span>
                                {rowData.row.is_locked == "Yes" ? < PersonIcon color="secondary" style={{ fontSize: 12 }} /> : ""}
                            </span>
                            <span>{rowData.row.is_betting_open == "No" ? <LockIcon color="secondary" style={{ fontSize: 12 }} /> : ""}
                            </span>
                        </div>
                    </>
                );
            },
        },
        {
            field: "winnings",
            headerName: "Winings",
            editable: false,
            width: 107,
            align: 'right',
        },
        {
            field: "credit_limit",
            headerName: "Credit Limits",
            editable: false,
            width: 107,
            align: 'right',
        },
        // {
        //     field: "credit_reference",
        //     headerName: "Credit Reference",
        //     editable: false,
        //     width: 162,
        // },
        {
            field: "exposure",
            headerName: "Exposure",
            editable: false,
            width: 107,
            renderCell: (rowData) => {
                return (<Link onClick={() => history.push("/livebethistory/" + rowData.row._id)}>{rowData.row.exposure.toFixed(2)}</Link>);
            },
            align: 'right',
        },

        {
            field: "balance",
            headerName: "Balance",
            editable: false,
            width: 107,
            align: 'right',
        },


        // {
        //     field: "client_pl",
        //     headerName: "Client (P/L)",
        //     editable: false,
        //     width: 129,
        // },


        // {
        //     field: "available_balance",
        //     headerName: "Available Balances",
        //     editable: false,
        //     width: 176,
        // },
        // {
        //     field: "exposure_limit",
        //     headerName: "Exposure Limit",
        //     editable: false,
        //     width: 151,
        // },

        // {
        //     field: "default_per",
        //     headerName: "Default %",
        //     editable: false,
        //     width: 116,
        // },
        // {
        //     field: "user_type",
        //     headerName: "Account Type",
        //     editable: false,
        //     width: 143,
        // },



        {
            field: "partnership",
            headerName: "Share",
            editable: false,
            width: 107,
            renderCell: (rowData) => {
                return (rowData.row.user_type != "User" ? rowData.row.partnership : ""
                )
            }
        },
        // {
        //     field: "teenpati_partnership",
        //     headerName: "Partnership Teenpati",
        //     editable: false,
        //     width: 107,
        // },
        // {
        //     field: "casino_partnership",
        //     headerName: "Partnership Casino",
        //     editable: false,
        //     width: 107,
        // },
        {
            field: "master_commision",
            headerName: "M. Comm.",
            editable: false,
            width: 107,
            align: 'right',
        },
        {
            field: "sessional_commision",
            headerName: "S. Comm.",
            editable: false,
            width: 107,
            align: 'right',
        },
        {
            field: "view_more",
            headerName: "Actions",
            editable: false,
            width: 230,
            renderCell: (rowData) => {
                return (
                    <>
                        <ButtonGroup key={rowData.row.id} disableElevation variant="contained">


                            {/* <Button

                                className="extra-sm-add-btn"
                                onClick={() => history.push('/chips')}
                            >
                                C
                            </Button> */}
                            <Button
                                size="small"
                                className="extra-sm-add-btn"
                                onClick={() => chipDrawModalOpen(rowData.row.id - 1, true)}
                            >
                                D
                            </Button>
                            {/* <Button
                                size="small"
                                className="btn-sm-danger"
                                onClick={() => changePassswordModalOpen(rowData.row.id - 1)}
                            >
                                P
                            </Button> */}

                            <Button
                                size="small"
                                className="extra-sm-add-btn"
                                onClick={() => chipDrawModalOpen(rowData.row.id - 1, false)}
                            >
                                W
                            </Button>
                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleClickMore(e, rowData.row._id, rowData.row.id, rowData.row.user_type)}>
                                More
                            </Button>
                            <Menu
                                id="new-menu"
                                anchorEl={anchorElMore}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                open={Boolean(anchorElMore)}
                                onClose={handleCloseMore}
                                className={classes.menu}

                            >
                                {tempitems}

                            </Menu>
                            {rowData.row.user_type !== "User" ? <Button
                                size="small"

                                onClick={() => addUserByuser(rowData.row._id, rowData.row.id - 1)}
                            >
                                <PersonAddIcon />
                            </Button> : ""}
                        </ButtonGroup>
                    </>
                );
            },
        },
    ];
    function setSelectedUsers(newUserList) {
        let tmpSelectUserList = [...newUserList];

        console.log("tmpSelectUserList", tmpSelectUserList);
        setSelectedUser(tmpSelectUserList);
    }

    const handleAction = (e) => {
        e.preventDefault();

        var axios = require("axios");
        var data = JSON.stringify({
            user_id: chekeduser,
            code: action,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}users/userAction`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                getUsers()
                notify("Action Applied Successfully", "success");
                setSelectedUserIndex([]);

                //console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                notify("Some Error Occured", "error");
                console.log(error);
            });
        console.log(JSON.stringify(formData));
    };

    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);
        // console.log("searchedVal",searchedVal);
        const filteredRows = userList.filter((row) => {
            return row.user_name.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setFilterUserList(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    function getNextUserType(nextType) {
        console.log("nextType", nextType);
        let index = Appconfig.userTypes.indexOf(Appconfig.userTypesAlias[nextType]);
        let temp = Appconfig.userTypes[index + 1];
        return temp.toLowerCase().replace(" ", "_");
    }

    return (
        <>
            <Header isBalanceUpdated={isBalanceUpdated} />


            <Grid className="bodypart custom-container grid-row">
                <Grid item lg={2} xs={2} className="xs_none tl-hide">
                    <List
                        component="nav"
                        className="side_list"
                        aria-label="secondary mailbox folders"
                    >
                        <ListItemLink href="#simple-list" className="active">
                            <ListItemText primary="Sports" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="In-Play" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="Cricket" />
                        </ListItemLink>
                    </List>
                </Grid>

                <Grid item lg={10} xs={12} className="report-section">

                    <div className="datatable_heading">
                        <Grid container>
                            <Grid item xs={7} sm={2} >
                                Accounts List
                            </Grid>
                            <Grid item xs={5} sm={2} justifyContent="end">
                                <Button className="btn-for-add" onClick={modalOpen}
                                    startIcon={<AddIcon />}
                                    size="small"
                                >
                                    Add Account
                                </Button>
                            </Grid>
                            <Grid item xs={7} sm={2}>

                                <NativeSelect
                                    style={{
                                        border: "solid 1px #eee;",
                                        background: "#dddd",
                                        // marginRight: "5px",
                                    }}
                                    value={action}
                                    onChange={handleActionChange}
                                >
                                    <option value="">Select Action</option>
                                    <option value="LB-0">Lock Betting</option>
                                    <option value="OB-1">Open Betting</option>
                                    <option value="LU-0">Lock User</option>
                                    <option value="UU-1">Unlock User</option>
                                    <option value="CU-1">Close User Account</option>
                                    <option value="CU-0">Open User Account</option>
                                </NativeSelect>

                            </Grid>
                            <Grid item xs={5} sm={1}>
                                <Button className="btn-for-add" style={{ width: '80%' }} disabled={chekeduser.length > 0 ? false : true} onClick={handleAction}>
                                    Action
                                </Button>
                            </Grid>

                            {/* <Grid item xs={1} sm={1}>
                                    <button
                                        className="extra-sm-btn"
                                    >
                                        Back
                                    </button>
                                </Grid> */}
                        </Grid>
                    </div>

                    <Grid container spacing={0} >
                        <Grid item sm={6} xs={5} md>
                            <div className="show_data">
                                <span className="showentries">Show</span>
                                <span>
                                    <FormControl className={classes.formControl}>
                                        <NativeSelect
                                            value={pageCount}
                                            onChange={(e) => setPageCount(e.target.value)}
                                            name="age"
                                            className={classes.selectEmpty}
                                            inputProps={{ 'aria-label': 'age' }}
                                        >
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={500}>500</option>
                                        </NativeSelect>
                                    </FormControl>
                                </span>
                                <span className="showentries">entries</span>
                            </div>
                        </Grid>
                        <Grid item sm={6} xs={6} md>
                            <div className="search_data" style={{ display: 'flex', placeItems: 'center' }}>
                                <span className="showentries">Search:</span>
                                <span>
                                    <form className={classes.root} noValidate autoComplete="off">
                                        <TextField value={searched} onChange={(searchVal) => requestSearch(searchVal.target.value)} variant="outlined" size="small" id="standard-basic" />
                                    </form>
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ display: 'flex', height: '100vh' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                enableSelection={true}
                                rows={filterUserList}
                                columns={columns}
                                pageSize={pageCount}
                                // components={{
                                //     Toolbar: GridToolbar,
                                // }}
                                // filterModel={{
                                //     items: [
                                //         {
                                //             columnField: "commodity",
                                //             operatorValue: "contains",
                                //             value: "rice",
                                //         },
                                //     ],
                                // }}
                                onSelectionModelChange={(e) => {
                                    // console.log(e.target.selectionModel);


                                    const tmpUserList = e;
                                    const newUserList = tmpUserList.map((value, index) => {
                                        return userList[value - 1]._id;
                                    });

                                    setSelectedUsers(newUserList);
                                    setSelectedUserIndex(e);
                                    console.log("fsadf", selectedIndex);
                                }}
                                selectionModel={selectedIndex}
                                checkboxSelection
                                disableSelectionOnClick
                            />
                        </div>
                    </div>
                </Grid>

                <Grid container>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={opens}
                        onClose={modalClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Fade in={opens}>
                            <div className="user_modal">
                                <Grid container spacing={2}>
                                    <Grid item lg={11} xs={10}>
                                        <h2 id="transition-modal-title">Add User </h2>
                                    </Grid>
                                    <Grid item lg={1} xs={1}>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={modalClose}
                                            color="secondary"
                                            className={classes.margin}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>

                                <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
                                    <Grid container justifyContent="space-evenly" spacing={2}>
                                        <Grid item sm={6} xs={6}>
                                            <TextValidator
                                                size="small"
                                                value={formData.name}
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                                onChange={handleInputChange}
                                                id="outlined-basic2"
                                                label="Name"
                                                name="name"
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item sm={6} xs={6}>
                                            <TextValidator
                                                size="small"
                                                value={formData.user_name}
                                                validators={["isUserNameExist", "required"]}
                                                errorMessages={['Username already Exist', 'this field is required']}
                                                onChange={handleInputChange}
                                                id="outlined-basic2"
                                                label="User Name"
                                                name="user_name"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={6}>
                                            <TextValidator
                                                size="small"
                                                value={formData.password}
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                                onChange={handleInputChange}
                                                id="outlined-basic2"
                                                label="Password"
                                                name="password"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        {/* <Grid item sm={6} xs={6}> */}
                                        <input
                                            type="hidden"
                                            value={formData.registration_date}

                                            onChange={handleInputChange}
                                            id="outlined-basic2"

                                            name="registration_date"

                                        />
                                        {/* </Grid> */}
                                        {/* <Grid item lg={6} xs={6}>
                                             <TextValidator size="small"
                                                value={formData.userId}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handleInputChange}
                                                id="outlined-basic2" label="User ID" name="userId" variant="outlined" />
                                        </Grid> */}

                                        <Grid item sm={6} xs={6}>
                                            <TextValidator
                                                id="outlined-select-currency"
                                                select
                                                size="small"
                                                label="Account Type"
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                                value={formData.user_type}
                                                name="user_type"
                                                onChange={handleInputChange}
                                                variant="outlined"
                                                style={{ width: "92%" }}
                                            >
                                                {userTypeArr.map((user, index) => (
                                                    <MenuItem value={user}>{Appconfig.userTypesSign[user]}</MenuItem>
                                                ))}
                                            </TextValidator>
                                        </Grid>
                                        <Grid item sm={6} xs={6}>
                                            <TextValidator
                                                size="small"
                                                type="number"
                                                value={formData.master_commision}
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                                onChange={handleInputChange}
                                                id="outlined-basic2"
                                                label="Commision"
                                                name="master_commision"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item sm={6} xs={6}>
                                            <TextValidator
                                                size="small"
                                                type="number"
                                                value={formData.deposite_bal}
                                                validators={["hasBalance", "required"]}
                                                errorMessages={['insufficient balance', 'this field is required']}
                                                onChange={handleInputChange}
                                                id="outlined-basic2"
                                                label="Deposite Balance"
                                                name="deposite_bal"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        {/* <Grid item sm={6} xs={6}>
                                            <TextValidator
                                                size="small"
                                                type="number"
                                                value={formData.teenpati_partnership}
                                                validators={["required"]}
                                                errorMessages={["this field is required"]}
                                                onChange={handleInputChange}
                                                id="outlined-basic2"
                                                label="Teenpati Partnership (100)"
                                                name="teenpati_partnership"
                                                variant="outlined"
                                            />
                                        </Grid> */}
                                        {formData.user_type !== "User" ? <>
                                            <Grid item sm={6} xs={6}>
                                                <TextValidator
                                                    size="small"
                                                    type="number"
                                                    value={formData.partnership}
                                                    validators={["required"]}
                                                    errorMessages={["this field is required"]}
                                                    onChange={handleInputChange}
                                                    id="outlined-basic2"
                                                    label="Sports Partership"
                                                    name="partnership"
                                                    variant="outlined"
                                                />
                                            </Grid>

                                            <Grid item sm={6} xs={6}>
                                                <TextValidator
                                                    size="small"
                                                    value={formData.casino_partnership}
                                                    type="number"
                                                    validators={["required"]}
                                                    errorMessages={["this field is required"]}
                                                    onChange={handleInputChange}
                                                    id="outlined-basic2"
                                                    label="Casino Partner.."
                                                    name="casino_partnership"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </> : ""}
                                        <Grid item sm={12} xs={12}>
                                            &nbsp;<Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={modalClose}
                                            >
                                                Close

                                            </Button>
                                            &nbsp;
                                            <Button variant="contained" type="submit" color="primary">
                                                Create Account
                                            </Button>


                                        </Grid>
                                    </Grid>
                                </ValidatorForm>

                            </div>
                        </Fade>
                    </Modal>

                    <div>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={passwordModalOpen}
                            onClose={changePassswordModalClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={passwordModalOpen}>
                                <div className="user_modal">
                                    <Grid container spacing={2}>
                                        <Grid item lg={11} xs={10}>
                                            <h2 id="transition-modal-title">Change Password</h2>
                                        </Grid>
                                        <Grid item lg={1} xs={1}>
                                            <IconButton
                                                aria-label="delete"
                                                onClick={changePassswordModalClose}
                                                color="secondary"
                                                className={classes.margin}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>

                                    <ValidatorForm onSubmit={handlePassword} autoComplete="off">
                                        <Grid container spacing={2}>
                                            <Grid item lg={6} xs={6}>
                                                <TextField
                                                    size="small"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    id="outlined-basic"
                                                    value={passwordData.name}
                                                    label="Username"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <TextField
                                                    size="small"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    id="outlined-basic2"
                                                    value={passwordData.user_name}
                                                    label="Name"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <TextValidator
                                                    size="small"
                                                    value={passwordData.password}
                                                    validators={['required']}
                                                    errorMessages={["this field is required"]}
                                                    id="password"
                                                    onChange={handlePasswordChange}
                                                    name="password"
                                                    label="Password"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item lg={6} xs={6}>
                                                <TextValidator
                                                    size="small"
                                                    value={passwordData.confirm_password}
                                                    validators={['isPasswordMatch', 'required']}
                                                    errorMessages={["password mismatch", "this field is required"]}
                                                    id="confirm_password"
                                                    onChange={handlePasswordChange}
                                                    name="confirm_password"
                                                    label="Confirm Pass"
                                                    variant="outlined"
                                                />
                                            </Grid>

                                            <Grid item lg={6} xs={6}>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={changePassswordModalClose}
                                                >
                                                    Close
                                                </Button>
                                                &nbsp;
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    color="primary"
                                                >
                                                    Save Chages
                                                </Button>

                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>
                                </div>
                            </Fade>
                        </Modal>
                    </div>

                    <div>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={chipDrawModal}
                            onClose={chipDrawModalClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Fade in={chipDrawModal}>
                                <div className="user_modal">
                                    <Grid container spacing={2}>
                                        <Grid item lg={11} xs={10}>
                                            <h2 id="transition-modal-title">
                                                Chip In/Out {chipDraw.userName}
                                            </h2>
                                        </Grid>
                                        <Grid item lg={1} xs={1}>
                                            <IconButton
                                                aria-label="delete"
                                                onClick={chipDrawModalClose}
                                                color="secondary"
                                                className={classes.margin}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>

                                    <ValidatorForm
                                        onSubmit={
                                            chipDraw.Deposite
                                                ? handleChipDepositeSubmit
                                                : handleChipDrawSubmit
                                        }
                                        autoComplete="off"
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item lg={6} xs={6}>
                                                <TextValidator
                                                    size="small"
                                                    validators={["required"]}
                                                    type="number"
                                                    errorMessages={["this field is required"]}
                                                    id="outlined-basic"
                                                    value={chipDraw.free_chips}
                                                    onChange={chipDrawInputChange}
                                                    name="free_chips"
                                                    label="Free Chips"
                                                    variant="outlined"
                                                />
                                            </Grid>

                                            {/* <Grid item lg={6} xs={6}>
                                                <TextValidator
                                                    size="small"
                                                    value={chipDraw.password}
                                                    validators={["required"]}
                                                    errorMessages={["this field is required"]}
                                                    id="password"
                                                    onChange={chipDrawInputChange}
                                                    name="password"
                                                    label="Password"
                                                    variant="outlined"
                                                />
                                            </Grid> */}

                                            <Table
                                                className={classes.table}
                                                size="small"
                                                aria-label="a dense table"
                                            >
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>
                                                            {" "}
                                                            <label>Parent Free Chips</label>
                                                        </TableCell>
                                                        <TableCell>
                                                            {" "}
                                                            <label>{masterBalance}</label>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            {" "}
                                                            <label>User Balance</label>
                                                        </TableCell>
                                                        <TableCell>
                                                            {" "}
                                                            <label>{childBalance}</label>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            {" "}
                                                            <label>Parent New Free Chips</label>
                                                        </TableCell>
                                                        <TableCell>
                                                            {" "}
                                                            <label>
                                                                {chipDraw.Deposite
                                                                    ? calculateParentFreeChipDep()
                                                                    : calculateParentFreeChipDraw()}
                                                            </label>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            {" "}
                                                            <label>{chipDraw.userName} New Free Chips</label>
                                                        </TableCell>
                                                        <TableCell>
                                                            {" "}
                                                            <label>
                                                                {chipDraw.Deposite
                                                                    ? calculateChildFreeChipDep()
                                                                    : calculateChildFreeChipDraw()}
                                                            </label>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>

                                            <Grid item lg={6} xs={6}>
                                                {chipDraw.Deposite ? (
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                    >
                                                        Deposite
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        type="submit"
                                                        color="secondary"
                                                    >
                                                        Withdrawl
                                                    </Button>
                                                )}

                                                {/* <Button variant="contained" color="secondary" onClick={chipDrawModalClose}>Close</Button> */}
                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>
                                </div>
                            </Fade>
                        </Modal>
                    </div>
                </Grid>
            </Grid>
            <Footer />
        </>
    );
};

export default AccountList;
