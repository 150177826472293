import React, { useEffect } from "react";
import "./admin.css";
import "./adminResponsive.css";
import Header from "./Header";
import MiniGames from "./MiniGames";
import Footer from "./Footer";
import Logo from "../assets/line_logo_home_page.png";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
import {
  makeStyles,
  Paper,
  Grid,
  Tab,
  AppBar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

import open_teen from "../assets/open-teen.jpg";
import Cards_Live from "../assets/32-Cards-Live.jpg";
import live_teenpatti from "../assets/live-teenpatti.jpg";
import updown from "../assets/7updown.png";
import ander_baher from "../assets/ander-baher.jpg";
import dragon_tiger from "../assets/dragon-tiger.jpg";
import InPlay from "../assets/in-play.png";
import AddUser from "../assets/add-user.png";
import Home4 from "../assets/home-4.png";
import Timer from "../assets/timer.png";
import Logout from "../assets/logout.png";
import cricket from "../assets/sports-images/cric.jpg";
import soccer from "../assets/sports-images/soccer.jpg";
import tennis from "../assets/sports-images/tennis.jpg";
import virt from "../assets/sports-images/virt.jpg";
import ezugi from "../assets/sports-images/ezugi.jpg";

import CardCasino from "../assets/open-teen.jpg";
// import amarAkbarAnothny from "../assets/amar_akbar_anthony.jpg";
import seven from "../assets/seven.jpg";
import eight from "../assets/eight.jpg";
import nine from "../assets/nine.png";
import ten from "../assets/ten.jpg";
import { useHistory } from "react-router-dom";
import { SportsSoccerTwoTone } from "@material-ui/icons";

import indian_casino from "../assets/sports-images/indian_casino.jpg";
import indian_casino_2 from "../assets/sports-images/indian_casino_2.jpg";
import slot_game from "../assets/sports-images/slot_game.jpg";
import axios from "axios";
import Appconfig from "../config/config";
import slide1 from "../assets/sports-images/kv-all-skyexchange-m.jpg";

import fb from "../assets/facebook.png";
import wa from "../assets/whatsapp.png";
import tw from "../assets/twitter.png";
import tg from "../assets/telegram.png";
import yt from "../assets/youtube.png";
import ig from "../assets/instagram.png";

import gc from "../assets/gamecare.png";
import gt from "../assets/gt.png";
import plusE from "../assets/18plus.png";

import openTeen from "../assets/casino-match/open-teen.jpg";
import liveTeenpatti from "../assets/casino-match/live-teenpatti.jpg";
import dragonTiger from "../assets/casino-match/dragon-tiger.jpg";
import anderBaher from "../assets/casino-match/ander-baher.jpg";
import cardsLive from "../assets/casino-match/32-Cards-Live.jpg";
import sevenUpDown from "../assets/casino-match/7updown.jpg";
import amarAkbarAnothny from "../assets/casino-match/warli-matka.jpg";









const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function createData(
  event_id,
  name,
  value,
  action,
  calories,
  fat,
  carbs,
  protein
) {
  return { event_id, name, value, action, calories, fat, carbs, protein };
}

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const Dashboard = () => {
  const classes = useStyles();


  useEffect(() => {
    window.scroll(0, 0)

  }, []);


  // const [nav, setNav] = React.useState(2);
  const [value, setValue] = React.useState(isMobile ? "0" : "1");

  const handleChange = (event, newValue) => {

    setValue(newValue);
  };


  const rows = [];

  const history = useHistory();
  const logout = () => {
    sessionStorage.clear();
    sessionStorage.setItem("loggedIn", false);
    history.push("/");
  };
  return (
    <div>
      <Header />
      <div className="slider-margin">
        <Splide
          options={{
            type: 'loop',
            // perPage : 3,
            speed: 3000,
            autoplay: true,
          }}>
          <SplideSlide>
            <img src={slide1} alt="Image 1" />
          </SplideSlide>
          <SplideSlide>
            <img src={slide1} alt="Image 2" />
          </SplideSlide>
        </Splide>
      </div>
      <Grid className="bodypart custom-container grid-row bodypart-dashboard">

        <Grid item lg={2} xs={2} className="xs_none tl-hide">
          <List
            component="nav"
            className="side_list"
            aria-label="secondary mailbox folders"
          >
            <ListItemLink href="#simple-list" className="active">
              <ListItemText primary="Sports" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="In-Play" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="Cricket" />
            </ListItemLink>
          </List>
        </Grid>



        <Grid item lg={5} xs={12} className="mini-games-grid">
          <TabContext value={value}>
            <AppBar position="static" className="box_none">
              <div className="mobile-card-tabs">

                <>
                  <div className="sport-heading-container">
                    <span className="sport-heading">Real Sports Game</span>
                  </div>
                  <TabList
                    // onChange={handleChange}
                    className="maintabs mobile_tab"
                    variant="fullWidth"
                    aria-label="simple tabs example"
                  >
                    <Tab
                      className="main_tab"
                      icon={<MiniGames event="Cricket" image={cricket} />}
                      onClick={() => history.push(`event-list/4`)}
                    />
                    <Tab
                      className="main_tab"
                      icon={<MiniGames event="Tennis" image={tennis} />}
                      onClick={() => history.push(`event-list/2`)}
                    />
                    <Tab
                      className="main_tab"
                      icon={<MiniGames event="Soccer" image={soccer} />}
                      onClick={() => history.push(`event-list/1`)}
                    />
                    <Tab
                      className="main_tab"
                      icon={<MiniGames event="Virtual Games" image={virt} />}
                      value="4"
                    />
                  </TabList>
                  <div className="sport-heading-container">
                    <span className="sport-heading">Our Live Casino</span>
                  </div>


                </>

              </div>
            </AppBar>

          </TabContext>
        </Grid>
      </Grid>
      <div className="footer-infos">
        <div className="social-logo">
          <div className="footer-logo">
            <img src={Logo} alt="noimg" />
          </div>

          <div className="footer-social">
            <a href="javascript:void(0)" >
              <img src={ig} alt="noimg" /></a>

            <a href="javascript:void(0)" >
              <img src={fb} alt="noimg" /></a>

            <a href="javascript:void(0)" >
              <img src={wa} alt="noimg" /></a>

            <a href="javascript:void(0)" >
              <img src={tw} alt="noimg" /></a>
            <a href="javascript:void(0)" >
              <img src={yt} alt="noimg" /></a>
            <a href="javascript:void(0)" >
              <img src={tg} alt="noimg" /></a>
          </div>
        </div>
        <div className="footerbig">
          <a href="javascript:void(0)" ><img src={plusE} alt="noimg" /></a>
          <a href="javascript:void(0)" >
            <img src={gc} alt="noimg" /></a>
          <a href="javascript:void(0)" >
            <img src={gt} alt="noimg" /></a>
        </div>

        <div className="footer-bottom"><div className="text-center mt-2">© Copyright 2021. All Rights Reserved.</div></div>
      </div>

      <Footer />
    </div>
  );
};

export default Dashboard;
