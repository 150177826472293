
import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, List, ListItem, Switch, TextareaAutosize, Chip, ListItemText, FormControl, NativeSelect, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from './Header'
import Footer from './Footer'
// import React, { useState, useEffect } from 'react';
import { Table, TableBody, MenuItem, InputLabel, Select, Menu, Checkbox, TableCell, TableContainer, TableHead, Paper, Dialog, TableRow, DialogActions, DialogTitle, DialogContentText, DialogContent, Button, Modal, Typography, Fade, Backdrop } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Appconfig from '../config/config';

import axios from 'axios';
import moment from 'moment';
import { spacing } from '@material-ui/system';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { useParams } from "react-router-dom";

import './admin.css';
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function BlockMarketEventsFancys() {
    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }
    const params = useParams();
    const [event_id, setEventId] = React.useState(params.event_id);
    const [EventMatchList, setEventMatchList] = React.useState([]);
    const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
    const [EventMatchId, setEventMatchId] = React.useState('');
    const [marketList, setmarketList] = React.useState([]);

    useEffect(() => { window.scroll(0,0)
        fancys(params.event_id)
    }, [])
    const columns = [

        {
            field: 'id',
            headerName: 'S.NO',
            editable: true,
            width: 58
        },
        // {
        //     field: 'event_type',
        //     headerName: 'BetfairId',
        //     editable: true,
        //     flex: 1,
        // },
        {
            field: 'name',
            headerName: 'Name',
            sortable: false,
            width: 130,
            disableClickEventBubbling: true,
            renderCell: rowData => {
                return (
                    <Link to='#' >{rowData.row.runner_name}</Link>
                );
            }
        },

        {
            field: "is_blocked",
            headerName: "Status",
            sortable: false,
            width: 74,
            disableClickEventBubbling: true,
            renderCell: rowData => {
                return (
                    rowData.row.is_blocked === "Yes" ? <Chip label="Inactive" color="secondary" /> : <Chip label="Active" color="primary" />
                );
            }
        },
        {
            field: "edit",
            headerName: "Action",
            sortable: false,
            width: 74,
            disableClickEventBubbling: true,
            renderCell: rowData => {
                return (
                    <Switch
                        checked={rowData.row.is_blocked === "Yes" ? false : true}
                        onChange={() => blockUnblockMarket(rowData.row.match_id, rowData.row.selection_id)}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                );
            }
        },
    ];
    function fancys(eventId) {


        var data = JSON.stringify({
            "user_id": userInfo._id,
            "event_id": eventId
        });


        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}blockMarket/blockMarketFancy`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setmarketList(response.data.resultData);
                console.log(response.data.resultData)
                let tmpEventList = response.data.resultData;
                const newEventMatchList = tmpEventList.map((marketList, index) => {
                    tmpEventList[index]['id'] = index + 1;
                });
                console.log('after match odds click', response.data.resultData);
                setmarketList(tmpEventList)

                //  console.log(EventList);


                // console.log(EventList);
            })
            .catch(function (error) {
                console.log(error);
            });


    }
    function blockUnblockMarket(event_id, selection_id) {
        console.log([event_id, params.event_type_id, selection_id]);
        var data = JSON.stringify({

            "user_id": userInfo._id,
            "event_id": event_id,
            "market_id": "0",
            "fancy_id": selection_id,
            "event_type_id": params.event_type_id,
            "type": "Fancy"

        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}blockMarket/block&unblockMarket`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                fancys(params.event_id)
                // getEventList();

                // console.log(EventList);
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Header />
            <Grid className="bodypart custom-container grid-row">
                <Grid item lg={2} xs={2} className="xs_none tl-hide">
                    <List component="nav" className="side_list" aria-label="secondary mailbox folders">
                        <ListItemLink href="#simple-list" className="active">
                            <ListItemText primary="Sports" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="In-Play" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="Cricket" />
                        </ListItemLink>
                    </List>
                </Grid>

                <Grid item lg={10} xs={12} className="report-section">

                    <div className="datatable_heading">
                        Fancy Listing

                    </div>
                    <div style={{ display: 'flex', height: '100vh' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                className="block-market"
                                // components={{
                                //     Toolbar: GridToolbar,
                                // }}
                                // filterModel={{
                                //     items: [{ columnField: 'commodity', operatorValue: 'contains', value: 'rice' }],
                                // }}
                                rows={marketList}
                                columns={columns}
                                pageSize={100}
                                disableSelectionOnClick
                            />
                        </div>
                    </div>

                </Grid>
            </Grid>

            <Footer />
        </div >
    );
}