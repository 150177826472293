import React from 'react';
import { makeStyles, Link, Grid, List, TextField, ListItem, Select, ListItemText, InputLabel, MenuItem, FormControl, NativeSelect, Button } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from './Header'
import Footer from './Footer'
import Appconfig from '../config/config'
import './admin.css';
import axios from 'axios';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
}));

export default function EventSingle() {
  const params = useParams();
  const userId = params.user_id;
  const classes = useStyles();
  const history = useHistory();
  const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
  const [pageCount, setPageCount] = React.useState(10);

  const [fetchData, SetFetchData] = React.useState({
    "user_id": userInfo._id,
    "from_date": moment().add(-1, 'days').format('YYYY-MM-DD'),
    "to_date": moment().format('YYYY-MM-DD'),
    "type": "All"
  });
  const [profitLossList, SetProfitLossList] = React.useState([]);
  React.useEffect(() => {  window.scroll(0,0);
    getProfitLoss();
  }, [userId])
  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  const columns = [


    { field: 'id', width: 91, headerName: 'S.No.' },
    {
      field: 'event_name',
      headerName: 'Event Name',
      editable: false,
      width: 155,
    },
    // {
    //   field: 'market_name',
    //   headerName: 'Market',
    //   editable: false,
    //   width: 101,
    // },
    {
      field: 'totalPL',
      headerName: 'P_L',
      editable: false,
      width: 100,
    },
    {
      field: 'wallet',
      headerName: 'Commission',
      editable: false,
      width: 137,
    },
    {
      field: 'createdAt',
      headerName: 'Created On',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (moment(rowData.row.createdAt).format('MM/DD/YYYY  hh:mm:ss A'));
      }

    },
    {
      field: 'Action',
      headerName: 'Action',
      editable: false,
      width: 98,
      renderCell: (rowData) => {
        return (
          <Link onClick={() => history.push(`/bethistory/${rowData.row.match_id}/0/0`)}>Show More</Link>
        );
      },
    },
  ];

  const rows = [
    { id: 1, event_name: "Cricket", market_name: 'Match Odds	', totalPL: "0.00", wallet: '0.00', createdAt: '1000', Action: "show More" },

  ];

  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    SetFetchData({
      ...fetchData,
      [event.target.name]: event.target.value,
    });
    console.log(fetchData);
  };

  function getProfitLoss() {

    fetchData.user_id = userId != 0 ? userId : userInfo._id;
    // var data = JSON.stringify(formData);
    var data = JSON.stringify(fetchData)

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}reports/profitLoss`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // console.log('smjh me nhi aaya yrr', JSON.stringify(response.data));
        SetProfitLossList(response.data.resultData);
        let tmpAccountList = response.data.resultData;
        const newChipList = tmpAccountList.map((profitLossList, index) => {
          tmpAccountList[index]["id"] = index + 1;
        });
        SetProfitLossList(tmpAccountList);
        //console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <div className={classes.root}>
      <Header />
      <Grid className="bodypart custom-container grid-row">
        <Grid item lg={2} xs={2} className="xs_none tl-hide">
          <List component="nav" className="side_list" aria-label="secondary mailbox folders">
            <ListItemLink href="#simple-list" className="active">
              <ListItemText primary="Sports" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="In-Play" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="Cricket" />
            </ListItemLink>
          </List>
        </Grid>

        <Grid item lg={10} xs={12} className="report-section">

          <div className="datatable_heading">
            Profit Loss Listing &nbsp;

          </div>

          <Grid container spacing={0} >
            <Grid item sm={3} xs={6} md>
              <FormControl variant="outlined" size="small" style={{ width: '97%' }} >
                <InputLabel id="demo-simple-select-outlined-label">Game Type</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={fetchData.type}
                  onChange={handleChange}
                  name="type"
                  className="gametype"
                  inputProps={{ 'aria-label': 'age' }}
                  label="Game Type"

                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="4">Cricket</MenuItem>
                  <MenuItem value="1">Soccer</MenuItem>
                  <MenuItem value="2">Tennis</MenuItem>
                  <MenuItem value="1001">Live teenpatti</MenuItem>
                  <MenuItem value="1002">Live Casino</MenuItem>
                  <MenuItem value="1003">Live Game</MenuItem>
                  <MenuItem value="999">Fancy</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={3} xs={6} md>
              <FormControl className={classes.formControl}>
                <TextField variant="outlined" size="small" type="text" label="Via event name" />
              </FormControl>
            </Grid>
            <Grid item sm={3} xs={6} md>

              <TextField className="dates" label="From Date" variant="outlined" size="small" type="date" value={fetchData.from_date} onChange={handleChange} name="from_date" />
            </Grid>
            <Grid item sm={3} xs={6} md>
              <TextField className="dates" label="To Date" variant="outlined" size="small" type="date" style={{ marginBottom: 4 }} value={fetchData.to_date} onChange={handleChange} name="to_date" />
            </Grid>

            <Grid item sm={3} xs={6} md>
              <Button variant="contained" color="secondary">
                Clear
              </Button>
              &nbsp;
              <Button variant="contained" color="primary" onClick={() => getProfitLoss()}>
                Filter
              </Button>


            </Grid>

          </Grid>
          <Grid container spacing={0} >
            <Grid item sm={6} xs={5} md>
              <div className="show_data">
                <span className="showentries">Show</span>
                <span>
                  <FormControl className={classes.formControl}>
                    <NativeSelect
                      value={pageCount}
                      onChange={(e) => setPageCount(e.target.value)}
                      name="age"
                      className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'age' }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </NativeSelect>
                  </FormControl>
                </span>
                <span className="showentries">entries</span>
              </div>
            </Grid>
            <Grid item sm={6} xs={6} md>
              <div className="search_data" style={{ display: 'flex', placeItems: 'center' }}>
                <span className="showentries">Search:</span>
                <span>
                  <form className={classes.root} noValidate autoComplete="off">
                    <TextField variant="outlined" size="small" id="standard-basic" />
                  </form>
                </span>
              </div>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', height: '100vh' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                // components={{
                //   Toolbar: GridToolbar,
                // }}
                // filterModel={{
                //   items: [{ columnField: 'commodity', operatorValue: 'contains', value: 'rice' }],
                // }}
                rows={profitLossList}
                columns={columns}
                pageSize={5}
                disableSelectionOnClick
                components={{
                  noRowsOverlay: <h1>no rows</h1>,
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Footer />

    </div >
  );
}