import React, { useEffect, useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { SocketContext } from "../context/socket";
import "./admin.css";
import "./adminResponsive.css";
import TvIcon from '@material-ui/icons/Tv';
import moment from "moment";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import BtnDelete from '../assets/btn-delete.svg';
import { MobileView, BrowserView, isMobile } from 'react-device-detect';

import MenuIcon from '@material-ui/icons/Menu';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  makeStyles,
  FormControlLabel,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Grid,
  AppBar,
  Checkbox,
  Tabs,
  Tab,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Modal,
  Backdrop,
  Fade,
  Divider,
  TableFooter,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import axios from "axios";
import Appconfig from "../config/config";
import { ToastContainer, toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 0
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  modal: {
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",

    margin: 9,
    borderRadius: 5,
    height: 100

  },
  darktblhead: {
    td: {
      padding: 5
    }
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value != index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function createTbl(name, type, calories, fat, carbs, protein, suspended) {
  return { name, type, calories, fat, carbs, protein, suspended };
}

const rows = [
  createTbl("Surrey", "0 216", 159, 6.0, 1.67, 1.7),
  createTbl("Glamorgan", "0 200", 237, 9.0, 1.67, 1.7),
];

const datas = [
  createTbl("Surrey", "0 216", 159, 6.0, 1.67, 1.7),
  createTbl("Glamorgan", "0 200", 237, 9.0, 1.67, 1.7, "suspended"),
];

const fancy = [
  createTbl("5 Over Run", "0 216", 159, 6.0, 35, 37, ""),
  createTbl("10 Over Run", "0 200", 237, 9.0, 35, 37, ""),
  createTbl("Sachin Tendulkar Runs", "0 452", 15, 16.0, 35, 37, "suspended"),
  createTbl("Total Middal Overs", "0 100", 305, 3.7, 35, 37, ""),
  createTbl("First Inning total Run", "0 500", 48.5, 16.0, 35, 37, "suspended"),
  createTbl("20 Over Run", "0 500", 48.5, 16.0, 35, 37, ""),
  createTbl("5 Over Run", "0 500", 48.5, 16.0, 35, 37, ""),
];

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const EventDetail = () => {
  const socket = useContext(SocketContext);
  const classes = useStyles();
  const params = useParams();
  const [chipList, setchipList] = React.useState([]);

  const [placing, setPlacing] = React.useState(false);

  const [positionOpen, setPositionOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [EventData, setEventData] = React.useState([]);
  const [prevRunners, setRunners] = React.useState([]);
  const [BetPlaceData, setBetPlaceData] = React.useState({
    event_id: "",
    market_id: "",
    is_back: "",
    price: "",
    is_fancy: "",
    selection_id: "",
    runner_name: "",
  });
  const [state, setState] = React.useState({
    checkedB: false,
  });
  const [postionFancyList, setPostionFancyList] = React.useState([]);

  const [StakeValue, setStakeValue] = React.useState(0);
  const [ProfitValue, setProfitValue] = React.useState(0);
  const [lossValue, setLossValue] = React.useState(0);
  const [betSlipList, setBetSlipList] = React.useState([]);
  const [newTemp, setNewTemp] = React.useState("");
  const [expanded, setExpanded] = React.useState({
    panel1: false,
    panel2: false
  });

  const [oddsData, setOddsData] = React.useState({
    is_fancy_off: "No",
    is_odds_off: "No"
  });

  const [userOddsData, setUserOddsData] = React.useState({
    is_fancy_odds_off: false,
    is_match_odds_off: false,
    is_bookmaker_odds_off: false,
  });

  const [tvUrl, setTvUrl] = React.useState("");
  const [scoreUrl, setScoreUrl] = React.useState("");
  const [isBalanceUpdated, setIsBalanceUpdated] = React.useState(false);
  const [currentPositionList, setCurrentPositionList] = React.useState([]);
  const [selfExposure, setSelfExposure] = React.useState([]);
  const [selfTotalExposure, setSelfTotalExposure] = React.useState([]);
  const [uplineExposure, setUplineExposure] = React.useState([]);



  const [iframeHeight, setScoreIframeHeight] = React.useState("");


  const positionClose = () => {
    setPositionOpen(false);
  }
  function handleChangeAccordian(newExpanded) {
    // alert(!expanded[newExpanded] );
    setExpanded({ ...expanded, [newExpanded]: !expanded[newExpanded] });
  }
  // const handleChangeAc = (name) => (event, newExpanded) => {
  //   // alert(newExpanded);
  //   setExpanded(newExpanded ? name : false);
  // };
  const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
  const eventId = params.event_id;

  useEffect(() => {
    window.scroll(0, 0)
    window.addEventListener("message", (event) => {
      if (event.origin == "https://central.satsport247.com'")
        console.log("event new", event.data.scoreWidgetHeight)
      setScoreIframeHeight(event.data.scoreWidgetHeight)
    }, false);
    let userName = userInfo.user_name;
    let room = eventId;
    //send event that user has joined room
    socket.emit("join_room", {
      username: userName,
      roomName: room
    });

    // console.log("profit,loss", [ProfitValue, lossValue])
    socket.on('fancy_update', function (data) {

      // console.log('FANCY UPDATE:', data);
      // console.log(data);
      fancyHtml(data);
    });


    socket.on("market_update", (data) => {

      // return false;
      //events   
      // console.log('MARKET UPDATE:', data);

      var matchId = eventId;
      // if (MarketId) {

      // var market = data.marketodds[matchId]

      if (data.marketodds.length > 0) {

        var market = data.marketodds.find(o => o.event_id == matchId);

        if (market) {
          if (market.market_types.length > 0) {
            market.market_types.map((market_type, index) => {

              if (market_type.hasOwnProperty("runners") && market_type.runners.length > 0) {
                market_type.runners.map((runner, index) => {



                  if (market_type.status == 'OPEN') {
                    // document.getElementsByClassName('overlay_matchBoxs_' + market_type.market_id.replace('.', '')).fadeOut();
                  } else {

                    // document.getElementsByClassName('overlay_matchBoxs_' + market_type.market_id.replace('.', '')).fadeIn();

                    // document.getElementsByClassName('status_matchBoxs_' + market_type.market_id.replace('.', '')).text(market_type.status);
                  }
                  var match_odd_row = document.getElementsByClassName('table_row_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)[0];

                  if (runner.status == 'SUSPENDED') {



                    if (document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                      match_odd_row.setAttribute('data-title', "SUSPENDED");
                      match_odd_row.classList.add("suspended");
                      if (parseFloat(document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_price)) {
                        document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;

                      } else {
                        document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
                      }


                      if (parseFloat(document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_price)) {
                        document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;

                      } else {
                        document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = 0;
                      }

                    }

                  } else {

                    if (document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                      match_odd_row.setAttribute('data-title', "");
                      match_odd_row.classList.remove("suspended");

                      if (parseFloat(document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_price)) {

                        document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price);

                      } else {
                        document.getElementById('availableToBack1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_price);
                      }


                      if (parseFloat(document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_price)) {
                        document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price);

                      } else {
                        document.getElementById('availableToLay1_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_price);
                      }
                    }
                    // if (parseFloat(document.getElementById('availableToLay2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_2_price)) {
                    //     document.getElementById('availableToLay2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_price;

                    // } else {
                    //     document.getElementById('availableToLay2_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_price;
                    // }

                    // if (parseFloat(document.getElementById('availableToLay3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_3_price)) {
                    //     document.getElementById('availableToLay3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_price;

                    // } else {
                    //     document.getElementById('availableToLay3_price_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_price;
                    // }

                  }


                  /************************Size */

                  // if (parseFloat(document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_3_size)) {
                  //     document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.back_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');

                  // } else {
                  //     document.getElementById('availableToBack3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.back_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                  // }


                  // if (parseFloat(document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_2_size)) {
                  //     document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.back_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');

                  // } else {
                  //     document.getElementById('availableToBack2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.back_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                  // }
                  if (document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id)) {
                    if (parseFloat(document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.back_1_size)) {
                      document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size);

                      // document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');

                    } else {

                      document.getElementById('availableToBack1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.back_1_size);
                      //  document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                    }


                    if (parseFloat(document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_1_size)) {
                      document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size);

                      // document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');

                    } else {

                      document.getElementById('availableToLay1_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML = parseFloat(runner.lay_1_size);
                      // document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                    }
                  }
                  // if (parseFloat(document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_2_size)) {
                  //     document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');

                  // } else {
                  //     document.getElementById('availableToLay2_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_2_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                  // }

                  // if (parseFloat(document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML) != parseFloat(runner.lay_3_size)) {
                  //     document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.add('yellow');

                  // } else {
                  //     document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).innerHTML=runner.lay_3_size;document.getElementById('availableToLay3_size_' + runner.market_id.replace('.', '') + '_' + runner.selection_id).parentElement.classList.remove('yellow');
                  // }


                });
              }
            });
          }
        }

      }
    });
    getOddsOnOff();
    getUserGenSettings();
    getEventData();
    getBetSlip();
    getTvUrl(eventId)
    getScoreUrl(eventId)
    getChips()
    getCurrentPosition()

  }, []);


  function getChips() {


    var data = JSON.stringify({
      "user_id": userInfo._id
    });

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}chips/getChips`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setchipList(response.data);
        let tmpChipList = response.data;
        const newChipList = tmpChipList.map((chipList, index) => {
          tmpChipList[index]["id"] = index + 1;
        });
        // console.log("data new", tmpChipList);

        setchipList(tmpChipList);
        // console.log(chipList);

      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const getOddsOnOff = () => {

    var data = JSON.stringify({
      site_code: userInfo.site_code,
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}oddsOnOff/getOddsOnOff`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        // console.log("response.data", response.data);
        if (response.data.result && response.data.resultData.length > 0) {
          oddsData["is_odds_off"] = response.data.resultData[0].is_odds_off;
          oddsData["is_fancy_off"] = response.data.resultData[0].is_fancy_off

        }
        else {

          oddsData["is_odds_off"] = "No"
          oddsData["is_fancy_off"] = "No"

        }
        // console.log("oddsData", oddsData)
      })
      .catch(function (error) {
        console.log(error);
      });

  };

  const handleBetInputChange = (e) => {
    setStakeValue(e.target.value);
    placeStakeValue(parseInt(e.target.value));
    calc(e.target.value, BetPlaceData.price, BetPlaceData.selection_id)
    SetPosition(e.target.value, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id);
  }
  function getEventData() {
    const event_id = params.event_id;


    var data = JSON.stringify({
      user_id: userInfo._id,
      event_id: event_id,
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}eventsDashboard/getDashboardDataByeventId`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("setEventData", response.data.resultData);
        setEventData(response.data.resultData);
        let tempData = [];
        let newrwo = response.data.resultData;
        // console.log("newrwo",newrwo);
        newrwo.map((Crick, index) => {
          Crick.marketTypes[0].marketRunners.map((runner, index) => {
            let shortName = runner.runner_name.match(/\b(\w)/g).join('')
            tempData.push(
              shortName
            );
          });

        });
        // console.log(tempData);
        setRunners(tempData);
        // console.log(setRunners);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function betPlace() {
    setPlacing(true);
    var data = JSON.stringify({
      user_id: userInfo._id,
      match_id: BetPlaceData.event_id,
      selection_id: BetPlaceData.selection_id,
      is_back: BetPlaceData.is_back,
      stake: StakeValue,
      price_val: BetPlaceData.price,
      market_id: BetPlaceData.market_id,
      is_fancy: BetPlaceData.is_fancy == 1 ? "Yes" : "No",
      market_name: "Match odds",
      profit: ProfitValue,
      loss: lossValue,
    });
    // console.log(data);

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}betting/addBetting`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setIsBalanceUpdated(true);
        setIsBalanceUpdated(false);
        setOpen(false);
        setTimeout(function () {
          setPlacing(false)
        }, 900);


        setBetPlaceData({
          event_id: "",
          market_id: "",
          is_back: "",
          price: "",
          is_fancy: "",
          selection_id: "",
          runner_name: "",
        });

        setProfitValue(0);
        setStakeValue(0);

        if (response.data.result == 0) {
          SetPosition(0, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id);
          notify(response.data.resultMessage, "error");
        } else {
          notify("Betting Placed Successfully", "success");
        }
        hideAllBetBox();
        getBetSlip();
        getEventData();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function notify(message, type) {
    toast.configure();
    if (type === 'error') {
      toast.error(message, {
        toastId: "error"
      });
    }
    else {
      toast.success(message, {
        toastId: "success"
      });
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const hideAllBetBox = () => {
    SetPosition(0, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id)
    const boxes = document.getElementsByClassName("pbb");
    for (var box of boxes) {
      box.style.setProperty("display", "none", "important")
    }
  }


  const handleOpen = (
    event_id,
    market_id,
    is_back,
    price,
    is_fancy,
    selection_id,
    runner_name,
    htmlId
  ) => {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));

    if (userInfo.user_type == "User") {

      hideAllBetBox();
      const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));

      // console.log('click',`availableToBack1_price_${market_id.replace('.','')}_${selection_id}`);


      hideAllBetBox();


      // console.log('click',`availableToBack1_price_${market_id.replace('.','')}_${selection_id}`);


      // console.log(`place-bet-block_${market_id.replace('.', '')}_${selection_id}`)
      // document.getElementsByClassName("pbb").style.setProperty("display", "none", "important");
      if (!is_fancy) {
        document.getElementsByClassName(`place-bet-block_${market_id.replace('.', '')}_${selection_id}`)[0].style.setProperty("display", "table-row", "important");
      } else {
        document.getElementsByClassName(`place-fancybet-block_${event_id}_${selection_id}`)[0].style.setProperty("display", "table-row", "important");
      }
      setProfitValue(0);
      setStakeValue(0)
      // console.log('click',`availableToBack1_price_${market_id.replace('.','')}_${selection_id}`);
      var priceNew = document.getElementById(htmlId).innerHTML;

      var lastFive = market_id.substr(market_id.length - 3);
      if (lastFive == "_BM") {
        priceNew = ((priceNew / 100) + 1).toFixed(2);
      }

      var runners = document.getElementsByClassName(`runners_${market_id.replace('.', '')}`);
      var tempRunners = "";
      // console.log(runners);
      setNewTemp("");
      if (!is_fancy) {
        if (EventData.length > 0) {
          EventData.map(async (event, index) =>
            event.marketTypes.map((market, index) => {

              market.marketRunners.map((runner, i) => {
                // console.log(runner.selection_id);
                if (market.market_id == market_id) {
                  // console.log(selection_id);
                  tempRunners += `<div class="parent-runner-on-bet"><div class="runner-on-bet">${runner.runner_name}</div><div class="runner-on-bet" id="${runner.selection_id + "_maxprofit_loss_runner_prev_" + market_id.replace('.', '')}">${0}</div><div class="runner-on-bet" id="${runner.selection_id + "_maxprofit_loss_runner_" + market_id.replace('.', '')}">${0}</div></div>`;

                }
              })

            })
          )
          if (tempRunners) {
            // console.log(newTemp);
            setNewTemp(tempRunners);

          }
        }
      }

      // alert(is_back);

      setBetPlaceData({
        event_id: event_id,
        market_id: market_id,
        is_back: is_back ? 1 : 0,
        price: priceNew,
        is_fancy: is_fancy,
        selection_id: selection_id,
        runner_name: runner_name,
      });

      setOpen(true);

      setTimeout(function () {
        SetPosition(0, priceNew, market_id, is_back, selection_id)
        calc(0, priceNew, selection_id)
      }, 800);

      // console.log("Bet Place Data", BetPlaceData);
    }
  };


  const handleClose = () => {
    setOpen(false);
    SetPosition(0, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id)
    setBetPlaceData({
      event_id: "",
      market_id: "",
      is_back: "",
      price: "",
      is_fancy: "",
      selection_id: "",
      runner_name: "",
    });
  };

  const placeStakeValue = (stake) => {
    setStakeValue(stake);
    // let profit;
    // if (BetPlaceData.is_fancy) {
    //   profit = stake;
    // }
    // else {
    //   profit = parseFloat(BetPlaceData.price) * parseFloat(stake) - stake;
    // }

    // setProfitValue(profit);
    calc(stake, BetPlaceData.price, BetPlaceData.selection_id)
    SetPosition(stake, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id);
  };
  function getBetSlip() {

    var data = JSON.stringify({
      user_id: userInfo._id,
      event_id: eventId,
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}betting/getBettingsByUserAndEventId`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setBetSlipList(response.data.resultData);
        // console.log('betslip list ', response.data.resultData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  function getCurrentPosition(user_id = null) {


    if (user_id == null) {
      user_id = userInfo._id;
    }
    var data = JSON.stringify({
      user_id: user_id,
      match_id: eventId,
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}betting/getCurrentPosition`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.result == 1) {
          setCurrentPositionList(response.data.resultData.match_odds_position);
          setSelfExposure(response.data.resultData.selfExposure);
          setSelfTotalExposure(response.data.resultData.totalExposure);
          setUplineExposure(response.data.resultData.uplineExposure);
        }
        else {
          setCurrentPositionList([]);
        }
        // console.log('betslip list ', response.data.resultData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  function fancyHtml(data) {
    // console.log("kaka", data);
    var superiors = ["270", "259", "185", "177", "30"];
    var matchId = eventId;
    if (document.getElementById('fancyAPI')) {
      // document.getElementById('fancyAPI').innerHTML = `<tr class="MuiTableRow-root yelloBG"><td class="MuiTableCell-root MuiTableCell-body table_first_row colorDanger"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td><td class="MuiTableCell-root MuiTableCell-body bg_odd MuiTableCell-alignRight">Back</td><td class="MuiTableCell-root MuiTableCell-body bg_even MuiTableCell-alignRight">Lay</td></tr>`;
    }
    if (matchId) {

      if (data.fantacy.length > 0) {


        var fancys = data.fantacy.find(o => o.event_id == matchId);

        if (fancys) {

          fancys = fancys.fancy_data;


          if (fancys.length) {

            for (var j = 0; j < fancys.length; j++) {


              if (fancys[j].runner_name.includes('ball')) {
                continue;

              }
              if (fancys[j].cron_disable == 'Yes') {
                // ClearAllSelection(1);
                // $('.fancy_lay_price_' + fancys[j].selection_id).parent().parent().fadeOut();
              } else {
                if (fancys[j].is_active == "No") {
                  document.getElementsByClassName('fancy_' + fancys[j].selection_id).remove();
                }
                if (fancys[j]) {

                  var block_market_fancys = fancys[j].block_market;
                  var block_all_market_fancys = fancys[j].block_all_market;
                  // var block_market_fancys = [];
                  // var block_all_market_fancys = [];

                  var find_fancy_all_block = block_all_market_fancys.filter(element => {

                    return superiors.includes(element.user_id.toString())
                  });

                  if (find_fancy_all_block.length > 0) {
                    // ClearAllSelection(1);
                    // $('.fancy_lay_price_' + fancys[j].selection_id).parent().parent().fadeOut();
                  } else {

                    var find_fancy_block = block_market_fancys.filter(element => {

                      return superiors.includes(element.user_id.toString())
                    });

                    if (find_fancy_block.length > 0) {
                      // ClearAllSelection(1);
                      // $('.fancy_lay_price_' + fancys[j].selection_id).parent().parent().fadeOut();
                    } else {
                      // $('.fancy_lay_price_' + fancys[j].selection_id).parent().parent().fadeIn();
                      var fancyHtml = '';
                      // console.log("reee", 'fancy_' + fancys[j].selection_id);
                      if (!document.getElementsByClassName('fancy_' + fancys[j].selection_id)) {
                        // console.log("nhi hai")
                        // fancyHtml += '<div class="block_box f_m_' + fancys[j].match_id + ' fancy_' + fancys[j].selection_id + ' f_m_31236" data-id="31236">';

                        // fancyHtml += '<ul class="sport-high fancyListDiv">';
                        // fancyHtml += '<li>';
                        // fancyHtml += '<div class="ses-fan-box">';
                        // fancyHtml += '<table class="table table-striped  bulk_actions">';
                        // fancyHtml += '<tbody>';








                        fancyHtml += `<tr class="MuiTableRow-root fancy_${fancys[j].selection_id}" >`;
                        fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body table_first_row">`;
                        fancyHtml += `<div class="text_left">
                        <button onclick=getFancyPosition(${fancys[j].selection_id}) className="book-fancy">Book</button>
                        <p >${fancys[j].runner_name}</p></div>`;
                        fancyHtml += `</td>`;
                        fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;

                        fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;
                        fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;
                        fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;

                        fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body bg_even MuiTableCell-alignRight" 
                        onclick=
                    handleOpen(
                      ${eventId},
                      "",
                      false,
                      ${fancys[j].lay_price1},
                      true,
                      ${fancys[j].selection_id},
                      ${fancys[j].runner_name},
                      fancy_lay_price_${fancys[j].match_id}_${fancys[j].selection_id}
                    )>`;
                        fancyHtml += `<div class="fancy-td-cell"><div class="fancy_lay_price_${fancys[j].selection_id}" id="fancy_lay_price_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_price1)}</div><p class="eventP fancy_lay_size_${fancys[j].selection_id}" id="fancy_lay_size_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_size1)}</p></div>`;
                        fancyHtml += `</td >`;
                        fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body bg_odd sus-parent MuiTableCell-alignRight" 
                        onclick=
                    handleOpen(
                      ${eventId},
                      "",
                      true,
                      ${fancys[j].back_price1},
                      true,
                      ${fancys[j].selection_id},
                      ${fancys[j].runner_name},
                      fancy_lay_price_${fancys[j].selection_id}
                    )>`;
                        fancyHtml += `<div class="fancy-td-cell"><div class="fancy_back_price_${fancys[j].selection_id}" id="fancy_back_price_${fancys[j].selection_id}">${parseFloat(fancys[j].back_price1)} </div> <p class="eventP fancy_back_size_${fancys[j].selection_id}" id="fancy_back_size_${fancys[j].selection_id}">${parseFloat(fancys[j].back_size1)}</p></div></td>`;
                        fancyHtml += `</tr >`;



                        // fancyHtml += '<tr class="MuiTableRow-root">';
                        // fancyHtml += '<td class="MuiTableCell-root MuiTableCell-body table_first_row">';
                        // fancyHtml += '<div  class="fancyhead' + fancys[j].selection_id + '" id="fancy_name' + fancys[j].selection_id + '">' + fancys[j].runner_name + '</div><b class="fancyLia' + fancys[j].selection_id + '"></b><p class="position_btn"><button class="btn btn-xs btn-danger" onclick="getPosition(' + fancys[j].selection_id + ')">Book</button></td>';


                        // fancyHtml += '<td></td>';
                        // fancyHtml += '<td></td>';

                        // fancyHtml += '<td class="fancy_lay" id="fancy_lay_' + fancys[j].selection_id + '" onclick="betfancy(`' + fancys[j].match_id + '`,`' + fancys[j].selection_id + '`,`' + 0 + '`);">';

                        // fancyHtml += '<button class="lay-cell cell-btn fancy_lay_price_' + fancys[j].selection_id + '" id="LayNO_' + fancys[j].selection_id + '">' + fancys[j].lay_price1 + '</button>';

                        // fancyHtml += '<button id="NoValume_' + fancys[j].selection_id + '" class="disab-btn fancy_lay_size_' + fancys[j].selection_id + '">' + fancys[j].lay_size1 + '</button></td>';

                        // fancyHtml += '<td class="fancy_back" onclick="betfancy(`' + fancys[j].match_id + '`,`' + fancys[j].selection_id + '`,`' + 1 + '`);">';

                        // fancyHtml += '<button class="back-cell cell-btn fancy_back_price_' + fancys[j].selection_id + '" id="BackYes_' + fancys[j].selection_id + '">' + fancys[j].back_price1 + '</button>';

                        // fancyHtml += '<button id="YesValume_' + fancys[j].selection_id + '" class="disab-btn fancy_back_size_' + fancys[j].selection_id + '">' + fancys[j].back_size1 + '</button>';
                        // fancyHtml += '</td>';

                        // fancyHtml += '<td>';
                        // fancyHtml += '</td>';
                        // fancyHtml += '<td>';
                        // fancyHtml += '</td>';
                        // fancyHtml += '</tr>';
                        // fancyHtml += '</tbody>';
                        // fancyHtml += '</table>';
                        // fancyHtml += '</div>';
                        // fancyHtml += '</li>';
                        // fancyHtml += '</ul></div>';
                        // console.log('fancy html', fancyHtml);
                        if (document.getElementById('fancyAPI')) {
                          document.getElementById('fancyAPI').innerHTML += fancyHtml;
                        }

                      }

                      // if (fancys[j].back_price1 == 'Ball') {
                      //     $('.fancy_lay_price_' + fancys[j].selection_id).parent().attr('disabled', 'disabled');
                      //     $('.fancy_back_price_' + fancys[j].selection_id).parent().attr('disabled', 'disabled');
                      //     $('.fancy_lay_price_' + fancys[j].selection_id).text(fancys[j].lay_price1);
                      //     $('.fancy_back_price_' + fancys[j].selection_id).text(fancys[j].back_price1);
                      //     $('.fancy_lay_size_' + fancys[j].selection_id).text(fancys[j].lay_size1);
                      //     $('.fancy_back_size_' + fancys[j].selection_id).text(fancys[j].back_size1);
                      // }
                      var newtr = document.getElementsByClassName('fancy_' + fancys[j].selection_id)[0];
                      if (document.getElementById('fancy_lay_price_' + fancys[j].selection_id)) {
                        if (fancys[j].game_status == 'Ball Running') {
                          // console.log("FDf", document.getElementsByClassName('fancy_' + fancys[j].selection_id));
                          // $('.fancy_lay_price_' + fancys[j].selection_id).parent().attr('disabled', 'disabled');
                          // $('.fancy_back_price_' + fancys[j].selection_id).parent().attr('disabled', 'disabled');
                          newtr.setAttribute('data-title', "BALL RUNNING");
                          newtr.classList.add("suspended");

                          document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = "-";
                          document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
                          document.getElementById('fancy_lay_size_' + fancys[j].selection_id).innerHTML = 'Ball Running';
                          document.getElementById('fancy_back_size_' + fancys[j].selection_id).innerHTML = 'Ball Running';

                        } else if (fancys[j].game_status == 'SUSPENDED') {
                          newtr.setAttribute('data-title', "SUSPENDED");
                          newtr.classList.add("suspended");
                          document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = "-";
                          document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
                          document.getElementById('fancy_lay_size_' + fancys[j].selection_id).innerHTML = 'SUSPENDED';
                          document.getElementById('fancy_back_size_' + fancys[j].selection_id).innerHTML = 'SUSPENDED';

                        } else if (fancys[j].back_price1 == 0) {
                          newtr.classList.remove("suspended");
                          document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = '-';
                          document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
                          document.getElementById('fancy_lay_size_' + fancys[j].selection_id).innerHTML = 'SUSPENDED';
                          document.getElementById('fancy_back_size_' + fancys[j].selection_id).innerHTML = 'SUSPENDED';

                        } else {
                          newtr.classList.remove("suspended");
                          // console.log('fancy_lay_price_',document.getElementsByClassName('fancy_lay_price_' + fancys[j].selection_id).innerHTML);
                          document.getElementById('fancy_lay_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_price1);
                          document.getElementById('fancy_back_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_price1);
                          document.getElementById('fancy_lay_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_size1);
                          document.getElementById('fancy_back_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_size1);

                        }
                      }
                    }
                  }

                } else {

                  var fancyHtml = '';

                  if (!document.getElementsByClassName('fancy_' + fancys[j].selection_id)) {
                    // fancyHtml += '<div class="block_box f_m_' + fancys[j].match_id + ' fancy_' + fancys[j].selection_id + ' f_m_31236" data-id="31236">';

                    // fancyHtml += '<ul class="sport-high fancyListDiv">';
                    // fancyHtml += '<li>';
                    // fancyHtml += '<div class="ses-fan-box">';
                    // fancyHtml += '<table class="table table-striped  bulk_actions">';
                    // fancyHtml += '<tbody>';








                    fancyHtml += `<tr class="MuiTableRow-root fancy_${fancys[j].selection_id}">`;
                    fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body table_first_row">`;
                    fancyHtml += `<div class="text_left">
                    <button onclick=getFancyPosition(${fancys[j].selection_id}) className="book-fancy">Book</button>
                    <p >${fancys[j].runner_name}</p></div>`;
                    fancyHtml += `</td>`;
                    fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;

                    fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;
                    fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;
                    fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body xs_none MuiTableCell-alignRight"></td>`;

                    fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body bg_even MuiTableCell-alignRight" onclick=
                    handleOpen(
                      ${eventId},
                      "",
                      false,
                      ${fancys[j].lay_price1},
                      true,
                      ${fancys[j].selection_id},
                      ${fancys[j].runner_name},
                      fancy_lay_price_${fancys[j].selection_id}
                    )>`;
                    fancyHtml += `<div class="fancy-td-cell"><div class="fancy_lay_price_${fancys[j].selection_id}" id="fancy_lay_price_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_price1)}</div><p class="eventP fancy_lay_size_${fancys[j].selection_id}" id="fancy_lay_size_${fancys[j].selection_id}">${parseFloat(fancys[j].lay_size1)}</p></div>`;
                    fancyHtml += `</td >`;
                    fancyHtml += `<td class="MuiTableCell-root MuiTableCell-body bg_odd sus-parent MuiTableCell-alignRight" onclick=
                    handleOpen(
                      ${eventId},
                      "",
                      true,
                      ${fancys[j].back_price1},
                      true,
                      ${fancys[j].selection_id},
                      ${fancys[j].runner_name},
                      fancy_lay_price_${fancys[j].selection_id}
                    )>`;
                    fancyHtml += `<div class="fancy-td-cell"><div class="fancy_back_price_${fancys[j].selection_id}" id="fancy_back_price_${fancys[j].selection_id}" >${parseFloat(fancys[j].back_price1)} </div> <p class="eventP fancy_back_size_${fancys[j].selection_id}" id="fancy_back_size_${fancys[j].selection_id}">${parseFloat(fancys[j].back_size1)}</p></div></td>`;

                    fancyHtml += `</tr >`;



                    // fancyHtml += '<tr class="MuiTableRow-root">';
                    // fancyHtml += '<td class="MuiTableCell-root MuiTableCell-body table_first_row">';
                    // fancyHtml += '<div  class="fancyhead' + fancys[j].selection_id + '" id="fancy_name' + fancys[j].selection_id + '">' + fancys[j].runner_name + '</div><b class="fancyLia' + fancys[j].selection_id + '"></b><p class="position_btn"><button class="btn btn-xs btn-danger" onclick="getPosition(' + fancys[j].selection_id + ')">Book</button></td>';


                    // fancyHtml += '<td></td>';
                    // fancyHtml += '<td></td>';

                    // fancyHtml += '<td class="fancy_lay" id="fancy_lay_' + fancys[j].selection_id + '" onclick="betfancy(`' + fancys[j].match_id + '`,`' + fancys[j].selection_id + '`,`' + 0 + '`);">';

                    // fancyHtml += '<button class="lay-cell cell-btn fancy_lay_price_' + fancys[j].selection_id + '" id="LayNO_' + fancys[j].selection_id + '">' + fancys[j].lay_price1 + '</button>';

                    // fancyHtml += '<button id="NoValume_' + fancys[j].selection_id + '" class="disab-btn fancy_lay_size_' + fancys[j].selection_id + '">' + fancys[j].lay_size1 + '</button></td>';

                    // fancyHtml += '<td class="fancy_back" onclick="betfancy(`' + fancys[j].match_id + '`,`' + fancys[j].selection_id + '`,`' + 1 + '`);">';

                    // fancyHtml += '<button class="back-cell cell-btn fancy_back_price_' + fancys[j].selection_id + '" id="BackYes_' + fancys[j].selection_id + '">' + fancys[j].back_price1 + '</button>';

                    // fancyHtml += '<button id="YesValume_' + fancys[j].selection_id + '" class="disab-btn fancy_back_size_' + fancys[j].selection_id + '">' + fancys[j].back_size1 + '</button>';
                    // fancyHtml += '</td>';

                    // fancyHtml += '<td>';
                    // fancyHtml += '</td>';
                    // fancyHtml += '<td>';
                    // fancyHtml += '</td>';
                    // fancyHtml += '</tr>';
                    // fancyHtml += '</tbody>';
                    // fancyHtml += '</table>';
                    // fancyHtml += '</div>';
                    // fancyHtml += '</li>';
                    // fancyHtml += '</ul></div>';
                    // console.log('fancy html', fancyHtml);
                    if (document.getElementById('fancyAPI')) {
                      document.getElementById('fancyAPI').innerHTML += fancyHtml;
                    }
                  }

                  if (fancys[j].game_status == 'Ball Running') {
                    // console.log('getfdsaf', document.getElementsByClassName('fancy_lay_price_' + fancys[j].selection_id));
                    // $('.fancy_lay_price_' + fancys[j].selection_id).parent().attr('disabled', 'disabled');
                    // $('.fancy_back_price_' + fancys[j].selection_id).parent().attr('disabled', 'disabled');
                    document.getElementById('fancy_' + fancys[j].selection_id).setAttribute('data-title', "BALL RUNNING");
                    document.getElementById('fancy_' + fancys[j].selection_id).classList.add("suspended");

                    document.getElementsByClassName('fancy_lay_price_' + fancys[j].selection_id).innerHTML = "-";
                    document.getElementsByClassName('fancy_back_price_' + fancys[j].selection_id).innerHTML = '-';
                    document.getElementsByClassName('fancy_lay_size_' + fancys[j].selection_id).innerHTML = 'Ball Running';
                    document.getElementsByClassName('fancy_back_size_' + fancys[j].selection_id).innerHTML = 'Ball Running';
                  } else {
                    document.getElementById('fancy_' + fancys[j].selection_id).classList.remove("suspended");
                    document.getElementsByClassName('fancy_lay_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_price1);
                    document.getElementsByClassName('fancy_back_price_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_price1);
                    document.getElementsByClassName('fancy_lay_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].lay_size1);
                    document.getElementsByClassName('fancy_back_size_' + fancys[j].selection_id).innerHTML = parseFloat(fancys[j].back_size1);
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  async function getTvUrl(eventId) {
    await fetch(`${Appconfig.marketSarket}livetvurl/${eventId}`)
      .then(response => response.json())
      .then(data => setTvUrl(data.livetv));

  }
  async function getScoreUrl(eventId) {
    await fetch(`${Appconfig.marketSarket}animurl/${eventId}`)
      .then(response => response.json())
      .then(data => setScoreUrl(data.animation));
  }





  function SetPosition(stake, priceVal, market_id, is_back, selection_id) {
    // console.log([StakeValue, priceVal, market_id, is_back, selection_id]);
    priceVal = parseFloat(priceVal)
    var MarketId = market_id;
    var MId = MarketId.replace('.', '');
    var selectionId = selection_id;
    var isback = is_back;
    stake = parseFloat(stake);
    let MatchMarketTypes = "";
    //  var MatchMarketTypes = 'M';
    var runners = document.getElementsByClassName("position_" + MId);
    var tempRunners = "";
    // console.log("tempRunnersv", runners);
    for (var item of runners) {
      var selecid = item.getAttribute('data-id');
      var winloss = parseFloat(item.value);
      var curr = 0;


      if (selectionId == selecid) {
        if (isback) {
          if (MatchMarketTypes == 'M') {
            curr = winloss + ((priceVal * stake) / 100);


          } else {
            curr = winloss + ((priceVal * stake) - stake);


          }
        } else {
          if (MatchMarketTypes == 'M') {
            curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));


          } else {

            curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));


          }
        }
      } else {
        if (isback == 1) {
          curr = winloss + (-1 * (stake));
        } else {
          curr = winloss + stake;
        }
      }
      var currV = Math.round(curr);


      // alert(document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).innerHTML);
      if (document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId)) {

        document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).setAttribute('data-value', winloss)

        document.getElementById(selecid + "_maxprofit_loss_runner_prev_" + MId).innerHTML = Math.abs(winloss);
      }

      if (document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId)) {
        document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).setAttribute('data-value', currV)
        document.getElementById(selecid + "_maxprofit_list_loss_runner_prev_" + MId).innerHTML = Math.abs(currV);

        // document.getElementById(selecid + "_maxprofit_loss_runner_" + MId).setAttribute('data-value', currV)

        // document.getElementById(selecid + "_maxprofit_loss_runner_" + MId).innerHTML = Math.abs(currV);
      }
    }
  }

  function getFancyPosition(selectionId) {

    var data = JSON.stringify({
      user_id: userInfo._id,
      event_id: eventId,
      fancy_id: selectionId
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}betting/getFancyPosition`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setPostionFancyList(response.data.resultData);
        setPositionOpen(true);
        // console.log('postionFancyList', response.data.resultData);
      })
      .catch(function (error) {
        console.log(error);
      });

    // console.log(postionFancyList)
  }

  function calc(t_stake, priceVal, selection_id) {
    var isfancy = BetPlaceData.is_fancy;
    priceVal = parseFloat(priceVal);
    t_stake = parseFloat(t_stake);
    var isback = BetPlaceData.is_back

    if (!isfancy) {
      // if (gameType == 'market') {
      //    if (MatchMarketTypes == 'M') {
      // var pl = Math.round((priceVal * t_stake) / 100);

      //    } else {
      var pl = Math.round((priceVal * t_stake) - t_stake);
      //    }
      // } else {
      // var pl = Math.round((priceVal * t_stake) / 100);
      // }
      pl = parseFloat(pl.toFixed(2));
      if (isback) {
        setProfitValue(pl)
        setLossValue(t_stake)
      } else {
        setLossValue(pl)
        setProfitValue(t_stake)
      }
      // SetPosition(priceVal);
    } else {
      if (document.getElementById('fancy_lay_size_' + selection_id)) {
        var NoValume = parseInt(document.getElementById('fancy_lay_size_' + selection_id).innerHTML);
        var YesValume = parseInt(document.getElementById('fancy_back_size_' + selection_id).innerHTML);
        var inputno = parseFloat(document.getElementById(`fancy_lay_price_${selection_id}`).innerHTML);
        var inputyes = parseFloat(document.getElementById(`fancy_back_price_${selection_id}`).innerHTML);

      }
      pl = parseFloat(t_stake);
      if (inputno == inputyes) {

        if (isback) {
          setLossValue(pl.toFixed(2))
          setProfitValue((YesValume * pl / 100).toFixed(2))
        } else {
          // console.log("inputno,inputyes,YesValume,NoValume", [inputno, inputyes, YesValume, NoValume])
          setLossValue((NoValume * pl / 100).toFixed(2))
          setProfitValue(pl.toFixed(2))
        }
      } else {
        setLossValue(pl.toFixed(2))
        setProfitValue(pl.toFixed(2))

      }
    }

  }
  const getUserGenSettings = () => {
    let id = userInfo._id;

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}generalSetting/getSettingByEvent`,
      headers: {
        'Content-Type': 'application/json',

      },
      data: JSON.stringify({ "user_id": id, event_id: eventId })
    };

    axios(config)
      .then(function (response) {
        console.log("response", response);
        let data = response.data.resultData;
        setUserOddsData({
          ...userOddsData,
          is_bookmaker_odds_off: data.is_bookmaker_odds_off,
          is_fancy_odds_off: data.is_fancy_odds_off,
          is_match_odds_off: data.is_match_odds_off,
        })

      })
  }
  return (
    <div>
      <Header isBalanceUpdated={isBalanceUpdated} />
      <Grid container className="bodypart">

        <Grid item lg={2} xs={2} className="xs_none tl-hide">
          <List
            component="nav"
            className="side_list"
            aria-label="secondary mailbox folders"
          >
            <ListItemLink href="#simple-list" className="active">
              <ListItemText primary="Sports" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="In-Play" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="Cricket" />
            </ListItemLink>
          </List>
        </Grid>



        <Grid item lg={6} xs={12} style={{ marginBottom: 101 }} className="my-accordian">
          <Grid container >
            <Grid xs={6} sm={6} item >
              <Button
                variant="contained"
                color="secondary"
                className="button-tv1"
                startIcon={<><MenuIcon style={{ color: scoreUrl ? "green" : "red" }} /></>}
                onClick={() => scoreUrl ? handleChangeAccordian('panel2') : console.log("there is not score")}

              >
                Live Score
              </Button>
            </Grid>
            {/* &nbsp; */}
            <Grid item xs={6} sm={6} >

              <Button
                variant="contained"
                color="secondary"
                className="button-tv"
                startIcon={<><TvIcon style={{ color: tvUrl ? "green" : "red" }} /></>}
                onClick={() => tvUrl ? handleChangeAccordian('panel1') : console.log("there is not tv")}

              >
                Live TV
              </Button>
            </Grid>
          </Grid>

          <Accordion square expanded={expanded.panel2} >
            <AccordionSummary aria-controls="panel2a-content" id="panel2a-header" style={{ display: 'none' }}>
            </AccordionSummary>
            <AccordionDetails className="event-iframe">
              <iframe src={scoreUrl} style={{ width: '100%', height: iframeHeight + "px" }} title="matchtv"></iframe>
            </AccordionDetails>
          </Accordion>

          <Accordion square expanded={expanded.panel1} style={{ margin: 0 }} className="my-accordian">
            <AccordionSummary aria-controls="panel1d-content" id="panel1fsdd-header" style={{ display: 'none' }}>
            </AccordionSummary>
            <AccordionDetails className="event-iframe">

              <iframe src={tvUrl} style={{ width: '100%' }} className="showtv" title="matchtv"></iframe>

            </AccordionDetails>
          </Accordion>

          <Grid container className="event_name_heading" style={{ marginBottom: 2 }}>
            <Grid xs={6} sm={7} item><span className="opentitle">{EventData.length > 0 ? EventData[0].event_name : ""}</span></Grid>
            <Grid xs={6} sm={5} item><span className="opendate">{EventData.length > 0 ? EventData[0].open_date : ""}</span></Grid>
          </Grid>

          <TableContainer component={Paper} className="event_tbl" style={{ marginBottom: 3 }}>

            {/* match odds start */}
            {EventData.length > 0 && EventData.map((event, index) =>
              event.marketTypes.map((market, index) =>
                market.marketRunners.length > 0 && market.market_name == "Match Odds" ? (
                  <Table className={classes.table} aria-label="simple table">

                    {oddsData.is_odds_off == "Yes" || userOddsData.is_match_odds_off ?

                      ""
                      :
                      <>
                        <TableHead>
                          <TableRow className="tblhead darktblhead">
                            <TableCell colSpan="7">{market.market_name}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow key="table_first_head" className="">
                          <TableCell className="table_first_head ">&nbsp; Min:0 Max: 0</TableCell>
                          <TableCell
                            align="right"
                            className="xs_none"
                          >

                          </TableCell>
                          <TableCell
                            align="right"
                            className="xs_none"
                          ></TableCell>
                          <TableCell
                            align="right"
                            className="xs_none"
                          ></TableCell>
                          <TableCell
                            align="right"
                            className="xs_none"
                          ></TableCell>
                          <TableCell align="center" className="bg_odd match_th_bg_even">
                            Back
                          </TableCell>
                          <TableCell align="center" className="bg_even match_th_bg_even">
                            Lay
                          </TableCell>
                        </TableRow>
                      </>
                    }

                    {oddsData.is_odds_off == "Yes" || userOddsData.is_match_odds_off ? "" :
                      <TableBody className="tbl_body">

                        {market.marketRunners.map((runner, i) => (
                          <>
                            <TableRow key={"market_" + i} className={`table_row_${market.market_id.replace('.', '')}_${runner.selection_id}  ${runner.status === "suspended" ? "suspended" : ""}`} data-title={runner.status === "suspended" ? "SUSPENDED" : ""}>
                              <TableCell className="table_first_row">
                                <div className="text_left">
                                  <p className={`runners_${market.market_id.replace('.', '')}   runner-name`}>{runner.runner_name}</p>

                                  <p data-value={runner.exposure} class="market-exposure" id={runner.selection_id + "_maxprofit_list_loss_runner_prev_" + market.market_id.replace('.', '')}>{Math.abs(runner.exposure.toFixed(2))}</p>
                                  <input type="hidden" className={`position_${market.market_id.replace('.', '')}`} data-id={runner.selection_id} value={runner.exposure.toFixed(2)} />
                                  {/* <p>&nbsp;</p> */}
                                </div>
                                {/* <div>
                        <p className="xs_none">{row.type}</p>
                        <p className="xs_none">&nbsp;</p>
                        </div> */}
                              </TableCell>

                              <TableCell
                                align="right"
                                onClick={() =>
                                  handleOpen(
                                    event.event_id,
                                    market.market_id,
                                    true,
                                    runner.back_1_price,
                                    false,
                                    runner.selection_id,
                                    runner.runner_name
                                  )
                                }
                                className="xs_none"
                              ></TableCell>
                              <TableCell
                                align="right"
                                onClick={() =>
                                  handleOpen(
                                    event.event_id,
                                    market.market_id,
                                    true,
                                    runner.back_1_price,
                                    false,
                                    runner.selection_id,
                                    runner.runner_name
                                  )
                                }
                                className="xs_none"
                              ></TableCell>
                              <TableCell
                                align="right"
                                onClick={() =>
                                  handleOpen(
                                    event.event_id,
                                    market.market_id,
                                    true,
                                    runner.back_1_price,
                                    false,
                                    runner.selection_id,
                                    runner.runner_name
                                  )
                                }
                                className="xs_none"
                              ></TableCell>
                              <TableCell
                                align="right"
                                onClick={() =>
                                  handleOpen(
                                    event.event_id,
                                    market.market_id,
                                    true,
                                    runner.back_1_price,
                                    false,
                                    runner.selection_id,
                                    runner.runner_name
                                  )
                                }
                                className="xs_none"
                              >
                              </TableCell>
                              <TableCell
                                align="right"
                                onClick={() =>
                                  handleOpen(
                                    event.event_id,
                                    market.market_id,
                                    true,
                                    runner.back_1_price,
                                    false,
                                    runner.selection_id,
                                    runner.runner_name,
                                    `availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                  )
                                }
                                className="bg_odd sus-parent"
                              >
                                <div className="td-cell">
                                  <div id={`availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{parseFloat(runner.back_1_price ? runner.back_1_price : 0)}</div>
                                  <p className="eventP" id={`availableToBack1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{parseFloat(runner.back_1_size ? runner.back_1_size : 0)}</p>
                                </div>

                              </TableCell>
                              <TableCell
                                align="right"
                                onClick={() =>
                                  handleOpen(
                                    event.event_id,
                                    market.market_id,
                                    false,
                                    runner.lay_1_price,
                                    false,
                                    runner.selection_id,
                                    runner.runner_name,
                                    `availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                  )
                                }
                                className="bg_even"
                              >
                                <div className="td-cell">
                                  <div id={`availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{parseFloat(runner.lay_1_price ? runner.lay_1_price : 0)}</div>

                                  <p className="eventP" id={`availableToLay1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{parseFloat(runner.lay_1_size ? runner.lay_1_size : 0)}</p>
                                </div>
                                {/* {runner.status === "suspended" ? (
                                <div className="suspended-cell">Suspended</div>
                              ) : (
                                ""
                              )
                              } */}
                              </TableCell>
                            </TableRow>


                            <TableRow p={1} key={"place-bet-block_" + i} className={`pbb place-bet-block_${market.market_id.replace('.', '')}_${runner.selection_id}`} button>
                              <TableCell className={`xs-bet-slip ${BetPlaceData.is_back ? "bcolor" : "lcolor"}`} colSpan="7">
                                <div className="bet-list" >
                                  <div className="input-num">
                                    <div>
                                      <a href="javascript:void(0)" onClick={() => setBetPlaceData({ ...BetPlaceData, price: (parseFloat(BetPlaceData.price) - 1).toFixed(2) })} style={{ borderTopLeftRadius: '1.6vw', borderBottomLeftRadius: '1.6vw' }}>
                                        <RemoveIcon />
                                      </a>
                                      <span >{BetPlaceData.price}</span>
                                      <a href="javascript:void(0)" onClick={() => setBetPlaceData({ ...BetPlaceData, price: (parseFloat(BetPlaceData.price) + 1).toFixed(2) })} style={{ borderTopRightRadius: '1.6vw', borderBottomRightRadius: '1.6vw' }}>
                                        <AddIcon />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="input-num">
                                    {/* <p>Min Bet</p> */}
                                    <div>
                                      <a href="javascript:void(0)" onClick={() => placeStakeValue(StakeValue - 1)} style={{ borderTopLeftRadius: '1.6vw', borderBottomLeftRadius: '1.6vw' }}>
                                        <RemoveIcon />
                                      </a>
                                      <input type="number" value={StakeValue}
                                        name="stake"
                                        onChange={handleBetInputChange} />
                                      <a href="javascript:void(0)" onClick={() => placeStakeValue(StakeValue + 1)} style={{ borderTopRightRadius: '1.6vw', borderBottomRightRadius: '1.6vw' }}>
                                        <AddIcon />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="btn-list-new">
                                  {chipList.map((chip, index) => {

                                    if (index < 10)
                                      return <>
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => placeStakeValue(chip.chip_value)}
                                          className=" chips-btn"
                                        >
                                          {chip.chip_label}
                                        </a>
                                      </>
                                  })
                                  }
                                </div>
                                <div className="btn-list">
                                  <a className="cancelbtn" href="javascript:void(0)" onClick={hideAllBetBox}>Cancel</a>
                                  <a className={!StakeValue || placing ? "placebtn disable" : "placebtn"} href="javascript:void(0)" onClick={() => betPlace()}>
                                    {placing ? "Placing...." : "Place Bet"}
                                    {placing ? <CircularProgress size={20} style={{ color: "black", marginLeft: 5, verticalAlign: "middle" }} /> : ""}
                                  </a>
                                </div>

                              </TableCell>
                            </TableRow>


                          </>
                        ))}
                      </TableBody>
                    }
                  </Table>
                ) : null
              )
            )}

            {/* match odds end */}


            {/* bookmaker start */}

            {EventData.length > 0 && EventData.map((event, index) =>
              event.marketTypes.map((market, index) =>
                market.marketRunners.length > 0 && market.market_name == "Bookmaker" ? (
                  <Table className={classes.table} aria-label="simple table">

                    {oddsData.is_odds_off == "Yes" || userOddsData.is_bookmaker_odds_off ?

                      ""
                      :
                      <>
                        <TableHead>
                          <TableRow className="tblhead darktblhead">
                            <TableCell colSpan="7">{market.market_name}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow key={"table_first_head_new"} className="">
                          <TableCell className="table_first_head ">&nbsp; Min:0 Max: 0</TableCell>
                          <TableCell
                            align="right"
                            className="xs_none"
                          >

                          </TableCell>
                          <TableCell
                            align="right"
                            className="xs_none"
                          ></TableCell>
                          <TableCell
                            align="right"
                            className="xs_none"
                          ></TableCell>
                          <TableCell
                            align="right"
                            className="xs_none"
                          ></TableCell>
                          <TableCell align="center" className="bg_odd match_th_bg_even">
                            Back
                          </TableCell>
                          <TableCell align="center" className="bg_even match_th_bg_even">
                            Lay
                          </TableCell>
                        </TableRow>
                      </>

                    }

                    {oddsData.is_odds_off == "Yes" || userOddsData.is_bookmaker_odds_off ? "" :
                      <TableBody className="tbl_body">

                        {market.marketRunners.map((runner, i) => (
                          <>
                            <TableRow key={"table_row_" + i} className={`table_row_${market.market_id.replace('.', '')}_${runner.selection_id}  ${runner.status === "suspended" ? "suspended" : ""}`} data-title={runner.status === "suspended" ? "SUSPENDED" : ""}>
                              <TableCell className="table_first_row">
                                <div className="text_left">
                                  <p className={`runners_${market.market_id.replace('.', '')}   runner-name`}>{runner.runner_name}</p>

                                  <p data-value={runner.exposure} class="market-exposure" id={runner.selection_id + "_maxprofit_list_loss_runner_prev_" + market.market_id.replace('.', '')}>{Math.abs(runner.exposure.toFixed(2))}</p>
                                  <input type="hidden" className={`position_${market.market_id.replace('.', '')}`} data-id={runner.selection_id} value={runner.exposure.toFixed(2)} />
                                  {/* <p>&nbsp;</p> */}
                                </div>
                                {/* <div>
                        <p className="xs_none">{row.type}</p>
                        <p className="xs_none">&nbsp;</p>
                        </div> */}
                              </TableCell>

                              <TableCell
                                align="right"
                                onClick={() =>
                                  handleOpen(
                                    event.event_id,
                                    market.market_id,
                                    true,
                                    runner.back_1_price,
                                    false,
                                    runner.selection_id,
                                    runner.runner_name
                                  )
                                }
                                className="xs_none"
                              ></TableCell>
                              <TableCell
                                align="right"
                                onClick={() =>
                                  handleOpen(
                                    event.event_id,
                                    market.market_id,
                                    true,
                                    runner.back_1_price,
                                    false,
                                    runner.selection_id,
                                    runner.runner_name
                                  )
                                }
                                className="xs_none"
                              ></TableCell>
                              <TableCell
                                align="right"
                                onClick={() =>
                                  handleOpen(
                                    event.event_id,
                                    market.market_id,
                                    true,
                                    runner.back_1_price,
                                    false,
                                    runner.selection_id,
                                    runner.runner_name
                                  )
                                }
                                className="xs_none"
                              ></TableCell>
                              <TableCell
                                align="right"
                                onClick={() =>
                                  handleOpen(
                                    event.event_id,
                                    market.market_id,
                                    true,
                                    runner.back_1_price,
                                    false,
                                    runner.selection_id,
                                    runner.runner_name
                                  )
                                }
                                className="xs_none"
                              >
                              </TableCell>
                              <TableCell
                                align="right"
                                onClick={() =>
                                  handleOpen(
                                    event.event_id,
                                    market.market_id,
                                    true,
                                    runner.back_1_price,
                                    false,
                                    runner.selection_id,
                                    runner.runner_name,
                                    `availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                  )
                                }
                                className="bg_odd sus-parent"
                              >
                                <div className="td-cell">
                                  <div id={`availableToBack1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{parseFloat(runner.back_1_price ? runner.back_1_price : 0)}</div>
                                  <p className="eventP" id={`availableToBack1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{parseFloat(runner.back_1_size ? runner.back_1_size : 0)}</p>
                                </div>

                              </TableCell>
                              <TableCell
                                align="right"
                                onClick={() =>
                                  handleOpen(
                                    event.event_id,
                                    market.market_id,
                                    false,
                                    runner.lay_1_price,
                                    false,
                                    runner.selection_id,
                                    runner.runner_name,
                                    `availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`
                                  )
                                }
                                className="bg_even"
                              >
                                <div className="td-cell">
                                  <div id={`availableToLay1_price_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{parseFloat(runner.lay_1_price ? runner.lay_1_price : 0)}</div>

                                  <p className="eventP" id={`availableToLay1_size_${market.market_id.replace('.', '')}_${runner.selection_id}`}>{parseFloat(runner.lay_1_size ? runner.lay_1_size : 0)}</p>
                                </div>
                                {/* {runner.status === "suspended" ? (
                                <div className="suspended-cell">Suspended</div>
                              ) : (
                                ""
                              )
                              } */}
                              </TableCell>
                            </TableRow>


                            <TableRow p={1} key={"keys_" + i} className={`pbb place-bet-block_${market.market_id.replace('.', '')}_${runner.selection_id}`} button>
                              <TableCell className={`xs-bet-slip ${BetPlaceData.is_back ? "bcolor" : "lcolor"}`} colSpan="7">
                                <div className="bet-list" >
                                  <div className="input-num">
                                    <div>
                                      <a href="javascript:void(0)" onClick={() => setBetPlaceData({ ...BetPlaceData, price: (parseFloat(BetPlaceData.price) - 1).toFixed(2) })} style={{ borderTopLeftRadius: '1.6vw', borderBottomLeftRadius: '1.6vw' }}>
                                        <RemoveIcon />
                                      </a>
                                      <span >{BetPlaceData.price}</span>
                                      <a href="javascript:void(0)" onClick={() => setBetPlaceData({ ...BetPlaceData, price: (parseFloat(BetPlaceData.price) + 1).toFixed(2) })} style={{ borderTopRightRadius: '1.6vw', borderBottomRightRadius: '1.6vw' }}>
                                        <AddIcon />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="input-num">
                                    {/* <p>Min Bet</p> */}
                                    <div>
                                      <a href="javascript:void(0)" onClick={() => placeStakeValue(StakeValue - 1)} style={{ borderTopLeftRadius: '1.6vw', borderBottomLeftRadius: '1.6vw' }}>
                                        <RemoveIcon />
                                      </a>
                                      <input type="number" value={StakeValue}
                                        name="stake"
                                        onChange={handleBetInputChange} />
                                      <a href="javascript:void(0)" onClick={() => placeStakeValue(StakeValue + 1)} style={{ borderTopRightRadius: '1.6vw', borderBottomRightRadius: '1.6vw' }}>
                                        <AddIcon />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="btn-list-new">
                                  {chipList.map((chip, index) => {

                                    if (index < 10)
                                      return <>
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => placeStakeValue(chip.chip_value)}
                                          className=" chips-btn"
                                        >
                                          {chip.chip_label}
                                        </a>
                                      </>
                                  })
                                  }
                                </div>
                                <div className="btn-list">
                                  <a className="cancelbtn" href="javascript:void(0)" onClick={hideAllBetBox}>Cancel</a>
                                  <a className={!StakeValue || placing ? "placebtn disable" : "placebtn"} href="javascript:void(0)" onClick={() => betPlace()}>
                                    {placing ? "Placing...." : "Place Bet"}
                                    {placing ? <CircularProgress size={20} style={{ color: "black", marginLeft: 5, verticalAlign: "middle" }} /> : ""}
                                  </a>
                                </div>

                              </TableCell>
                            </TableRow>


                          </>
                        ))}
                      </TableBody>
                    }
                  </Table>
                ) : null
              )
            )}
            {/* bookmaker end */}

            <Table className={classes.table} aria-label="simple table">


              {oddsData.is_fancy_off == "Yes" || userOddsData.is_fancy_odds_off ? "" :

                EventData.length > 0 && EventData[0].hasOwnProperty("fancy") ?
                  <>
                    <TableHead>
                      <TableRow className="tblhead darktblhead">
                        <TableCell colSpan="7">Fancy</TableCell>
                      </TableRow>
                      <TableRow className="">
                        <TableCell className="table_first_head "> &nbsp;Min:{0} Max: {0}</TableCell>
                        <TableCell align="right" className="xs_none"></TableCell>
                        <TableCell align="right" className="xs_none"></TableCell>
                        <TableCell align="right" className="xs_none"></TableCell>
                        <TableCell align="right" className="xs_none"></TableCell>
                        <TableCell align="right" className="bg_even fancy_th_bg_even" style={{ textAlign: 'center' }}>No</TableCell>
                        <TableCell align="right" className="bg_odd  fancy_th_bg_odd" style={{ textAlign: 'center' }}>Yes</TableCell>
                      </TableRow>
                    </TableHead>
                  </> : ""
              }


              {oddsData.is_fancy_off == "Yes" || userOddsData.is_fancy_odds_off ? "" :
                <TableBody className="tbl_body " id="fancyAPI">


                  {EventData.length > 0 && EventData.map((event, index) =>
                    event.fancy.map((fancy, i) => (
                      <>
                        <TableRow className={`fancy_${fancy.selection_id} ${fancy.game_status === "SUSPENDED" ? "suspended" : ""}`} data-title={fancy.game_status === "SUSPENDED" ? "SUSPENDED" : ""}>
                          <TableCell className="table_first_row">
                            <div className="text_left">
                              <button onClick={() => getFancyPosition(fancy.selection_id)} className="book-fancy">Book</button>
                              <p >{fancy.runner_name}</p>
                              {/* <p data-value={0} class="market-exposure">0</p> */}
                              {/* <p>&nbsp;</p> */}

                            </div>

                          </TableCell>
                          <TableCell
                            align="right"
                            onClick={() =>
                              handleOpen(event.event_id, "", true, "", false)
                            }
                            className="xs_none"
                          ></TableCell>
                          <TableCell
                            align="right"
                            onClick={() => handleOpen("1", "", true, "", false)}
                            className="xs_none"
                          ></TableCell>
                          <TableCell
                            align="right"
                            onClick={() => handleOpen("1", "", true, "", false)}
                            className="xs_none"
                          ></TableCell>
                          <TableCell
                            align="right"
                            onClick={() => handleOpen("1", "", true, "", false)}
                            className="xs_none"
                          ></TableCell>
                          <TableCell
                            align="right"
                            onClick={() =>
                              handleOpen(
                                event.event_id,
                                "",
                                false,
                                fancy.lay_price1,
                                true,
                                fancy.selection_id,
                                fancy.runner_name,
                                `fancy_lay_price_${fancy.selection_id}`
                              )
                            }
                            className="bg_even"
                          >
                            <div className="fancy-td-cell">
                              <div id={`fancy_lay_price_${fancy.selection_id}`}>{parseFloat(fancy.lay_price1)}</div>
                              <p className="eventP" id={'fancy_lay_size_' + fancy.selection_id}>{parseFloat(fancy.lay_size1)}</p>
                            </div>
                            {/* {fancy.game_status === "SUSPENDED" ? (
                            <div className="suspended-cell">Suspended</div>
                          ) : (
                            ""
                          )} */}
                          </TableCell>
                          <TableCell
                            align="right"
                            onClick={() =>
                              handleOpen(
                                event.event_id,
                                "",
                                true,
                                fancy.back_price1,
                                true,
                                fancy.selection_id,
                                fancy.runner_name,
                                `fancy_back_price_${fancy.selection_id}`
                              )
                            }
                            className="bg_odd sus-parent"
                          >
                            <div className="fancy-td-cell">
                              <div id={`fancy_back_price_${fancy.selection_id}`}>{parseFloat(fancy.back_price1)}</div>
                              <p className="eventP" id={'fancy_back_size_' + fancy.selection_id}>{parseFloat(fancy.back_size1)}</p>
                            </div>

                          </TableCell>

                        </TableRow>

                        <TableRow p={1} className={`pbb place-fancybet-block_${fancy.match_id}_${fancy.selection_id}`} button>
                          <TableCell className={`xs-bet-slip ${BetPlaceData.is_back ? "bcolor" : "lcolor"}`} colSpan="7">
                            <div className="bet-list" >
                              <div>

                                <Button className="typed" variant="contained">{BetPlaceData.price}</Button>
                              </div>

                              <div className="input-num">
                                {/* <p>Min Bet</p> */}
                                <div>
                                  <a href="javascript:void(0)" onClick={() => placeStakeValue(StakeValue - 1)} style={{ borderTopLeftRadius: '1.6vw', borderBottomLeftRadius: '1.6vw' }}>
                                    <RemoveIcon />
                                  </a>
                                  <input type="number" value={StakeValue}
                                    name="stake"
                                    onChange={handleBetInputChange} />
                                  <a href="javascript:void(0)" onClick={() => placeStakeValue(StakeValue + 1)} style={{ borderTopRightRadius: '1.6vw', borderBottomRightRadius: '1.6vw' }}>
                                    <AddIcon />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="btn-list-new">
                              {chipList.map((chip, index) => {

                                if (index < 10)
                                  return <>
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() => placeStakeValue(chip.chip_value)}
                                      className=" chips-btn"
                                    >
                                      {chip.chip_label}
                                    </a>
                                  </>
                              })
                              }
                            </div>
                            <div className="btn-list">
                              <a className="cancelbtn" href="javascript:void(0)" onClick={hideAllBetBox}>Cancel</a>
                              <a className={!StakeValue || placing ? "placebtn disable" : "placebtn"} href="javascript:void(0)" onClick={() => betPlace()}>
                                {placing ? "Placing...." : "Place Bet"}
                                {placing ? <CircularProgress size={20} style={{ color: "black", marginLeft: 5, verticalAlign: "middle" }} /> : ""}
                              </a>
                            </div>

                          </TableCell>
                        </TableRow>


                      </>
                    ))
                  )}
                </TableBody>
              }
            </Table>
          </TableContainer>




          <div className="betTab">
            <AppBar position="static">
              <Tabs
                value={value}
                variant="fullWidth"
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label={`All Bet (${betSlipList.length})`} />
                <Tab label={`Fancy Bet (${betSlipList.length > 0 ? betSlipList[0].fancyCount : ""})`} />
                {
                  userInfo.user_type != "User" && <Tab onClick={() => getCurrentPosition()} label={`Current Position`} />
                }


              </Tabs>
            </AppBar>
            <TabPanel className="tabBlock" value={value} index={0}>
              <TableContainer component={Paper} className="bet-slip">
                <Table className={classes.table} id="betList" aria-label="simple table">

                  <TableHead>
                    <TableRow className="darktblhead">
                      <TableCell style={{ width: 15 }}>No.</TableCell>
                      {userInfo.user_type != "User" ? <TableCell style={{ width: 100 }}>User</TableCell> : ""}
                      <TableCell style={{ width: 280 }}>Runner</TableCell>
                      <TableCell style={{ width: 100 }}>Odds</TableCell>
                      <TableCell style={{ width: 100 }}>Stack</TableCell>
                      <TableCell style={{ width: 100 }}>P_L</TableCell>
                      <TableCell style={{ width: 100 }}>Bet Type</TableCell>
                      <TableCell style={{ width: 275 }}>Time</TableCell>
                      <TableCell>Id</TableCell>
                      <TableCell>IP</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {betSlipList.length > 0 &&
                      betSlipList.map((row, index) => (
                        <TableRow className={row.is_back == 0 ? "bg_even" : "bg_odd"}>
                          <TableCell>{index + 1}</TableCell>
                          {userInfo.user_type != "User" ? <TableCell >{row.client}</TableCell> : ""}
                          <TableCell>{row.runner_name}</TableCell>
                          <TableCell>{row.odds}</TableCell>
                          <TableCell>{row.stake}</TableCell>
                          <TableCell>{row.profit}</TableCell>
                          <TableCell>{row.betting_type}</TableCell>
                          <TableCell>{moment(row.createdAt).format("YYYY-MM-DD h:mm:ss a")}</TableCell>
                          <TableCell>{row.hasOwnProperty("_id") ? (row._id).substr(-6) : ""}</TableCell>
                          <TableCell>{row.hasOwnProperty("ip_address") ? row.ip_address.replace('::ffff:', '') : ""}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel className="tabBlock" value={value} index={1}>
              <TableContainer component={Paper} className="bet-slip">
                <Table className={classes.table} id="betList" aria-label="simple table" >
                  <TableHead>
                    <TableRow className="darktblhead">
                      <TableCell style={{ width: 15 }}>No.</TableCell>
                      {userInfo.user_type != "User" ? <TableCell style={{ width: 100 }}>User</TableCell> : ""}
                      <TableCell style={{ width: 280 }}>Runner</TableCell>
                      <TableCell style={{ width: 100 }}>Odds</TableCell>
                      <TableCell style={{ width: 100 }}>Stack</TableCell>
                      <TableCell style={{ width: 100 }}>P_L</TableCell>
                      <TableCell style={{ width: 100 }}>Bet Type</TableCell>
                      <TableCell style={{ width: 275 }}>Time</TableCell>
                      <TableCell>Id</TableCell>
                      <TableCell>IP</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {betSlipList.length >= 0 && betSlipList.map((row, index) => (
                      row.betting_type != "Fancy" ? "" :
                        <TableRow className={row.is_back == 0 ? "bg_even" : "bg_odd"}>
                          <TableCell>{index + 1}</TableCell>
                          {userInfo.user_type != "User" ? <TableCell >{row.client}</TableCell> : ""}
                          <TableCell>{row.runner_name}</TableCell>
                          <TableCell>{row.odds}</TableCell>
                          <TableCell>{row.stake}</TableCell>
                          <TableCell>{row.profit}</TableCell>
                          <TableCell>{row.betting_type}</TableCell>
                          <TableCell>{moment(row.createdAt).format("YYYY-MM-DD h:mm:ss a")}</TableCell>
                          <TableCell>{row.hasOwnProperty("_id") ? (row._id).substr(-6) : ""}</TableCell>
                          <TableCell>{row.hasOwnProperty("ip_address") ? row.ip_address.replace('::ffff:', '') : ""}</TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel className="tabBlock" value={value} index={2}>
              <TableContainer component={Paper} className="bet-slip">
                <Table className={classes.table} aria-label="simple table" >
                  <TableHead>
                    <TableRow className="darktblhead">
                      <TableCell style={{ width: 15 }}>No.</TableCell>
                      <TableCell style={{ width: 100 }}>User</TableCell>
                      {
                        prevRunners.map((runner, index) => {
                          return <TableCell style={{ width: 100, textAlign: "center" }}>{runner}</TableCell>
                        })
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {currentPositionList.length >= 0 && currentPositionList.map((row, index) => (

                      <TableRow >
                        <TableCell style={{ width: 15 }}>{index + 1}</TableCell>
                        {
                          row.user_type == 'User' ?
                            <TableCell style={{ width: 200, fontWeight: 600 }}>{row.user_name} ({row.name})</TableCell> : <TableCell onClick={() => getCurrentPosition(row.user_id)} style={{ width: 200, fontWeight: 600 }}>{row.user_name} ({row.name})</TableCell>
                        }


                        {row.exposure.length >= 0 && row.exposure.map((exp, index) => (
                          <TableCell className="current-position" style={{ width: 100, textAlign: "center" }} data-value={exp.total_pl}>{Math.abs(exp.total_pl.toFixed(2))}</TableCell>
                        ))
                        }

                        {
                          row.exposure.length == 2 ?
                            <TableCell className="current-position" style={{ width: 100, textAlign: "center" }} data-value={0} >0</TableCell> : null

                        }
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={2} align="center">Total P/L</TableCell>
                      {selfTotalExposure && selfTotalExposure.length >= 0 && selfTotalExposure.map((exp, index) => (
                        <TableCell className="current-position" style={{ width: 100, textAlign: "center" }} data-value={exp.total_pl}>{Math.abs(exp.total_pl.toFixed(2))}</TableCell>
                      ))
                      }


                    </TableRow>
                    <TableRow>

                      <TableCell colSpan={2} align="center">Admin P&L</TableCell>
                      {selfExposure && selfExposure.length >= 0 && selfExposure.map((exp, index) => (
                        <TableCell className="current-position" style={{ width: 100, textAlign: "center" }} data-value={exp.total_pl}>{Math.abs(exp.total_pl.toFixed(2))}</TableCell>
                      ))
                      }
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} align="center">Super P&L</TableCell>
                      {uplineExposure && uplineExposure.length >= 0 && uplineExposure.map((exp, index) => (
                        <TableCell className="current-position" style={{ width: 100, textAlign: "center" }} data-value={exp.total_pl}>{Math.abs(exp.total_pl.toFixed(2))}</TableCell>
                      ))
                      }
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </TabPanel>

          </div>
        </Grid>
        <Grid item lg={4} xs={12} className="event-single-popup">
          {/* <form className="xs_none" noValidate autoComplete="off">
                        <Grid container >
                            <Grid item xs={6} sm={3}>
                                <p>Back (Bet For) </p>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <p>Profit </p>
                                <p className="colorDanger">0 </p>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <p>Loss </p>
                                <p className="colorSuccess">0 </p>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <p>Surrey </p>
                            </Grid>
                            <Grid className="flex-row">                
                                <Grid className="colmun">
                                    <TextField label="Size" id="outlined-size-small" variant="outlined" size="small" />
                                </Grid>
                                <Grid className="colmun">
                                    <TextField label="Stake" id="outlined-size-small" variant="outlined" size="small" />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} className="modal_number">
                                <Button variant="contained" className="eventSingleBtn">500</Button>
                                <Button variant="contained" className="eventSingleBtn">1000</Button>
                                <Button variant="contained" className="eventSingleBtn">5000</Button>
                                <Button variant="contained" className="eventSingleBtn">10000</Button>
                                <Button variant="contained" className="eventSingleBtn">25000</Button>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Grid container className="space-col">
                                    <Grid item xs={6} sm={6}>
                                        <Button variant="contained" className="eventSingleCancelBtn">CANCEL</Button>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Button variant="contained" className="eventSingleSuccessBtn">PLACE BET</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form> */}
        </Grid>
      </Grid>
      {/* <button type="button" onClick={handleOpen}>
                react-transition-group
            </button> */}
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="xs_modal place-bat-model">
            <form className={classes.root} noValidate autoComplete="off">
              <div className="top_head">
                <Button>BET SLIP</Button>
              </div>
              <div className="modal_head">
                <Grid container>
                  <Grid item xs={3} sm={3}>
                    <p className="left-txt">Back </p>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <p>Odds </p>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <p>Stake </p>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <p className="right-txt">Profit </p>
                  </Grid>
                </Grid>
              </div>
              <div className="modal_body">
                <Grid className="flex-row">
                  <Grid item xs={6} sm={6}>
                    <strong className="white-txt bet-runner_name">
                      {BetPlaceData.runner_name}
                    </strong>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      label="Size"
                      id="outlined-size-small"
                      variant="outlined"
                      size="small"
                      value={BetPlaceData.price}
                      onChange={(e) => setBetPlaceData({ "price": e.target.value })}
                      name="size"
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      label="Stake"
                      id="outlined-size-small"
                      variant="outlined"
                      size="small"
                      value={StakeValue}
                      name="stake"
                      onChange={handleBetInputChange}
                      defaultValue={0}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <strong className="white-txt right-txt">
                      {ProfitValue}
                    </strong>
                  </Grid>
                </Grid>
              </div>
              <div className="modal_footer">
                <Grid container>
                  {/* <Grid item xs={12} sm={12} className="modal_number">
                    <Button variant="contained" className="eventSingleBtn">
                      1
                    </Button>
                    <Button variant="contained" className="eventSingleBtn">
                      2
                    </Button>
                    <Button variant="contained" className="eventSingleBtn">
                      3
                    </Button>
                    <Button variant="contained" className="eventSingleBtn">
                      4
                    </Button>
                    <Button variant="contained" className="eventSingleBtn">
                      5
                    </Button>
                    <Button variant="contained" className="eventSingleBtn">
                      6
                    </Button>
                    <Button variant="contained" className="eventSingleBtn">
                      7
                    </Button>
                    <Button variant="contained" className="eventSingleBtn">
                      8
                    </Button>
                    <Button variant="contained" className="eventSingleBtn">
                      9
                    </Button>
                    <Button variant="contained" className="eventSingleBtn">
                      0
                    </Button>
                    <Button variant="contained" className="eventSingleBtn">
                      00
                    </Button>
                    <Button variant="contained" className="eventSingleBtn">
                      {"<"}
                    </Button>
                  </Grid> */}

      {/* <Grid item xs={12} sm={12} className="modal_btn">
                    {chipList.map((chip, index) => {

                      if (index < 10)
                        return <>
                          <Button
                            onClick={() => placeStakeValue(chip.chip_value)}
                            variant="contained"
                            className="eventSingleBtn"
                          >
                            {chip.chip_label}
                          </Button>
                        </>
                    })
                    }

                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6}>
                        <Button
                          onClick={() => handleClose()}
                          variant="contained"
                          className="eventSingleCancelBtn"
                        >
                          CANCEL
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Button
                          variant="contained"
                          className="eventSingleSuccessBtn"
                          onClick={() => betPlace()}
                          disabled={placing}
                          endIcon={placing ? <CircularProgress size={20} style={{ color: "red" }} /> : ""}
                        >
                          {placing ? "PLACING...." : "PLACE BET"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          newTemp
                      }} />
                  </Grid>
                </Grid>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>  */}

      {/* fancy position  */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={positionOpen}
        onClose={positionClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{ height: "100%" }}
      >

        <Fade in={positionOpen}>

          <div className="user_modal" >
            <Grid container spacing={2}>
              <Grid item lg={11} xs={10}>
                <h2 id="transition-modal-title">Fancy Positions</h2>
              </Grid>
              <Grid item lg={1} xs={1}>
                <IconButton aria-label="delete" onClick={positionClose} color="secondary" className={classes.margin}>
                  <CancelIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className="tblhead darktblhead">

                  <TableCell align="center" >Score </TableCell>
                  <TableCell align="center" >Ammount</TableCell>

                </TableRow>

              </TableHead>


              <TableBody className="tbl_body ">

                {postionFancyList.map((item, index) => {
                  if (item < 0)
                    return <>
                      <TableRow className="">
                        <TableCell align="center" >{index}</TableCell>
                        <TableCell align="center" className="fancy-position" data-value={item}>{item}</TableCell>
                      </TableRow>
                    </>

                  if (item >= 0 && item != null)
                    return <>
                      <TableRow className="">
                        <TableCell align="center" >{index}</TableCell>
                        <TableCell align="center" className="fancy-position" data-value={item}>{item}</TableCell>
                      </TableRow>
                    </>
                })
                }

              </TableBody>
            </Table>
          </div>
        </Fade>
      </Modal>
      {/* end fancy position */}


      <Footer />
    </div >
  );
};

export default EventDetail;

