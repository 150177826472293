import React from 'react';
import { makeStyles, Grid, List, ListItem, ListItemText, FormControl, NativeSelect, TextField, Button } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from './Header'
import Footer from './Footer'
import Appconfig from '../config/config';
import moment from 'moment';
import axios from 'axios';
import './admin.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function AllUserOnline
    () {
    const [onlineUserList, SetOnlineUserList] = React.useState([]);
    React.useEffect(() => {  window.scroll(0,0);
        getOnlineUser();
    }, []);
    const [pageCount, setPageCount] = React.useState(10);
    const classes = useStyles();


    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }
    const columns = [

        { field: 'id', headerName: 'S.No.', width: 91 },
        {
            field: 'name',
            headerName: 'User ID',
            editable: true,
            flex: 1,
        },
        {
            field: 'createdAt',
            headerName: 'Login Time',
            editable: true,
            width: 114,
            renderCell: rowData => {
                return (
                    moment(rowData.row.createdAt).format("DD-MMM-YYYY")

                );
            }

        },

        {
            field: 'ip_address',
            headerName: 'IP Address',
            editable: true,
            flex: 1,
        },

    ];


    const [state, setState] = React.useState({
        age: '',
        name: 'hai',
    });

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    function getOnlineUser() {

        var data = JSON.stringify({
            "user_id": "6113941520d37223238c4016",
            "user_type": "User"
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/usersOnline`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                //console.log(JSON.stringify(response.data));
                SetOnlineUserList(response.data.resultData);
                let tmpAccountList = response.data.resultData;
                const newChipList = tmpAccountList.map((onlineUserList, index) => {
                    tmpAccountList[index]["id"] = index + 1;
                });
                SetOnlineUserList(tmpAccountList);
                //console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    return (
        <div className={classes.root}>
            <Header />
            <Grid className="bodypart custom-container grid-row">
                <Grid item lg={2} xs={2} className="xs_none tl-hide">
                    <List component="nav" className="side_list" aria-label="secondary mailbox folders">
                        <ListItemLink href="#simple-list" className="active">
                            <ListItemText primary="Sports" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="In-Play" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="Cricket" />
                        </ListItemLink>
                    </List>
                </Grid>

                <Grid item lg={10} xs={12} className="report-section">

                    <div className="datatable_heading">
                        Online Users &nbsp;

                    </div>
                    <Grid container spacing="0">
                        <Grid item xs={6} sm={2}>
                            <button className="blue_button">
                                Super Admin
                            </button>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <button className="blue_button">
                                Sub Admin
                            </button>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <button className="blue_button">
                                Super Super
                            </button>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <button className="blue_button">
                                Master
                            </button>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <button className="blue_button">
                                User
                            </button>
                        </Grid>

                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item sm={6} xs={6} md>
                            <div className="show_data">
                                <span className="showentries">Show</span>
                                <span>
                                    <FormControl className={classes.formControl}>
                                        <NativeSelect
                                            value={pageCount}
                                            onChange={(e) => setPageCount(e.target.value)}
                                            name="age"
                                            className={classes.selectEmpty}
                                            inputProps={{ 'aria-label': 'age' }}
                                        >
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={500}>500</option>
                                        </NativeSelect>
                                    </FormControl>
                                </span>
                                <span className="showentries">entries</span>
                            </div>
                        </Grid>
                        <Grid item sm={6} xs={6} md>
                            <div className="search_data" style={{ display: 'flex', placeItems: 'center' }}>
                                <span className="showentries">Search:</span>
                                <span>
                                    <form className={classes.root} noValidate autoComplete="off">
                                        <TextField variant="outlined" size="small" id="standard-basic" />
                                    </form>
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ display: 'flex', height: '100vh' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                //  components={{
                                //     Toolbar: GridToolbar,
                                // }}
                                // filterModel={{
                                //     items: [{ columnField: 'commodity', operatorValue: 'contains', value: 'rice' }],
                                // }}
                                rows={onlineUserList}
                                columns={columns}
                                pageSize={5}
                                disableSelectionOnClick
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Footer />
        </div >
    );
}