import React from "react";
import "./admin.css";
import "./adminResponsive.css";
import SideDrawer from "./SideDrawer";
import { ToastContainer, toast } from 'react-toastify';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import clsx from 'clsx';
import HomeIcon from '@material-ui/icons/Home';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LockIcon from '@material-ui/icons/Lock';
import HistoryIcon from '@material-ui/icons/History';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import DescriptionIcon from '@material-ui/icons/Description';
import TimelineIcon from '@material-ui/icons/Timeline';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import {
  makeStyles,
  AppBar,
  Divider,
  ListItemSecondaryAction,
  Toolbar,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  IconButton,
  ButtonGroup,
  Button,
  List,
  MenuItem,
  Menu,
  ListItem,
  ListItemText,
  Modal,
  Backdrop,
  Fade,
  TextField,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import Logo from "../assets/line_logo_home_page.png";
import USER from "../assets/user-icon.png";
import WALLET from "../assets/wallet-icons.png";
import BELL from "../assets/bell-icon.png";
import FINANCIAL from "../assets/financial.png";
import RISK from "../assets/risk-icon.png";
import MicroPhone from "../assets/white-microphone.jpg";
import BlackMicroPhone from "../assets/black-microphone.png";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useHistory } from "react-router-dom";
import Appconfig from "../config/config";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
import { TramRounded } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 0.02,
  },
  marque: {
    flexGrow: 1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  setBody: {
    marginBottom: JSON.parse(window.sessionStorage.getItem("userData")).user_type != 'User' ? "10% !important" : "10% !important"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Header = (props) => {

  const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
  // const balance = window.sessionStorage.getItem("Balance");
  // const exposure = window.sessionStorage.getItem("Exposure") ? exposure : '0.00';
  const history = useHistory();
  const classes = useStyles();
  const auth = true;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenu, setOpenMenu] = React.useState(null);
  const [MainBalance, setMainBalance] = React.useState(window.sessionStorage.getItem("mainBalance") ? parseFloat(window.sessionStorage.getItem("mainBalance")) : 0.00);
  const [exposure, setExposure] = React.useState(window.sessionStorage.getItem("exposure") ? parseFloat(window.sessionStorage.getItem("exposure")) : 0.00);
  const [winnings, setWinnings] = React.useState(window.sessionStorage.getItem("winnings") ? parseFloat(window.sessionStorage.getItem("winnings")) : 0.00);
  const [creditLimits, setCreditLimit] = React.useState(window.sessionStorage.getItem("creditLimits") ? parseFloat(window.sessionStorage.getItem("creditLimits")) : 0.00);


  const open = Boolean(anchorEl);
  const [opens, setOpens] = React.useState(false);

  const [openCListMenu, setopenCListMenu] = React.useState(null);
  const [openSettings, setopenSettings] = React.useState(null);
  const [openBettingList, setopenBettingList] = React.useState(null);
  const [News, setNews] = React.useState([]);

  const [dialougeOpen, setdialougeOpen] = React.useState(false);
  const [fancyMessage, setfancyMessage] = React.useState(false);


  const [passwordData, setPasswordData] = React.useState({

  });

  const [oddsData, setOddsData] = React.useState({
    is_fancy_off: "No",
    is_odds_off: "No"
  });

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handlePasswordChange = (event) => {
    setPasswordData({
      ...passwordData,
      [event.target.name]: event.target.value

    })
    // console.log(passwordData);
  }

  function handleDialougeOpen(val) {
    setopenSettings(null);
    setdialougeOpen(true);
    setfancyMessage(val);
  }

  const handleDialogeClose = () => {
    setdialougeOpen(false);
  };


  const getOddsOnOff = () => {

    var data = JSON.stringify({
      site_code: userInfo.site_code,
      master_id: userInfo._id,
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}oddsOnOff/getOddsOnOff`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        // console.log("response.data", response.data);
        if (response.data.result) {
          oddsData["is_odds_off"] = response.data.resultData[0].is_odds_off;
          oddsData["is_fancy_off"] = response.data.resultData[0].is_fancy_off

        }
        else {

          oddsData["is_odds_off"] = "No"
          oddsData["is_fancy_off"] = "No"

        }
        // console.log("oddsData", oddsData)
      })
      .catch(function (error) {
        console.log(error);
      });

  };

  const handleDialougeSubmit = (e) => {
    e.preventDefault();
    var data = JSON.stringify({
      site_code: userInfo.site_code,
      master_id: userInfo._id,
      is_odds_off: oddsData.is_odds_off == "Yes" ? "No" : "Yes",
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}oddsOnOff/updateOddsOnOff`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getOddsOnOff();
        handleDialogeClose();
        if (response.data.result) {
          notify(response.data.resultMessage, "success");
        }
        else {
          notify(response.data.resultMessage, "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  };

  const handleDialougeSubmitFancy = (e) => {
    e.preventDefault();
    var data = JSON.stringify({
      site_code: userInfo.site_code,
      master_id: userInfo._id,
      is_fancy_off: oddsData.is_fancy_off == "Yes" ? "No" : "Yes"
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}oddsOnOff/updateOddsOnOff`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        getOddsOnOff();
        handleDialogeClose();
        if (response.data.result) {
          notify(response.data.resultMessage, "success");
        }
        else {
          notify(response.data.resultMessage, "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });

  };

  React.useEffect(() => {
    window.scroll(0, 0);
    // getNews();
    getBalance();
    getOddsOnOff();
    if (userInfo.user_type == "User") {
      window.setInterval(function () {
        checkNewLogin();
      }, 2000);
    }
  }, [props.isBalanceUpdated]);

  function getNews() {
    var data = JSON.stringify({
      site_id: "60fc5aca67e1e3799230a6f7",
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}news/getNews`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        let messageArr = [];
        response.data.resultData.map((news, index) => {
          messageArr.push(news.message);
        })

        setNews(messageArr);
        // console.log(News);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getBalance() {
    var data = JSON.stringify({
      user_id: userInfo._id,
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}ledger/getUserBalance`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.result == 0) {
        } else {
          // console.log(response.data.resultData);
          setExposure(response.data.resultData.exposure);
          setMainBalance(response.data.resultData.balance);
          setCreditLimit(response.data.resultData.creditLimit);
          setWinnings(response.data.resultData.totalWinnings);

          window.sessionStorage.setItem("exposure", response.data.resultData.exposure);
          window.sessionStorage.setItem("mainBalance", response.data.resultData.balance);
          window.sessionStorage.setItem("winnings", response.data.resultData.totalWinnings);
          window.sessionStorage.setItem("creditLimits", response.data.resultData.creditLimit);

        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleBettingList = (event) => {
    setopenBettingList(event.currentTarget);
  };

  const handleCloseBettingList = (event) => {
    setopenBettingList(null);
  };

  const handleSettings = (event) => {
    setopenSettings(event.currentTarget);
  };

  const handleCloseSetting = () => {
    setopenSettings(null);
  };
  const handleCloseCList = () => {
    setopenCListMenu(null);
  };

  const handleCListClick = (event) => {
    setopenCListMenu(event.currentTarget);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const modalOpen = () => {
    setOpens(true);
  };

  const modalClose = () => {
    setOpens(false);
  };
  const logout = () => {
    sessionStorage.clear();
    sessionStorage.setItem("loggedIn", false);
    history.push("/");
  };

  const handlePasswordSubmit = (e) => {

    e.preventDefault();
    var data = JSON.stringify({
      "old_password": passwordData.old_password,
      "new_password": passwordData.new_password,
      "user_id": userInfo._id
    });

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}users/changePassword`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        if (response.data.result) {
          notify(response.data.message, 'success');
        }
        else {
          notify(response.data.message, 'error');
        }
        // //console.log(JSON.stringify(response.data));
        modalClose();
        setPasswordData({
          old_password: '',
          new_password: '',
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const checkNewLogin = () => {

    var data = JSON.stringify({
      "login_token": userInfo.login_token,
      "user_id": userInfo._id
    });

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}userToken/getToken`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        if (response.data.result) {

        }
        else {
          sessionStorage.clear();
          sessionStorage.setItem("loggedIn", false);
          window.location.href = "/";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function notify(message, type) {
    toast.configure();
    if (type === 'error') {
      toast.error(message, {
        toastId: "error"
      });
    }
    else {
      toast.success(message, {
        toastId: "success"
      });
    }
  }

  const preventDefault = (event) => event.preventDefault();
  return (
    <div>

      <div className="header-section setBody">
        <div className="top_nav">
          <div className="nav_menu">
            <nav role="navigation">
              <AppBar position="static" className="app_bar">
                <Toolbar>
                  <IconButton
                    edge="start"
                    className="hamburgerMenu"
                    color="inherit"
                    aria-label="menu"
                  >
                    <SideDrawer />
                  </IconButton>
                  <Typography variant="h6" className="logo-outer">
                    <Link onClick={() => history.push('/settlement')}>
                      <img alt="sgBetImage" src={Logo} className="logo" />
                    </Link>
                  </Typography>
                  <Typography variant="h6" className="desktop-marquee" >
                    <div className="marquee">
                      <ButtonGroup
                        color="primary"
                        className="bg_white marquee"
                        aria-label="outlined primary button group"
                      >
                        <Button className="desktop-news">
                          <img alt="sgBetImage" src={BlackMicroPhone} />
                        </Button>
                        <Button>
                          <marquee>
                            {
                              News.map((row, index) => {
                                return <span className="newss">{row}</span>
                              })}
                          </marquee>
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Typography>
                  {/* <Typography variant="h6" className="wallet_icon">
                                        <img alt="sgBetImage" src={WALLET} />
                                    </Typography> */}
                  {/* <Typography variant="h6" className="wallet">
                    <span>
                      <img alt="sgBetImage" src={FINANCIAL} />{" "}
                      <p>Main: {(MainBalance - Math.abs(exposure)).toFixed(2)}</p>
                    </span>
                    <span>
                      <img alt="sgBetImage" width='11%' src={RISK} />{" "}
                      <p onClick={() => history.push("/livebethistory/0")}>Exposure: {exposure.toFixed(2)}</p>
                    </span>
                  </Typography> */}
                  {auth && (
                    <div>
                      <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        // onClick={handleMenu}
                        color="inherit"
                        className="user_icon"
                        onClick={userInfo.user_type == "User" ? toggleDrawer("right", true) : handleMenu}
                      >
                        {/* <PersonOutlineIcon className="dis_block" /> */}
                        <img
                          alt="sgBetImage"
                          src={USER}
                          className="user_icon_img"
                        />
                        <p>
                          {userInfo.name} <KeyboardArrowDownIcon />
                        </p>
                      </IconButton>


                      {userInfo.user_type == "User" ? (
                        <Drawer anchor={"right"} className="userdrawer" open={state["right"]} onClose={toggleDrawer("right", false)}>
                          <List className="drawer-listing">
                            <ListItem>
                              {/* <AccountBalanceIcon className="drawer-icons" /> */}
                              <ListItemText  >
                                <p className="login-name" >{userInfo.user_name}</p>

                              </ListItemText>
                            </ListItem>
                            <ListItem>
                              <AccountBalanceIcon className="drawer-icons" />
                              <ListItemText primary="Balance Information" />


                            </ListItem>
                            <ListItem>
                              {/* <ListItemText >
                                <span>

                                    <span>Main: </span><span> {MainBalance - exposure}</span>
                                  </span>
                                <div className="group">
                                  <div>Main Balance:</div>
                                  <span>
                                    <strong>{(MainBalance - Math.abs(exposure)).toFixed(2)}</strong>
                                  </span>
                                </div>
                                <div className="group">
                                  <div>Credit Limit:</div>
                                  <span>
                                    {creditLimits.toFixed(2)}
                                  </span>
                                </div>
                                <div className="group">
                                  <div>Winnings:</div>
                                  <span>
                                    {winnings.toFixed(2)}
                                  </span>
                                </div>
                                <div className="group">
                                  <div>Net Exposure:</div>
                                  <span>
                                    {exposure.toFixed(2)}
                                  </span>
                                </div>
                              </ListItemText> */}
                            </ListItem>

                            <Divider />
                            {/* <ListItem className="user-drawer-li">
                              <HomeIcon className="drawer-icons" />  <ListItemText onClick={() => history.push('/settlement')} primary="Home" />

                            </ListItem>
                            <Divider /> */}

                            <ListItem className="user-drawer-li">
                              <PlayArrowIcon className="drawer-icons" />  <ListItemText onClick={() => history.push('/inplay')} primary="In-Play" />
                            </ListItem>
                            <MenuItem>
                              <Link onClick={() => history.push('/inplay')} >Favorite</Link>
                            </MenuItem>

                            <Divider />
                            <ListItem className="user-drawer-li">
                              <ShowChartIcon className="drawer-icons" />  <ListItemText onClick={() => history.push('/running-market-analysis')} primary="Running Market analysis" />
                            </ListItem>
                            <Divider />
                            <ListItem className="user-drawer-li">
                              <DescriptionIcon className="drawer-icons" />  <ListItemText onClick={() => history.push('/accountinfo/0')} primary="Account Statement" />
                            </ListItem>
                            <Divider />
                            <ListItem className="user-drawer-li">
                              <TimelineIcon className="drawer-icons" /> <ListItemText onClick={() => history.push('/profitloss/0')} primary="Profit & Loss" />
                            </ListItem>

                            <Divider />
                            <ListItem className="user-drawer-li">
                              <HistoryIcon className="drawer-icons" />  <ListItemText onClick={() => history.push('/bethistory/0/0/0')} primary="Bet History analysis" />
                            </ListItem>
                            <Divider />
                            <ListItem className="user-drawer-li">
                              <HistoryIcon className="drawer-icons" /><ListItemText onClick={() => history.push('/livebethistory/0')} primary="Live Bet History" />
                            </ListItem>

                            <Divider />
                            <ListItem className="user-drawer-li" >
                              <LockIcon className="drawer-icons" /> <ListItemText onClick={modalOpen} primary="Change Password" />
                            </ListItem>
                            <Divider />
                            <ListItem onClick={handleClose} className="user-drawer-li">
                              <ExitToAppIcon className="drawer-icons" />  <ListItemText onClick={logout} primary="Log Out" />
                            </ListItem>
                          </List>
                        </Drawer>
                      ) : <Menu
                        id="menu-appbar"

                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={open}
                        onClose={handleClose}
                      >
                        <ListItem >
                          <ListItemText onClick={modalOpen} primary="Change Password" />
                        </ListItem>
                        <ListItem onClick={handleClose}>
                          <ListItemText onClick={logout} primary="Log Out" />
                        </ListItem>
                      </Menu>
                      }

                    </div>
                  )}
                </Toolbar>
              </AppBar>
            </nav>
          </div>
        </div>
        {userInfo.user_type != "User" ?
          <>
            <div className="sidenav">
              <nav className="mainmenu">
                <List component="nav" aria-label="secondary mailbox folders">
                  <ListItem button>
                    <ListItemText primary="Dashboard" />
                  </ListItem>
                  
                
              <ListItem button>
                <Link onClick={() => history.push('/settlement')} >Settlement</Link>
              </ListItem>
              <ListItem button>
                <Link onClick={() => history.push('/settled')} >Settled</Link>
              </ListItem>

            </List>
          </nav>
            </div>
    </> : ""
}
        <div className="mobile_nav mheaderm">
          {/* <Typography className={classes.root}>
            <Link href="#" onClick={preventDefault}>
              <img alt="sgBetImage" src={FINANCIAL} /> Main :{" "}
              {(MainBalance - Math.abs(exposure)).toFixed(2)}
            </Link>
            <Link onClick={() => history.push("/livebethistory/0")}>
              <img alt="sgBetImage" style={{ width: "11%" }} src={RISK} /> Exposure : {exposure.toFixed(2)}
            </Link>
          </Typography> */}
        </div>
        {/* <div className="mobile_news">
          <div className="mobile_new_block">
            <div className="first">
              {" "}
              <img src={MicroPhone} /> NEWS{" "}
              <ArrowForwardIosOutlinedIcon
                style={{ color: "#fff" }}
                className="news-right-arrow"
              />
            </div>
            <div className="second">
              <marquee>  {
                News.map((row, index) => {
                  return <span className="newss">{row}</span>
                })}
              </marquee>
            </div>
          </div>
        </div> */}
      </div >
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={opens}
        onClose={modalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={opens}>
          <div className="user_modal">
            <h2 id="transition-modal-title">Change Password</h2>
            <ValidatorForm

              onSubmit={handlePasswordSubmit}
              autoComplete="off"
            >
              <Grid container >


                <Grid item lg={6} xs={12}>
                  <TextValidator size="small"
                    value={passwordData.old_password}
                    validators={['required']}
                    errorMessages={['this field is required']}
                    onChange={handlePasswordChange}
                    id="outlined-basic2" label="Old Password" name="old_password" variant="outlined" />

                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextValidator size="small"
                    value={passwordData.new_password}
                    validators={['required']}
                    errorMessages={['this field is required']}
                    onChange={handlePasswordChange}
                    id="outlined-basic2" label="New Password" name="new_password" variant="outlined" />
                </Grid>
                <Grid item lg={12} xs={12}>

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={modalClose}
                  >
                    Cancel
                  </Button>
                  &nbsp;
                  <Button variant="contained" type="submit" color="primary">
                    Submit
                  </Button>

                </Grid>
              </Grid>
            </ValidatorForm>
          </div>
        </Fade>
      </Modal>

      <Dialog
        open={dialougeOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogeClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">ON/OFF Setting</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {fancyMessage
              ? ` Are you sure you want to ${oddsData["is_fancy_off"] == "Yes" ? 'START' : 'STOP'} showing the FANCY`
              : `Are you sure you want to ${oddsData["is_odds_off"] == "Yes" ? 'START' : 'STOP'} showing the ODDS`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleDialogeClose}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={fancyMessage ? handleDialougeSubmitFancy : handleDialougeSubmit}
            color="primary"
            autoFocus
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};

export default Header;