import React from 'react';
import { makeStyles, Grid, List, Link, ListItem, ListItemText, FormGroup, MenuItem, NativeSelect, InputLabel, FormControl, Select, FormControlLabel, Checkbox, TextField, Button } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from './Header'
import Footer from './Footer'
import Appconfig from '../config/config';
import axios from 'axios';
import './admin.css';
import { useHistory } from 'react-router';
import { useParams } from "react-router-dom";

import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function EventSingle() {
  const params = useParams();
  const userId = params.user_id;
  const history = useHistory();
  const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
  const classes = useStyles();
  const [accountList, SetAccountList] = React.useState([]);
  const [pageCount, setPageCount] = React.useState(3);
  const [formData, setFormData] = React.useState({
    "user_id": userId == 0 ? userInfo._id : userId,
    "game_type": "All",
    "from_date": moment().add(-7, 'days').format('YYYY-MM-DD'),
    "to_date": moment().format('YYYY-MM-DD'),
  })
  React.useEffect(() => {
    window.scroll(0, 0);
    getAccount(0);
  }, [params.user_id]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    console.log(formData);
  };
  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  const columns = [

    { field: 'id', width: 91, headerName: 'S.No.' },
    {
      field: 'createdAt',
      headerName: 'Date',
      editable: false,
      width: 180,
      renderCell: rowData => {
        return (moment(rowData.row.createdAt).format("YYYY-MM-DD h:mm:ss"))
      }

    },
    {
      field: 'remarks',
      headerName: 'Description',
      editable: false,
      width: 300,
      renderCell: rowData => {
        return (
          <>
            {rowData.row.type == "Settlement" ? rowData.row.type+" / " : ""}
            {rowData.row.betting_type ? <Link onClick={() => history.push(`/bethistory/${rowData.row._id.x}/${rowData.row.selection_id}/${params.user_id}`)}>{`${rowData.row.remarks}` + (rowData.row.betting_type ? `(${rowData.row.betting_type} / ${rowData.row.place_name})` : "")}</Link> : rowData.row.remarks}
          </>
        );
      }
    },
    {
      field: 'credit',
      headerName: 'Credit',
      editable: false,
      width: 94,
      renderCell: rowData => {
        return (rowData.row.transaction_type == "Credit" || rowData.row.transaction_type != "Debit" && rowData.row.amount > 0
          ? rowData.row.amount : "0")
      }
    },
    {
      field: 'debit',
      headerName: 'Debit',
      editable: false,
      width: 94,
      renderCell: rowData => {
        return (
          rowData.row.transaction_type == "Debit" || rowData.row.transaction_type != "Credit" && rowData.row.amount < 0 ?
            rowData.row.amount : "0")
      }
    },
    {
      field: 'balance',
      headerName: 'Balance',
      editable: false,
      width: 107,
    },
  ];

  const rows = [
    // { id: 1, createdAt: '2021-07-05 09:11:09', remarks: 'Free Chip Deposit By MasterGreen	', credit: '1000.00', debit: '0.00', balance: '1000' },
    //   { id: 2, date: '2022-07-05 09:11:09', description: 'Free Chip Deposit By MasterGreen	', credit: '1000.00', debit: '0.00', balance: '1000' },
    //   { id: 3, date: '2021-07-05 09:11:09', description: 'Free Chip Deposit By MasterGreen	', credit: '1000.00', debit: '0.00', balance: '1000' },
    //   { id: 4, date: '2021-07-05 09:11:09', description: 'Free Chip Deposit By MasterGreen	', credit: '1000.00', debit: '0.00', balance: '1000' },
    //   { id: 5, date: '2021-07-05 09:11:09', description: 'Free Chip Deposit By MasterGreen	', credit: '1000.00', debit: '0.00', balance: '1000' },
    //   { id: 6, date: '2021-07-05 09:11:09', description: 'Free Chip Deposit By MasterGreen	', credit: '1000.00', debit: '0.00', balance: '1000' },
    //   { id: 7, date: '2021-07-05 09:11:09', description: 'Free Chip Deposit By MasterGreen	', credit: '1000.00', debit: '0.00', balance: '1000' },
  ];

  function getAccount(type) {
    setFormData({
      ...formData,
      ["type"]: type,
    });
    var data = JSON.stringify(formData);

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}reports/accountStatement`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        SetAccountList(response.data.resultData);
        let tmpAccountList = response.data.resultData;
        const newChipList = tmpAccountList.map((accountList, index) => {
          tmpAccountList[index]["id"] = index + 1;
        });
        SetAccountList(tmpAccountList);
        //console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div className={classes.root}>
      <Header />
      <Grid className="bodypart custom-container grid-row">
        <Grid item lg={2} xs={2} className="xs_none tl-hide">
          <List component="nav" className="side_list" aria-label="secondary mailbox folders">
            <ListItemLink href="#simple-list" className="active">
              <ListItemText primary="Sports" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="In-Play" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="Cricket" />
            </ListItemLink>
          </List>
        </Grid>

        <Grid item lg={10} xs={12} className="report-section">

          <div className="datatable_heading">
            Account Statement
          </div>

          {/* <div className="datatable_check">

                <FormGroup row>
                  <Grid container >
                    <Grid item sm={2} xs={3} >
                      <FormControlLabel
                        control={<Checkbox checked={formData.type == "0" ? true : false}
                          onClick={() => { getAccount(0) }} name="checkedA" color="primary" />}
                        label="All"
                      />
                    </Grid>
                    <Grid item sm={2} xs={4} >
                      <FormControlLabel
                        control={<Checkbox checked={formData.type == "1" ? true : false} onClick={() => { getAccount(1) }} name="checkedA" color="primary" />}
                        label="Free Chips"
                      />
                    </Grid>
                    <Grid item sm={2} xs={4} >
                      <FormControlLabel
                        control={<Checkbox checked={formData.type == "2" ? true : false} onClick={() => { getAccount(2) }} name="checkedA" color="primary" />}
                        label="Settlement"
                      />
                    </Grid>
                    <Grid item sm={2} xs={4} md>
                      <FormControlLabel
                        control={<Checkbox checked={formData.type == "3" ? true : false} onClick={() => { getAccount(3) }} name="checkedA" color="primary" />}
                        label="Profit Loss"
                      />
                    </Grid>
                    <Grid item sm={2} xs={4} md>
                      <FormControlLabel
                        control={<Checkbox checked={formData.type == "4" ? true : false} onClick={() => { getAccount(4) }} name="checkedA" color="primary" />}
                        label="Statement"
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
              </div> */}

          <Grid container spacing={0} >
            <Grid item sm={3} xs={6} >
              <TextField label="From Date" variant="outlined" className="dates" size="small" type="date" value={formData.from_date} onChange={handleChange} name="from_date" />
            </Grid>
            <Grid item sm={3} xs={6} >
              <TextField label="To Date" variant="outlined" className="dates" size="small" type="date" value={formData.to_date} onChange={handleChange} name="to_date" />
            </Grid>
            <Grid item sm={3} xs={6} >
              <FormControl variant="outlined" size="small" style={{ width: '97%' }} >
                <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={formData.game_type}
                  onChange={handleChange}
                  name="game_type"
                  className="gametype"
                  inputProps={{ 'aria-label': 'age' }}
                  label="Type"
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Free Chip">Deposite/Withdraw</MenuItem>
                  <MenuItem value="Game Report">Game Report</MenuItem>

                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={3} xs={6} >
              <TextField label="Search" variant="outlined" size="small" type="text" />
            </Grid>
            <Grid item sm={4} xs={12} >
              <Button variant="contained" color="secondary">
                Clear
              </Button>
              &nbsp;
              <Button variant="contained" color="primary" onClick={() => getAccount(formData.type)}>
                Filter
              </Button>

            </Grid>
          </Grid>

          {/* <Grid container spacing={3}>
              <Grid item sm={6} xs={5} md>
                <div className="show_data">
                  <span className="showentries">Show</span>
                  <span>
                    <FormControl className={classes.formControl}>
                      <NativeSelect
                        value={pageCount}
                        onChange={(e) => setPageCount(e.target.value)}
                        name="age"
                        className={classes.selectEmpty}
                        inputProps={{ 'aria-label': 'age' }}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={500}>500</option>
                      </NativeSelect>
                    </FormControl>
                  </span>
                  <span className="showentries">entries</span>
                </div>
              </Grid>
              <Grid item sm={6} xs={6} md>
                <div className="search_data" style={{ display: 'flex', placeItems: 'center' }}>
                  <span className="showentries">Search:</span>
                  <span>
                    <form className={classes.root} noValidate autoComplete="off">
                      <TextField variant="outlined" size="small" id="standard-basic" />
                    </form>
                  </span>
                </div>
              </Grid>
            </Grid> */}
          <div style={{ display: 'flex', height: '100vh' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid

                rows={accountList}
                // rows={accountList}
                columns={columns}
                pageSize={25}

              />
            </div>
          </div>
        </Grid>
      </Grid>

      <Footer />
    </div >
  );
}