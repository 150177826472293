import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from './Header';
import Footer from './Footer';
import { Table, FormControl, NativeSelect, TableBody, TableCell, FormControlLabel, Card, TableContainer, TableHead, Paper, Dialog, TableRow, DialogActions, DialogTitle, DialogContentText, DialogContent, Grid, Button, List, ListItemText, Modal, Typography, ListItem, Fade, Backdrop, TextField, Checkbox } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import Appconfig from '../config/config';
import axios from 'axios';
import { useParams } from "react-router-dom";
import CancelIcon from '@material-ui/icons/Cancel';
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
const useStyles = makeStyles({

    root: {
        // width: '100%',
        margin: 10,

    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10
    },

});
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export default function Chips() {
    const params = useParams();
    const userId = params.user_id;
    const user_type = params.user_type;
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [chipList, setchipList] = React.useState([]);
    const [opens, setOpens] = React.useState(false);
    const [editopens, setEditOpens] = React.useState(false);
    const [dialougeOpen, setDialougeOpen] = React.useState(false);
    const [deletechipid, setdeletechipid] = React.useState('');
    const [formData, setFormData] = useState({
        chip_name: '',
        chip_value: '',
        update_for_all_user: '',
    });

    const [formEditData, setEditFormData] = useState({
        chip_name: '',
        chip_value: '',
        chip_id: '',
        update_for_all_user: '',
    });
    const handleInputChange = (event) => {

        if (event.target.name === 'update_for_all_user') {
            setFormData({
                ...formData,
                [event.target.name]: event.target.checked
            })
        }
        else {
            setFormData({
                ...formData,
                [event.target.name]: event.target.value
            })
        }

    }


    const handleUpdateInputChange = (event) => {

        if (event.target.name === 'update_for_all_user') {
            setEditFormData({
                ...formEditData,
                [event.target.name]: event.target.checked
            })
        }
        else {
            setEditFormData({
                ...formEditData,
                [event.target.name]: event.target.value
            })

        }


    }
    const modalOpen = () => {
        setOpens(true);
    };

    const modalClose = () => {
        setOpens(false);
    };


    const editmodalClose = () => {
        setEditOpens(false);
    };


    const classes = useStyles();

    useEffect(() => { window.scroll(0,0)
        getChips();
    }, [userId]);


    function getChips() {
        let id = userInfo._id;
        if (userId != 0) {
            id = userId
        }
        var data = JSON.stringify({
            "user_id": id
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}chips/getChips`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setchipList(response.data);
                let tmpChipList = response.data;
                const newChipList = tmpChipList.map((chipList, index) => {
                    tmpChipList[index]["id"] = index + 1;
                });
                console.log("data new", tmpChipList);

                setchipList(tmpChipList);
                console.log(chipList);

            })
            .catch(function (error) {
                console.log(error);
            });

    }


    function addChips() {
        let id = userInfo._id;
        if (userId != 0) {
            id = userId
        }
        var data = JSON.stringify({
            "user_id": id
        });
        var data = JSON.stringify({
            "user_id": id,
            "chip_label": formData.chip_name,
            "chip_value": formData.chip_value,
            "is_active": "Yes",
            "is_delete": "No"
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}chips/addChips`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                //console.log(JSON.stringify(response.data));
                setOpens(false);
                getChips();
                formData.chip_value = ""
                formData.chip_name = ""
                notify('Stake has been added successfully', 'success')
            })
            .catch(function (error) {
                notify('error occured', 'error')
                console.log(error);
            });
    }



    function editChip(id, index) {
        console.log(chipList[index].chip_value);
        formEditData.chip_value = chipList[index].chip_value
        formEditData.chip_name = chipList[index].chip_label
        formEditData.chip_id = id

        setEditOpens(true);

        // addChips(id); 
    }

    function deleteChip(id) {
        setdeletechipid(id);
        handleClickDialogeOpen();
    }

    const confirmDelete = () => {
        var data = JSON.stringify({
            "_id": deletechipid
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}chips/deleteChips`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setdeletechipid('');
                setDialougeOpen(false);
                console.log('Delete', response.data);
                if (response.data.status) {
                    getChips();
                    notify('chips Deleted successfully', 'success')
                }
                else {
                    notify('error occured', 'error')
                }
            })
            .catch(function (error) {

                console.log(error);
            });
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        addChips();
        console.log();
    }



    function updateChips() {


        var data = JSON.stringify({
            "_id": formEditData.chip_id,
            "data": {
                "chip_label": formEditData.chip_name,
                "chip_value": formEditData.chip_value,
                "is_active": "Yes",
                "is_delete": "No"
            }
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}chips/updateChips`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log('UPDATED', response.data);
                if (response.data.ok) {
                    setEditOpens(false);
                    getChips();
                    formEditData.chip_value = ""
                    formEditData.chip_name = ""
                    notify('Stake has been updated successfully', 'success')
                }
                else {
                    notify('error occured', 'error')
                }
            })
            .catch(function (error) {

                console.log(error);
            });
    }

    const handleUpdate = (event) => {
        event.preventDefault();
        updateChips();

    }


    function handleClickDialogeOpen() {
        setDialougeOpen(true);
    };

    const handleDialogeClose = () => {
        setDialougeOpen(false);
    };

    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }
    const columns = [


        {
            field: 'chip_label',
            headerName: 'Stake Name',
            editable: false,
            width: 105
        },
        {
            field: 'chip_value',
            headerName: 'Stake Value',
            editable: false,
            width: 105

        },
        {
            field: 'debit',
            headerName: 'Action',
            editable: false,
            width: 130,

            renderCell: (rowData) => {
                return (
                    <>
                        <IconButton aria-label="edit" onClick={() => editChip(rowData.row._id, rowData.row.id - 1)}>
                            <EditIcon color="primary" fontSize="medium" />
                        </IconButton>

                        <IconButton aria-label="delete" onClick={() => deleteChip(rowData.row._id)}>
                            <DeleteIcon color="secondary" fontSize="medium" />
                        </IconButton>
                    </>
                );
            },
        },
    ];


    function updateForAll() {
        let id = userInfo._id;
        if (userId != 0) {
            id = userId
        }
        var data = JSON.stringify({
            "user_id": id
        });
        var data = JSON.stringify({
            "user_id": id,
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}chips/updateChipsForAll`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                getChips();
                if (response.data.result) {
                    notify(response.data.resultMessage, 'success')
                }
                else {
                    notify(response.data.resultMessage, 'error')
                }
            })
            .catch(function (error) {
                notify('error occured', 'error')
                console.log(error);
            });
    }

    return (
        <>
            <Header />


            <Grid className="bodypart custom-container grid-row">
                <Grid item lg={2} xs={2} className="xs_none tl-hide">
                    <List
                        component="nav"
                        className="side_list"
                        aria-label="secondary mailbox folders"
                    >
                        <ListItemLink href="#simple-list" className="active">
                            <ListItemText primary="Sports" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="In-Play" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="Cricket" />
                        </ListItemLink>
                    </List>
                </Grid>

                <Grid item lg={10} xs={12} className="report-section">

                    <div className="datatable_heading">
                        Stake
                        <div style={{ width: "80%" }}>
                            <button
                                onClick={modalOpen}
                                className="btn-for-add"
                                style={{ float: "right" }}
                            >
                                Add Stake
                            </button>
                            {
                                user_type.toLowerCase() != 'user'? <button
                                    onClick={() => updateForAll()}
                                    className="btn-for-add"
                                    style={{ float: "right" }}
                                >
                                    Update For All
                                </button> : ""
                            }
                        </div>
                    </div>



                    <div style={{ display: 'flex', height: '100vh' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                // components={{
                                //     Toolbar: GridToolbar,
                                // }}
                                // filterModel={{
                                //     items: [{ columnField: 'commodity', operatorValue: 'contains', value: 'rice' }],
                                // }}
                                rows={chipList}
                                columns={columns}
                                // pageSize={}
                                disableSelectionOnClick
                            />
                        </div>
                    </div>
                </Grid>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={opens}
                    onClose={modalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={opens}>
                        <div className="user_modal">
                            <Grid container spacing={2}>
                                <Grid item lg={11} xs={10}>
                                    <h2 id="transition-modal-title">Stake</h2>
                                </Grid>
                                <Grid item lg={1} xs={1}>
                                    <IconButton aria-label="delete" onClick={modalClose} color="secondary" className={classes.margin}>
                                        <CancelIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>

                            <ValidatorForm

                                onSubmit={handleSubmit}
                                autoComplete="off"
                            >
                                <Grid container spacing={2}>
                                    <Grid item lg={6} xs={6}>
                                        <TextValidator
                                            size="small"
                                            inputProps={{
                                                maxLength: 6
                                            }}
                                            value={formData.chip_name}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            onChange={handleInputChange}
                                            id="outlined-basic2" label="Stake Name" name="chip_name" variant="outlined" />
                                    </Grid>
                                    <Grid item lg={6} xs={6}>
                                        <TextValidator
                                            size="small"
                                            type="number"
                                            onInput={(e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7)
                                            }}
                                            inputProps={{
                                                min: 1
                                            }}
                                            value={formData.chip_value}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            onChange={handleInputChange}
                                            id="outlined-basic" label="Stake Value" name="chip_value" variant="outlined" />
                                    </Grid>








                                    <Grid item lg={12} xs={12}>
                                        <Button variant="contained" color="secondary" onClick={modalClose}>Close</Button>&nbsp;
                                        <Button variant="contained" type="submit" color="primary">Save Chages</Button>

                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </div>
                    </Fade>
                </Modal>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={editopens}
                    onClose={editmodalClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={editopens}>
                        <div className="user_modal">
                            <Grid container spacing={2}>
                                <Grid item lg={11} xs={10}>
                                    <h2 id="transition-modal-title">Edit Stake</h2>
                                </Grid>
                                <Grid item lg={1} xs={1}>
                                    <IconButton aria-label="delete" onClick={editmodalClose} color="secondary" className={classes.margin}>
                                        <CancelIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>

                            <ValidatorForm

                                onSubmit={handleUpdate}
                                autoComplete="off"
                            >
                                <Grid container spacing={2}>
                                    <Grid item lg={6} xs={6}>
                                        <TextValidator
                                            size="small"
                                            value={formEditData.chip_name}
                                            inputProps={{
                                                maxLength: 6
                                            }}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            onChange={handleUpdateInputChange}
                                            id="outlined-basic2" label="Stake Name" name="chip_name" variant="outlined" />
                                    </Grid>
                                    <Grid item lg={6} xs={6}>
                                        <TextValidator
                                            size="small"
                                            type="number"
                                            onInput={(e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7)
                                            }}
                                            inputProps={{
                                                min: 1
                                            }}
                                            value={formEditData.chip_value}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            onChange={handleUpdateInputChange}
                                            id="outlined-basic" label="Stake Value" name="chip_value" variant="outlined" />
                                    </Grid>
                                    <Grid item lg={12} xs={12}>
                                        <Button variant="contained" type="submit" color="primary">Save Chages</Button>&nbsp;
                                        <Button variant="contained" color="secondary" onClick={editmodalClose}>Close</Button>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </div>
                    </Fade>
                </Modal>
                <Dialog
                    open={dialougeOpen}
                    onClose={handleDialogeClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are You Sure You Want To Delete This Stake
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleDialogeClose} color="secondary" >
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={confirmDelete} color="primary" autoFocus>
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
            <Footer />
        </>
    );
}