import { FormControl, Input, List, ListItem, ListItemText, FormControlLabel, Divider, TextField, Checkbox, Button, Card, CardActions, CardContent, CardHeader, Typography, Grid } from '@material-ui/core';
import Header from './Header'
import { makeStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import React, { useState } from 'react';
import axios from 'axios';
import Appconfig from '../config/config';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';


function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}
const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        // marginTop: 140,
        // margin: 10,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    divbrown: {
        width: '100%',
        display: 'inline-block',
        margin: '0px 0px',
        background: '#e6e7e9',
        fontSize: 12,
        padding: '0px 10px',

    },
    headerdiv: {
        borderBottom: '1px solid #efefef',
        padding: 10,
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
}));

const MatchGenSetting = () => {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    const [cricket, setCricketData] = useState({
        min_stack_cricket: '10000',
        max_stack_cricket: '110000',
        max_profit_cricket: '200000',
        max_loss_cricket: '200000',
        bet_delay_cricket: '2',
        pre_inplay_profit_cricket: '25000',
        pre_inplay_stack_cricket: '10000',
        min_odds_cricket: '1',
        max_odds_cricket: '20',
        unmatch_bet_cricket: true,
        tihrityMinutesCheck: true
    });

    const [tennis, setTennisData] = useState({
        min_stack_tennis: '10000',
        max_stack_tennis: '110000',
        max_profit_tennis: '200000',
        max_loss_tennis: '200000',
        bet_delay_tennis: '2',
        pre_inplay_profit_tennis: '25000',
        pre_inplay_stack_tennis: '10000',
        min_odds_tennis: '1',
        max_odds_tennis: '20',
        unmatch_bet_tennis: true,
        lock_bet_tennis: true
    });

    const [soccer, setSoccerData] = useState({
        min_stack_soccer: '10000',
        max_stack_soccer: '110000',
        max_profit_soccer: '200000',
        max_loss_soccer: '200000',
        bet_delay_soccer: '2',
        pre_inplay_profit_soccer: '25000',
        pre_inplay_stack_soccer: '10000',
        min_odds_soccer: '1',
        max_odds_soccer: '20',
        unmatch_bet_soccer: '',
        lock_bet_soccer: true
    });

    const [fancy, setFancyData] = useState({
        min_stack_fancy: '10000',
        max_stack_fancy: '110000',
    });

    const [casino, setCasinoData] = useState({
        min_stack_casino: '10000',
        max_stack_casino: '110000',
        max_profit_casino: '200000',
        max_loss_casino: '200000',
        bet_delay_casino: '2',
        pre_inplay_profit_casino: '25000',
        pre_inplay_stack_casino: '10000',
        min_odds_casino: '1',
        max_odds_casino: '20',
        unmatch_bet_casino: true,
        lock_bet_casino: true
    });

    const handleCricketInputChange = (event) => {

        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setCricketData({
                ...cricket,
                [event.target.name]: event.target.value
            });
        }
        console.log('cricket', cricket);
    }

    const handleTennisInputChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setTennisData({
                ...tennis,
                [event.target.name]: event.target.value
            });
        }
        console.log('tennis', tennis);
    }

    const handleSoccerInputChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setSoccerData({
                ...soccer,
                [event.target.name]: event.target.value
            });
        }
        console.log('soccer', soccer);
    }

    const handleFancyInputChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setFancyData({
                ...fancy,
                [event.target.name]: event.target.value
            });
        }
        console.log('fancy', fancy);
    }
    const handleCasinoInputChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setCasinoData({
                ...casino,
                [event.target.name]: event.target.value
            });
        }
        console.log('casino', casino);
    }



    const handleCricketSubmit = (event) => {
        event.preventDefault();
        const postobje = {
            "user_id": userInfo._id,
            "event_type": 1,
            "event_name": "cricket",
            "min_stake": cricket.min_stack_cricket,
            "max_stake": cricket.max_stack_cricket,
            "max_profit": cricket.max_profit_cricket,
            "max_loss": cricket.max_loss_cricket,
            "bet_delay": cricket.bet_delay_cricket,
            "pre_inplay_profit": cricket.pre_inplay_profit_cricket,
            "pre_inplay_stake": cricket.pre_inplay_stack_cricket,
            "min_odds": cricket.min_odds_cricket,
            "max_odds": cricket.max_odds_cricket,
            "unmatch_bet": cricket.unmatch_bet_cricket ? 'Yes' : 'No',
            "lock_bet": cricket.tihrityMinutesCheck ? 'Yes' : 'No',
            "is_unmatch_allowed": cricket.unmatch_bet_cricket ? 'Yes' : 'No',
            "is_odds_allowed": cricket.tihrityMinutesCheck ? 'Yes' : 'No',
        }
        save(postobje);
        console.log('arr', postobje);
    }

    const handleTennisSubmit = (event) => {
        event.preventDefault();
        const postobje = {
            "user_id": userInfo._id,
            "event_type": 1,
            "event_name": "tennis",
            "min_stake": tennis.min_stack_tennis,
            "max_stake": tennis.max_stack_tennis,
            "max_profit": tennis.max_profit_tennis,
            "max_loss": tennis.max_loss_tennis,
            "bet_delay": tennis.bet_delay_tennis,
            "pre_inplay_profit": tennis.pre_inplay_profit_tennis,
            "pre_inplay_stake": tennis.pre_inplay_stack_tennis,
            "min_odds": tennis.min_odds_tennis,
            "max_odds": tennis.max_odds_tennis,
            "unmatch_bet": tennis.unmatch_bet_tennis ? 'Yes' : 'No',
            "lock_bet": tennis.lock_bet_tennis ? 'Yes' : 'No',
            "is_unmatch_allowed": tennis.unmatch_bet_tennis ? 'Yes' : 'No',
            "is_odds_allowed": tennis.lock_bet_tennis ? 'Yes' : 'No',
        }
        save(postobje);
    }

    const handleSoccerSubmit = (event) => {
        event.preventDefault();
        const postobje = {
            "user_id": userInfo._id,
            "event_type": 1,
            "event_name": "soccer",
            "min_stake": soccer.min_stack_soccer,
            "max_stake": soccer.max_stack_soccer,
            "max_profit": soccer.max_profit_soccer,
            "max_loss": soccer.max_loss_soccer,
            "bet_delay": soccer.bet_delay_soccer,
            "pre_inplay_profit": soccer.pre_inplay_profit_soccer,
            "pre_inplay_stake": soccer.pre_inplay_stack_soccer,
            "min_odds": soccer.min_odds_soccer,
            "max_odds": soccer.max_odds_soccer,
            "unmatch_bet": soccer.unmatch_bet_soccer ? 'Yes' : 'No',
            "lock_bet": soccer.lock_bet_soccer ? 'Yes' : 'No',
            "is_unmatch_allowed": soccer.unmatch_bet_soccer ? 'Yes' : 'No',
            "is_odds_allowed": soccer.lock_bet_soccer ? 'Yes' : 'No',
        }
        save(postobje);
    }

    const handleFancySubmit = (event) => {
        event.preventDefault();

        const postobje = {
            "user_id": userInfo._id,
            "event_type": 1,
            "event_name": "fancy",
            "min_stake": fancy.min_stack_fancy,
            "max_stake": fancy.max_stack_fancy,
            "max_profit": fancy.max_profit_fancy,
            "max_loss": fancy.max_loss_fancy,
            "bet_delay": fancy.bet_delay_fancy,
            "pre_inplay_profit": fancy.pre_inplay_profit_fancy,
            "pre_inplay_stake": fancy.pre_inplay_stack_fancy,
            "min_odds": fancy.min_odds_fancy,
            "max_odds": fancy.max_odds_fancy,
            "unmatch_bet": fancy.unmatch_bet_fancy ? 'Yes' : 'No',
            "lock_bet": fancy.lock_bet_fancy ? 'Yes' : 'No',
            "is_unmatch_allowed": fancy.unmatch_bet_fancy ? 'Yes' : 'No',
            "is_odds_allowed": fancy.lock_bet_fancy ? 'Yes' : 'No',
        }
        save(postobje);
    }

    const handleCasinoSubmit = (event) => {
        event.preventDefault();
        const postobje = {
            "user_id": userInfo._id,
            "event_type": 1,
            "event_name": "casino",
            "min_stake": casino.min_stack_casino,
            "max_stake": casino.max_stack_casino,
            "max_profit": casino.max_profit_casino,
            "max_loss": casino.max_loss_casino,
            "bet_delay": casino.bet_delay_casino,
            "pre_inplay_profit": casino.pre_inplay_profit_casino,
            "pre_inplay_stake": casino.pre_inplay_stack_casino,
            "min_odds": casino.min_odds_casino,
            "max_odds": casino.max_odds_casino,
            "unmatch_bet": casino.unmatch_bet_casino ? 'Yes' : 'No',
            "lock_bet": casino.lock_bet_casino ? 'Yes' : 'No',
            "is_unmatch_allowed": casino.unmatch_bet_casino ? 'Yes' : 'No',
            "is_odds_allowed": casino.lock_bet_casino ? 'Yes' : 'No',
        }
        save(postobje);
    }
    function save(formData) {

        console.log('save', formData);


        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}generalSetting/addSetting`,
            headers: {
                'Content-Type': 'application/json',

            },
            data: JSON.stringify(formData)
        };

        axios(config)
            .then(function (response) {

                console.log(response);
                // storeUserinfo(response.data.resultData);
                if (response.statusText == 'OK') {
                    // alert('a'); 
                    notify('success', 'success')
                    // storeUserinfo(response.data.resultData);

                }
                else {
                    notify('faliure', 'error')

                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }

    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;
    return (
        <div className={classes.root}>
            <Header />
            <Grid className="bodypart custom-container grid-row">
                <Grid item lg={2} xs={2} className="xs_none tl-hide">
                    <List component="nav" className="side_list" aria-label="secondary mailbox folders">
                        <ListItemLink href="#simple-list" className="active">
                            <ListItemText primary="Sports" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="In-Play" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="Cricket" />
                        </ListItemLink>
                    </List>
                </Grid>
                <Grid item lg={10} xs={12} className="report-section">
                    <div className="datatable_heading">
                        Set Match General Setting
                    </div>
                    <Card className={classes.root} variant="outlined">





                        <div className={classes.divbrown}>
                            <Typography className="subHeading">Cricket</Typography>
                        </div>

                        <CardContent>
                            <ValidatorForm
                                // 
                                onSubmit={handleTennisSubmit}
                                autoComplete="off"
                            >

                                <div>
                                    <Grid container justifyContent="space-evenly">

                                        <Grid item xs={6} sm={3}>
                                            <TextValidator size="small"
                                                id="outlined-disabled"
                                                label="MIN STAKE"
                                                variant="outlined"
                                                name="min_stack_tennis"
                                                value={tennis.min_stack_tennis}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handleTennisInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextValidator size="small"
                                                id="outlined-disabled"
                                                label="MAX STAKE"
                                                variant="outlined"
                                                name="max_stack_tennis"
                                                value={tennis.max_stack_tennis}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handleTennisInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextValidator size="small"
                                                id="outlined-disabled"
                                                label="MAX PROFIT"
                                                variant="outlined"
                                                name="max_profit_tennis"
                                                value={tennis.max_profit_tennis}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handleTennisInputChange}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Max Loss"
                                                variant="outlined"
                                                name="max_loss_tennis"
                                                onChange={handleTennisInputChange}
                                                value={tennis.max_loss_tennis}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container justifyContent="space-evenly">

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="BET DELAY"
                                                variant="outlined"
                                                name="bet_delay_tennis"
                                                onChange={handleTennisInputChange}
                                                value={tennis.bet_delay_tennis}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="PRE INPLAY PROFIT"
                                                variant="outlined"
                                                name="pre_inplay_profit_tennis"
                                                onChange={handleTennisInputChange}
                                                value={tennis.pre_inplay_profit_tennis}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="PRE INPLAY STAKE"
                                                variant="outlined"
                                                name="pre_inplay_stack_tennis"
                                                onChange={handleTennisInputChange}
                                                value={tennis.pre_inplay_stack_tennis}
                                            /> </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="VOLUME LIMIT"
                                                variant="outlined"
                                                name="volume_limit"
                                                onChange={handleTennisInputChange}
                                            // value={tennis.volume_limit}
                                            />

                                        </Grid>


                                    </Grid>
                                    <Grid container justifyContent="space-evenly">

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="MIN ODDS"
                                                variant="outlined"
                                                name="min_odds_tennis"
                                                onChange={handleTennisInputChange}
                                                value={tennis.min_odds_tennis}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="MAX ODDS"
                                                variant="outlined"
                                                name="max_odds_tennis"
                                                onChange={handleTennisInputChange}
                                                value={tennis.max_odds_tennis}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Range Total Matched"
                                                variant="outlined"
                                                name="range_total_matched"
                                                onChange={handleTennisInputChange}
                                                value={tennis.max_odds_tennis}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Total Match Profit"
                                                variant="outlined"
                                                name="total_match_profit"
                                                onChange={handleTennisInputChange}
                                                value={tennis.max_odds_tennis}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Total Match Stack"
                                                variant="outlined"
                                                name="total_match_stack"
                                                onChange={handleTennisInputChange}
                                                value={tennis.max_odds_tennis}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox

                                                        onChange={handleTennisInputChange}
                                                        name="unmatch_bet_tennis"
                                                        color="primary"
                                                    />
                                                }
                                                label="UNMATCH BET"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox

                                                        onChange={handleTennisInputChange}
                                                        name="thirtyMinutesCheck"
                                                        color="primary"
                                                    />
                                                }
                                                label="30 Minutes Check"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Button variant="contained" type="submit" color="primary">
                                                Update
                                            </Button>
                                        </Grid>
                                    </Grid>


                                </div>

                            </ValidatorForm>

                        </CardContent>

                        <div className={classes.divbrown}>
                            <Typography className="subHeading">Tennis</Typography>
                        </div>
                        <CardContent>
                            <ValidatorForm

                                onSubmit={handleTennisSubmit}
                                autoComplete="off"
                            >

                                <div>
                                    <Grid container justifyContent="space-evenly">

                                        <Grid item xs={6} sm={3}>
                                            <TextValidator size="small"
                                                id="outlined-disabled"
                                                label="MIN STAKE"
                                                variant="outlined"
                                                name="min_stack_tennis"
                                                value={tennis.min_stack_tennis}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handleTennisInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextValidator size="small"
                                                id="outlined-disabled"
                                                label="MAX STAKE"
                                                variant="outlined"
                                                name="max_stack_tennis"
                                                value={tennis.max_stack_tennis}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handleTennisInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextValidator size="small"
                                                id="outlined-disabled"
                                                label="MAX PROFIT"
                                                variant="outlined"
                                                name="max_profit_tennis"
                                                value={tennis.max_profit_tennis}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handleTennisInputChange}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Max Loss"
                                                variant="outlined"
                                                name="max_loss_tennis"
                                                onChange={handleTennisInputChange}
                                                value={tennis.max_loss_tennis}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container justifyContent="space-evenly">

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="BET DELAY"
                                                variant="outlined"
                                                name="bet_delay_tennis"
                                                onChange={handleTennisInputChange}
                                                value={tennis.bet_delay_tennis}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="PRE INPLAY PROFIT"
                                                variant="outlined"
                                                name="pre_inplay_profit_tennis"
                                                onChange={handleTennisInputChange}
                                                value={tennis.pre_inplay_profit_tennis}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="PRE INPLAY STAKE"
                                                variant="outlined"
                                                name="pre_inplay_stack_tennis"
                                                onChange={handleTennisInputChange}
                                                value={tennis.pre_inplay_stack_tennis}
                                            /> </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="VOLUME LIMIT"
                                                variant="outlined"
                                                name="volume_limit"
                                                onChange={handleTennisInputChange}
                                            // value={tennis.volume_limit}
                                            />

                                        </Grid>


                                    </Grid>
                                    <Grid container justifyContent="space-evenly">

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="MIN ODDS"
                                                variant="outlined"
                                                name="min_odds_tennis"
                                                onChange={handleTennisInputChange}
                                                value={tennis.min_odds_tennis}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="MAX ODDS"
                                                variant="outlined"
                                                name="max_odds_tennis"
                                                onChange={handleTennisInputChange}
                                                value={tennis.max_odds_tennis}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Range Total Matched"
                                                variant="outlined"
                                                name="range_total_matched"
                                                onChange={handleTennisInputChange}
                                                value={tennis.max_odds_tennis}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Total Match Profit"
                                                variant="outlined"
                                                name="total_match_profit"
                                                onChange={handleTennisInputChange}
                                                value={tennis.max_odds_tennis}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Total Match Stack"
                                                variant="outlined"
                                                name="total_match_stack"
                                                onChange={handleTennisInputChange}
                                                value={tennis.max_odds_tennis}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox

                                                        onChange={handleTennisInputChange}
                                                        name="unmatch_bet_tennis"
                                                        color="primary"
                                                    />
                                                }
                                                label="UNMATCH BET"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox

                                                        onChange={handleTennisInputChange}
                                                        name="thirtyMinutesCheck"
                                                        color="primary"
                                                    />
                                                }
                                                label="30 Minutes Check"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Button variant="contained" type="submit" color="primary">
                                                Update
                                            </Button>
                                        </Grid>
                                    </Grid>


                                </div>

                            </ValidatorForm>
                        </CardContent>                        <div className={classes.divbrown}>
                            <Typography className="subHeading">Soccer</Typography>
                        </div>
                        {/* <div className={classes.divbrown}>
                            <Typography variant="h6" component="h2">Soccer</Typography>
                        </div> */}
                        <CardContent>
                            <ValidatorForm

                                onSubmit={handleSoccerSubmit}
                                autoComplete="off"
                            >

                                <div>
                                    <Grid container justifyContent="space-evenly">

                                        <Grid item xs={6} sm={3}>
                                            <TextValidator size="small"
                                                id="outlined-disabled"
                                                label="MIN STAKE"
                                                variant="outlined"
                                                name="min_stack_soccer"
                                                value={soccer.min_stack_soccer}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handleSoccerInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextValidator size="small"
                                                id="outlined-disabled"
                                                label="MAX STAKE"
                                                variant="outlined"
                                                name="max_stack_soccer"
                                                value={soccer.max_stack_soccer}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handleSoccerInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextValidator size="small"
                                                id="outlined-disabled"
                                                label="MAX PROFIT"
                                                variant="outlined"
                                                name="max_profit_soccer"
                                                value={soccer.max_profit_soccer}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handleSoccerInputChange}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Max Loss"
                                                variant="outlined"
                                                name="max_loss_soccer"
                                                onChange={handleSoccerInputChange}
                                                value={soccer.max_loss_soccer}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container justifyContent="space-evenly">

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="BET DELAY"
                                                variant="outlined"
                                                name="bet_delay_soccer"
                                                onChange={handleSoccerInputChange}
                                                value={soccer.bet_delay_soccer}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="PRE INPLAY PROFIT"
                                                variant="outlined"
                                                name="pre_inplay_profit_soccer"
                                                onChange={handleSoccerInputChange}
                                                value={soccer.pre_inplay_profit_soccer}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="PRE INPLAY STAKE"
                                                variant="outlined"
                                                name="pre_inplay_stack_soccer"
                                                onChange={handleSoccerInputChange}
                                                value={soccer.pre_inplay_stack_soccer}
                                            /> </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="VOLUME LIMIT"
                                                variant="outlined"
                                                name="volume_limit"
                                                onChange={handleSoccerInputChange}
                                            // value={soccer.volume_limit}
                                            />

                                        </Grid>


                                    </Grid>
                                    <Grid container justifyContent="space-evenly">

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="MIN ODDS"
                                                variant="outlined"
                                                name="min_odds_soccer"
                                                onChange={handleSoccerInputChange}
                                                value={soccer.min_odds_soccer}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="MAX ODDS"
                                                variant="outlined"
                                                name="max_odds_soccer"
                                                onChange={handleSoccerInputChange}
                                                value={soccer.max_odds_soccer}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Range Total Matched"
                                                variant="outlined"
                                                name="range_total_matched"
                                                onChange={handleSoccerInputChange}
                                                value={soccer.max_odds_soccer}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Total Match Profit"
                                                variant="outlined"
                                                name="total_match_profit"
                                                onChange={handleSoccerInputChange}
                                                value={soccer.max_odds_soccer}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Total Match Stack"
                                                variant="outlined"
                                                name="total_match_stack"
                                                onChange={handleSoccerInputChange}
                                                value={soccer.max_odds_soccer}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox

                                                        onChange={handleSoccerInputChange}
                                                        name="unmatch_bet_soccer"
                                                        color="primary"
                                                    />
                                                }
                                                label="UNMATCH BET"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox

                                                        onChange={handleSoccerInputChange}
                                                        name="thirtyMinutesCheck"
                                                        color="primary"
                                                    />
                                                }
                                                label="30 Minutes Check"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Button variant="contained" type="submit" color="primary">
                                                Update
                                            </Button>
                                        </Grid>
                                    </Grid>


                                </div>

                            </ValidatorForm>
                        </CardContent>
                        <div className={classes.divbrown}>
                            <Typography className="subHeading">Fancy</Typography>
                        </div>
                        {/* <div className={classes.divbrown}>
                            <Typography variant="h6" component="h2">Fancy</Typography>
                        </div> */}
                        <CardContent  style={{marginBottom:'100px'}}>
                            <ValidatorForm

                                onSubmit={handleFancySubmit}
                                autoComplete="off"
                            >

                                <div>
                                    <Grid container justifyContent="space-evenly">

                                        <Grid item xs={6} sm={3}>
                                            <TextValidator size="small"
                                                id="outlined-disabled"
                                                label="MIN STAKE"
                                                variant="outlined"
                                                name="min_stack_fancy"
                                                value={fancy.min_stack_fancy}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handleFancyInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextValidator size="small"
                                                id="outlined-disabled"
                                                label="MAX STAKE"
                                                variant="outlined"
                                                name="max_stack_fancy"
                                                value={fancy.max_stack_fancy}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handleFancyInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextValidator size="small"
                                                id="outlined-disabled"
                                                label="MAX PROFIT"
                                                variant="outlined"
                                                name="max_profit_fancy"
                                                value={fancy.max_profit_fancy}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                                onChange={handleFancyInputChange}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Max Loss"
                                                variant="outlined"
                                                name="max_loss_fancy"
                                                onChange={handleFancyInputChange}
                                                value={fancy.max_loss_fancy}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container justifyContent="space-evenly">

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="BET DELAY"
                                                variant="outlined"
                                                name="bet_delay_fancy"
                                                onChange={handleFancyInputChange}
                                                value={fancy.bet_delay_fancy}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="PRE INPLAY PROFIT"
                                                variant="outlined"
                                                name="pre_inplay_profit_fancy"
                                                onChange={handleFancyInputChange}
                                                value={fancy.pre_inplay_profit_fancy}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="PRE INPLAY STAKE"
                                                variant="outlined"
                                                name="pre_inplay_stack_fancy"
                                                onChange={handleFancyInputChange}
                                                value={fancy.pre_inplay_stack_fancy}
                                            /> </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="VOLUME LIMIT"
                                                variant="outlined"
                                                name="volume_limit"
                                                onChange={handleFancyInputChange}
                                            // value={fancy.volume_limit}
                                            />

                                        </Grid>


                                    </Grid>
                                    <Grid container justifyContent="space-evenly">

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="MIN ODDS"
                                                variant="outlined"
                                                name="min_odds_fancy"
                                                onChange={handleFancyInputChange}
                                                value={fancy.min_odds_fancy}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="MAX ODDS"
                                                variant="outlined"
                                                name="max_odds_fancy"
                                                onChange={handleFancyInputChange}
                                                value={fancy.max_odds_fancy}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Range Total Matched"
                                                variant="outlined"
                                                name="range_total_matched"
                                                onChange={handleFancyInputChange}
                                                value={fancy.max_odds_fancy}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Total Match Profit"
                                                variant="outlined"
                                                name="total_match_profit"
                                                onChange={handleFancyInputChange}
                                                value={fancy.max_odds_fancy}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <TextField size="small"
                                                id="outlined-disabled"
                                                label="Total Match Stack"
                                                variant="outlined"
                                                name="total_match_stack"
                                                onChange={handleFancyInputChange}
                                                value={fancy.max_odds_fancy}
                                            />
                                        </Grid>

                                        <Grid item xs={6} sm={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox

                                                        onChange={handleFancyInputChange}
                                                        name="unmatch_bet_fancy"
                                                        color="primary"
                                                    />
                                                }
                                                label="UNMATCH BET"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox

                                                        onChange={handleFancyInputChange}
                                                        name="thirtyMinutesCheck"
                                                        color="primary"
                                                    />
                                                }
                                                label="30 Minutes Check"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <Button variant="contained" type="submit" color="primary">
                                                Update
                                            </Button>
                                        </Grid>
                                    </Grid>


                                </div>

                            </ValidatorForm>
                        </CardContent>


                    </Card>
                </Grid>
            </Grid>
            <Footer/>
        </div>
    );
}

export default MatchGenSetting;