import React, { useState } from 'react';
import './login.css'
import CircularProgress from '@material-ui/core/CircularProgress';
import Logo from './assets/logo_line_login_page.png'
import { FormControl, Input, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import Appconfig from './config/config';
import { toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import bgnew from './assets/login-bg-mobile.jpg';
import fg from './assets/footer-logo.png';
import epn from './assets/18plusNew.png';
import gs from './assets/gamstop.svg';
import bg from './assets/begambleaware.png';
import gc from './assets/gamecare.svg';




const Login = (props) => {
    const history = useHistory();
    const [formData, setformData] = useState({
        user_name: '',
        password: '',
        site_code: Appconfig.sitecodes,
        user_type: "Operator"
    });
    const [activeLoader, setActiveLoader] = useState(false)
    const handleInputChange = (event) => {
        setformData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }
    const handleSubmit = (event) => {
        setActiveLoader(true);
        event.preventDefault();
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}users/userAuthenticate`,
            headers: {
                'Content-Type': 'application/json',

            },
            data: JSON.stringify(formData)
        };

        axios(config)
            .then(function (response) {


                if (response.data.result) {
                    notify(response.data.resultMessage, 'success')
                    storeUserinfo(response.data.resultData);
                    props.setLoggedIn(true)

                }
                else {
                    notify(response.data.resultMessage, 'error')

                }
                setActiveLoader(false);
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }
    function storeUserinfo(userdata) {
        window.sessionStorage.setItem("loggedIn", true);
        window.sessionStorage.setItem("userData", JSON.stringify(userdata));
        history.push('/settlement');

    }
    return (
        <div>
            {/* <ToastContainer limit={3} /> */}

            <div className="login-body">
                <div id="wrapper">

                    <div className="logo">
                        <img className="logoheadImage" src={Logo} alt="sgBetImage" />
                    </div>
                    <div style={{ padding: '20px' }}>
                        <div id="login" className="form">
                            <section className="login-form">
                                <ValidatorForm

                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                >

                                    <FormControl fullWidth className="login-margin" >
                                        <TextValidator fullWidth validators={['required']}
                                            errorMessages={['this field is required']} value={formData.user_name} helperText="Username" placeholder="Enter username" name="user_name" onChange={handleInputChange} />
                                    </FormControl>
                                    <FormControl fullWidth className="login-margin">
                                        <TextValidator fullWidth validators={['required']}
                                            errorMessages={['this field is required']} type="password" value={formData.password} helperText="Password" placeholder="Password" name="password" onChange={handleInputChange} />
                                    </FormControl>
                                    <FormControlLabel className="remember-me login-margin"
                                        control={<Checkbox name="checkedB" color="primary" />} label="Remember me" />
                                    <FormControl fullWidth >
                                        {/* <Link onClick={()=>userLogin}> */}
                                        <Button type="submit" variant="contained" color="primary" size="large" className="login-btn">Login {activeLoader ? <CircularProgress size={20} style={{ color: "white", marginLeft: 10 }} /> : ""}</Button>
                                        <small className="recaptchaTerms">This site is protected by reCAPTCHA and the Google
                                            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                                            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                                        </small>
                                        {/* </Link> */}
                                    </FormControl>
                                </ValidatorForm>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="customfooter">
                <p className="parafirst" style={{ lineHeight: '28px' }}>
                    <a data-v-4272648c href="/m/responsiblegambling" className>
                        Responsible Gambling
                    </a>  |
                    <a data-v-4272648c href="javscript:void(0)" role="button">Prohibited Territories</a>  |
                    <a data-v-4272648c href="javscript:void(0)" ><img data-v-4272648c src={gc} className="gamecare" alt="dsf" /></a>  |
                    <a data-v-4272648c href="javscript:void(0)" ><img data-v-4272648c src={bg} className="begambleaware" alt="dsf" /></a>  |
                    <a data-v-4272648c href="javscript:void(0)" ><img data-v-4272648c src={gs} className="gamstop" alt="dsf" /></a> |
                    <a data-v-4272648c href="javscript:void(0)"><img data-v-4272648c src={epn} className="plus18" alt="dsf" /></a>
                </p>
                <p data-v-4272648c className="parasecond">
                    Copyright 2020. Powered by <img data-v-4272648c src={fg} alt="dsf" /></p>
            </footer>
        </div>
    )
}

export default Login;