import './App.css';
import Report from './admin/Report';
import Dashboard from './admin/Dashboard';
import EventDetail from './admin/EventDetail';
import Accountinfo from './admin/Accountinfo';
import Profitloss from './admin/Profitloss';
import Bethistory from './admin/Bethistory';
import LiveBethistory from './admin/LiveBethistory';
import Login from './Login';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Viewinfo from './admin/ViewInfo';
import Chips from './admin/Chips';
import UserList from './admin/UserList';
import News from './admin/News';

import Inplay from './admin/Inplay';
import Sports from './admin/Sports';
import Casino from './admin/Casino';
import AdminFund from './admin/AdminFund';
import MatchSetting from './admin/MatchSetting';
import MatchGenSetting from './admin/MatchGenSetting';
import WebSetting from './admin/WebsiteSetting';
import MessageSetting from './admin/MessageSetting';
import BlockMarket from './admin/BlockMarket';
import SessionFancy from './admin/SessionFancy';
import RunningMarketAnalysis from './admin/RunningMarketAnalysis';
import BookMaking from './admin/BookMaking';
import AddFancy from './admin/AddFancy';
import BettingEventsList from './admin/BettingEventsList';
import AdminMatchListing from './admin/AdminMatchListing';
import EventList from './admin/EventList';
import Appconfig from './config/config'
import CasinoList from './admin/CasinoList';
import CasinoBets from './admin/CasinoBets';
import AllUserOnline from './admin/AllUserOnline';
import { SocketContext, socket } from './context/socket';
import Settlement from './admin/Settlement';
import SettlementEvent from './admin/SettlementEvent';
import SettlementEventData from './admin/SettlementEventData';
import FancyStack from './admin/FancyStack';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Settled from './admin/Settled';
import SettledEvent from './admin/SettledEvent';
import SettledEventData from './admin/SettledEventData';
import AccountList from './admin/AccountList'
import ChipSummary from './admin/ChipSummary';
import CloseUserList from './admin/CloseUserList';
import BlockMarketEvents from './admin/BlockMarketEvents';
import BlockMarketEventsMarkets from './admin/BlockMarketEventsMarkets';
import BlockMarketEventsFancys from './admin/BlockMarketEventsFancys';
import ScrollIntoView from './admin/ScrollIntoView';
import BetDelete from './admin/BetDelete';
import BetDeleteEvents from './admin/BetDeleteEvents';
import BetDeleteEventsBet from './admin/BetDeleteEventsBet';
function App() {

  const [loggedin, setLoggedIn] = useState(false);
  let loggedIn = window.sessionStorage.getItem("loggedIn");
  useEffect(() => {
    window.scroll(0, 0)
    loggedIn = window.sessionStorage.getItem("loggedIn");
  }, [loggedin]);
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={false}
        draggable={true}
        progress={undefined}
        limit={1}
      />
      <SocketContext.Provider value={socket}>
        <Router >
          <ScrollIntoView>
            <Switch>
              {
                loggedIn === 'true'
                  ? (
                    <>

                      <Route exact path="/" component={Login} />
                      <Route exact path="/dashboard" component={Dashboard} />
                      <Route exact path="/event-detail/:event_id" component={EventDetail} />
                      <Route exact path="/report" component={Report} />
                      <Route exact path="/accountinfo/:user_id" component={Accountinfo} />
                      <Route exact path="/profitloss/:user_id" component={Profitloss} />
                      <Route exact path="/bethistory/:event_id/:selection_id/:user_id" component={Bethistory} />
                      <Route exact path="/livebethistory/:user_id" component={LiveBethistory} />
                      <Route exact path="/user_gen_settings/:user_id" component={Viewinfo} />
                      <Route exact path="/chips/:user_id/:user_type" component={Chips} />
                      <Route exact path="/userlist/:user_id/:user_type" component={UserList} />

                      <Route exact path="/news" component={News} />
                      <Route exact path="/inplay" component={Inplay} />
                      <Route exact path="/sports" component={Sports} />
                      <Route exact path="/casinos" component={Casino} />
                      <Route exact path="/adminfund" component={AdminFund} />
                      <Route exact path="/matchsetting" component={MatchSetting} />
                      <Route exact path="/websitesetting" component={WebSetting} />
                      <Route exact path="/messagesetting" component={News} />
                      <Route exact path="/matchgensetting" component={MatchGenSetting} />
                      <Route exact path="/blockmarket" component={BlockMarket} />
                      <Route exact path="/blockmarket/:event_type_id" component={BlockMarketEvents} />
                      <Route exact path="/blockmarket-markets/:event_type_id/:event_id" component={BlockMarketEventsMarkets} />
                      <Route exact path="/blockmarket-markets/:event_type_id/:event_id/fancy" component={BlockMarketEventsFancys} />

                      <Route exact path="/session-fancy" component={SessionFancy} />
                      <Route exact path="/running-market-analysis" component={RunningMarketAnalysis} />
                      <Route exact path="/book-making" component={BookMaking} />
                      <Route exact path="/add-fancy" component={AddFancy} />
                      <Route exact path="/betting-events-list" component={BettingEventsList} />
                      <Route exact path="/admin-match-listing" component={AdminMatchListing} />
                      <Route exact path="/event-list/:event_type_id" component={EventList} />
                      <Route exact path="/casino-list" component={CasinoList} />
                      <Route exact path="/casino-bets/:casino_type" component={CasinoBets} />
                      <Route exact path="/all-user-online" component={AllUserOnline} />
                      <Route exact path="/settlement" component={Settlement} />
                      <Route exact path="/settlement/:event_type" component={SettlementEvent} />
                      <Route exact path="/settlement-data/:event_type/:event_id" component={SettlementEventData} />

                      <Route exact path="/settled" component={Settled} />
                      <Route exact path="/settled/:event_type" component={SettledEvent} />
                      <Route exact path="/settled-data/:event_type/:event_id" component={SettledEventData} />
                      <Route exact path="/fancy-stack" component={FancyStack} />
                      <Route exact path="/account-list/:user_id/:user_type" component={AccountList} />
                      <Route exact path="/chipsummary/:user_id" component={ChipSummary} />
                      <Route exact path="/closeduserlist" component={CloseUserList} />
                      <Route exact path="/betdelete" component={BetDelete} />
                      <Route exact path="/betdelete-event/:event_type_id" component={BetDeleteEvents} />
                      <Route exact path="/betdelete-bet/:event_type_id/:event_id" component={BetDeleteEventsBet} />

                    </>
                  )
                  : (<Route component={() => <Login setLoggedIn={setLoggedIn} />} />)
              }


            </Switch>
          </ScrollIntoView>
        </Router>
      </SocketContext.Provider>
    </>
  );
}

export default App;
