import React from 'react';
import { makeStyles, Grid, List, ListItem, ListItemText, DialogTitle, Dialog, DialogActions, DialogContentText, DialogContent, Chip, IconButton, Switch, MenuItem, InputLabel, FormControlLabel, Select, Button, FormControl, TableCell, TableRow, TableBody, TableContainer, Table, TableHead, NativeSelect, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { ToastContainer, toast } from 'react-toastify';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Header from './Header'
import Footer from './Footer'
import Appconfig from '../config/config';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import './admin.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import SearchBar from "material-ui-search-bar";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function SettledEventData() {
    const params = useParams();
    const classes = useStyles();
    const history = useHistory();
    const [eventList, SetEventList] = React.useState({});
    const [eventType, SetEventType] = React.useState(params.event_type);
    const [eventId, SetEventId] = React.useState(params.event_id);
    const [menuItem, SetMenuItem] = React.useState({});
    const [price, SetPrice] = React.useState({});
    const [marketFancys, SetMarketFancys] = React.useState([]);
    const [searched, setSearched] = React.useState("");
    const [dialougeOpen, setDialougeOpen] = React.useState(false);
    const [fetchComplete, setFetchComplete] = React.useState(false);
    const [resultDialougeOpen, setResultDialougeOpen] = React.useState(false);
    const [matchData, setMatchData] = React.useState("");

    const [revertMatchResultD, setRevertMatchResultDOpen] = React.useState(false);
    const [revertMatchData, setrevertMatchData] = React.useState("");
    const [revertMatchDataObj, setrevertMatchDataObj] = React.useState({});
    const [fcResultDialougeOpen, setFCResultDialougeOpen] = React.useState(false);
    const [fResultDialougeOpen, setFResultDialougeOpen] = React.useState(false);
    const [fMatchData, setFMatchData] = React.useState("");
    const [fcMatchData, setFCMatchData] = React.useState("");

    const [revertFancyResultD, setRevertFancyResultDOpen] = React.useState(false);
    const [revertFancyData, setrevertFancyData] = React.useState("");
    const [tiePassword, setTiePassword] = React.useState("");
    const [cancelPassword, setCancelPassword] = React.useState("");
    const [resultPassword, setResultPassword] = React.useState("");
    const [fancyResultPassword, setFancyResultPassword] = React.useState("");
    const [matchDataObj, setMatchDataObj] = React.useState({});

    React.useEffect(() => {
        window.scroll(0, 0);
        getSettlementEventTypes();
    }, []);
    const [state, setState] = React.useState({

    });

    const [switchData, setSwitchData] = React.useState({
        checkedA: false,
        checkedB: false,
    });

    const handleTiePassword = (event) => {
        setTiePassword(event.target.value,);
    };
    const handleCancelPassword = (event) => {
        setCancelPassword(event.target.value,);
    };
    const handleResultPassword = (event) => {
        setResultPassword(event.target.value);
    };
    const handleFancyResultPassword = (event) => {
        setFancyResultPassword(event.target.value);
    };
    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    const handleSwitch = (event) => {
        setSwitchData({
            ...switchData,
            [event.target.name]: event.target.checked,
        });

        unListFromSettlementList(eventId, event.target.checked, event.target.name)
    }

    const handleTie = (event) => {
        handleClickDialogeOpen()
        setSwitchData({
            ...switchData,
            [event.target.name]: event.target.checked,
        });
    }

    const confirmTie = (event) => {
        unListFromSettlementList(eventId, switchData.checkedB, "checkedB")
    }

    function handleClickDialogeOpen() {
        setDialougeOpen(true);
    };

    const handleDialogeClose = () => {
        setDialougeOpen(false);
    };
    const handleFCResultDialogeClose = () => {
        setFCResultDialougeOpen(false);
    };
    const cancelFancyResult = () => {
        let ndata = JSON.parse(fMatchData);
        ndata.password = cancelPassword;
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}settlement/cancelFancy`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(ndata)
        };

        axios(config)
            .then(function (response) {
                // SetPrice({ [id]: '' })
                getSettlementEventTypes()
                setFCResultDialougeOpen(false);
                if (response.data) {

                    notify('Fancy Canceled', 'success');
                }
                else {
                    notify('something Occurred', 'error');
                }
                // SetPrice({ [id]: '' })
                // //console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function fancyCancel(event_id, selection_id, id) {

        var data = JSON.stringify({
            "event_id": event_id,
            "selection_id": selection_id,
            "result": price[id],
            "is_fancy": "Yes"
        });

        setFMatchData(data);
        setFCResultDialougeOpen(true);

    }
    const handlePrice = (event) => {
        SetPrice({
            ...price,
            [event.target.name]: event.target.value,
        });
    }

    const handleDropChange = (event) => {
        SetMenuItem({
            ...menuItem,
            [event.target.name]: event.target.value,
        });
    }

    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }

    function getSettlementEventTypes() {
        setFetchComplete(true)
        const data = JSON.stringify({

            "event_id": eventId,
            "event_type": eventType,
            "status": "Settled"

        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}settlement/getSettlementEventsData`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                // console.log(response.data.resultData);
                SetEventList(response.data.resultData);
                SetMarketFancys(response.data.resultData.marketFancys)
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(() => { setFetchComplete(false); });
    }
    // console.log(eventList);

    function betSettle(event_id, market_id, id, marketName) {

        var tempdata = {
            "event_id": event_id,
            "market_id": market_id,
            "result": menuItem[id],
            "is_fancy": "No",
            "marketName": marketName,
            "event_type": params.event_type
        };
        var data = JSON.stringify(tempdata);
        setMatchDataObj(tempdata);
        setMatchData(data);
        setResultDialougeOpen(true);
    }

    function betSettleRestore(event_id, market_id, id, marketName) {
        let tempData = {
            "event_id": event_id,
            "market_id": market_id,
            "result": menuItem[id],
            "is_fancy": "No",
            "restore": true,
            "marketName": marketName,
        };
        var data = JSON.stringify(tempData);
        setrevertMatchDataObj(tempData);
        setrevertMatchData(data);
        setRevertMatchResultDOpen(true);
    }

    function fancySettle(event_id, selection_id, id) {

        var data = JSON.stringify({
            "event_id": event_id,
            "selection_id": selection_id,
            "result": price[id],
            "is_fancy": "Yes"
        });

        setFMatchData(data);
        setFResultDialougeOpen(true);

    }


    function fancySettleRestore(event_id, selection_id, id) {

        var data = JSON.stringify({
            "event_id": event_id,
            "selection_id": selection_id,
            "result": price[id],
            "is_fancy": "Yes",
            "restore": true
        });

        setrevertFancyData(data);
        setRevertFancyResultDOpen(true);

    }

    function unListFromSettlementList(event_id, settleStatus, tieOrUnlist) {

        var data, url;
        if (tieOrUnlist == 'checkedA') {
            data = JSON.stringify({
                "event_id": event_id,
                "settleStatus": settleStatus
            });
            url = 'settleUnsettleEvents';
        }
        else {
            data = JSON.stringify({
                "event_id": event_id,
                "tieStatus": settleStatus,
                "password": tiePassword
            });
            url = 'settleTieMatch';
        }

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}settlement/${url}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                getSettlementEventTypes()
                handleDialogeClose();
                if (response.data.result) {
                    notify(response.data.resultMessage, 'success');
                }
                else {
                    notify(response.data.resultMessage, 'error');
                }
                // //console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });

    }


    const confirmUpdateResult = () => {
        let tempMatchData = JSON.parse(matchData);
        tempMatchData.password = resultPassword;
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}settlement/settlementEntrySubmit`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(tempMatchData)
        };

        axios(config)
            .then(function (response) {
                getSettlementEventTypes()
                setResultDialougeOpen(false);
                if (response.data.result) {
                    notify(response.data.resultMessage, 'success');
                }
                else {
                    notify(response.data.resultMessage, 'error');
                }
                // SetMenuItem({ [id]: ' ' })
                // //console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleResultDialogeClose = () => {
        setResultDialougeOpen(false);
    };

    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }

    const requestSearch = (searchedVal) => {
        // console.log("searchedVal",searchedVal);
        const filteredRows = eventList.marketFancys.filter((row) => {
            return row.runner_name.toLowerCase().includes(searchedVal.toLowerCase());
        });
        SetMarketFancys(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    const handleRevertMatchDClose = () => {
        setRevertMatchResultDOpen(false);
    }


    const confirmRevertMatch = () => {
        let tempMatchData = JSON.parse(revertMatchData);
        tempMatchData.password = resultPassword;
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}settlement/settlementEntrySubmit`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(tempMatchData)
        };

        axios(config)
            .then(function (response) {
                getSettlementEventTypes()
                setRevertMatchResultDOpen(false);
                if (response.data.result) {
                    notify(response.data.resultMessage, 'success');
                }
                else {
                    notify(response.data.resultMessage, 'error');
                }
                // SetMenuItem({ [id]: ' ' })
                // //console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const confirmUpdateFResult = () => {

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}settlement/settlementEntrySubmit`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: fMatchData
        };

        axios(config)
            .then(function (response) {
                getSettlementEventTypes();
                setFResultDialougeOpen(false);
                if (response.data.result) {
                    notify(response.data.resultMessage, 'success');
                }
                else {
                    notify(response.data.resultMessage, 'error');
                }
                // SetPrice({ [id]: '' })
                // //console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const handleFResultDialogeClose = () => {
        setFResultDialougeOpen(false);
    };


    const handleRevertFancyDClose = () => {
        setRevertFancyResultDOpen(false);
    }


    const confirmRevertFancy = () => {
        let tempfMatchData = JSON.parse(revertFancyData);
        tempfMatchData.password = fancyResultPassword;
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}settlement/settlementEntrySubmit`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(tempfMatchData)
        };

        axios(config)
            .then(function (response) {
                getSettlementEventTypes();
                setRevertFancyResultDOpen(false);
                if (response.data) {
                    notify('Fancy Result Updated', 'success');
                }
                else {
                    notify('something Occurred', 'error');
                }
                // SetPrice({ [id]: '' })
                // //console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (
        <div className={classes.root}>
            <Header />

            <Grid className="bodypart custom-container grid-row">
                <Grid item lg={2} xs={2} className="xs_none tl-hide">
                    <List component="nav" className="side_list" aria-label="secondary mailbox folders">
                        <ListItemLink href="#simple-list" className="active">
                            <ListItemText primary="Sports" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="In-Play" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="Cricket" />
                        </ListItemLink>
                    </List>
                </Grid>

                <Grid item lg={10} xs={12} className="Settlement-section">
                    {fetchComplete ? <div className="set-loader"><CircularProgress /> </div> :
                        <div>
                            {
                                eventList.hasOwnProperty('eventsDetail') &&
                                <div class="title_new_at" style={{ padding: "11px" }}>
                                    <span class="lable-user-name">
                                        {eventList.eventsDetail.event_name}  Utd Match Tie
                                    </span>
                                    <span style={{ float: "right" }}>
                                        <FormControlLabel
                                            control={<Switch checked={eventList.eventsDetail.is_tie == "Yes"} value={eventList.eventsDetail.is_tie == "Yes"} onChange={handleTie} name="checkedB" />}
                                            label={eventList.eventsDetail.is_tie == "Yes" ? "On" : "Off"}
                                        />
                                    </span>
                                </div>
                            }

                            {

                                eventList.hasOwnProperty("eventsDetail") && eventList.eventsDetail.is_tie == "No" && eventList.hasOwnProperty('marketTypes')
                                && eventList.marketTypes.map((row, index) => (
                                    <>
                                        <div class="title_new_at" style={{ padding: "11px" }}>
                                            <span class="lable-user-name">
                                                {eventList.eventsDetail.event_name} {row.market_name}
                                            </span>
                                        </div>
                                        <div class=" appendAjaxTbl">
                                            <Table className={classes.table} size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow class="headings">
                                                        <TableCell>S.No.</TableCell>

                                                        <TableCell colSpan="2">Result</TableCell>
                                                        <TableCell style={{ textAlign: "center", }}>Action</TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell>1</TableCell>
                                                        <TableCell colSpan="2">
                                                            <FormControl variant="outlined" size="small" className={classes.formControl}>
                                                                {/* <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel> */}
                                                                <Select
                                                                    labelId="demo-simple-select-outlined-label"
                                                                    id="demo-simple-select-outlined"
                                                                    value={menuItem.hasOwnProperty(`select${index}`) ? menuItem[`select${index}`] : row.result}
                                                                    onChange={handleDropChange}
                                                                    defaultValue={row.result}
                                                                    name={`select${index}`}
                                                                    style={{ width: '120px' }}
                                                                >
                                                                    <MenuItem value=" ">
                                                                        <em>--Select Result--</em>
                                                                    </MenuItem>
                                                                    {row.marketRunners.map((runner, index) => (

                                                                        runner.hasOwnProperty('runner_name') &&
                                                                        <MenuItem value={runner.selection_id}>{runner.runner_name}</MenuItem>
                                                                    ))
                                                                    }


                                                                </Select>
                                                            </FormControl>


                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => { betSettleRestore(eventList.eventsDetail.event_id, row.market_id, `select${index}`, row._id) }}>
                                                                <SettingsBackupRestoreIcon />
                                                            </IconButton>
                                                            <Button type="button" size="small" style={{ background: "green", color: '#fff' }} variant="contained" id="btn_30800505" onClick={() => { betSettle(eventList.eventsDetail.event_id, row.market_id, `select${index}`, row._id) }}>Save</Button></TableCell>
                                                    </TableRow>

                                                </TableHead>
                                            </Table>
                                        </div>


                                    </>
                                ))
                            }
                            {
                                eventList.hasOwnProperty('eventsDetail') &&
                                <div class="title_new_at" style={{ padding: "11px" }}>
                                    <span class="lable-user-name">
                                        {eventList.eventsDetail.event_name}  Fancy
                                    </span>

                                </div>
                            }

                            <div class=" appendAjaxTbl">
                                <SearchBar
                                    value={searched}
                                    onChange={(searchVal) => requestSearch(searchVal)}
                                    onCancelSearch={() => cancelSearch()}
                                />
                                <Table className={classes.table} id="settlements" size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow class="headings">
                                            <TableCell >S.N.</TableCell>
                                            <TableCell >Runner</TableCell>
                                            <TableCell width={100}>Price</TableCell>
                                            <TableCell >Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            marketFancys.map((row, index) => (
                                                <TableRow>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>
                                                        {row.runner_name}
                                                    </TableCell>
                                                    <TableCell  >
                                                        <TextField
                                                            variant="outlined"
                                                            name={`price_${index}`}
                                                            size="small"
                                                            defaultValue={row.result ? row.result : ''}
                                                            onChange={handlePrice}
                                                            value={price[`price_${index}`]}

                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => { fancySettleRestore(eventList.eventsDetail.event_id, row.selection_id, `price_${index}`) }}>
                                                            <SettingsBackupRestoreIcon />
                                                        </IconButton>
                                                        <Button type="button" size="small" style={{ background: "red", color: '#fff' }} variant="contained" id="btn_30800505" onClick={() => { fancyCancel(eventList.eventsDetail.event_id, row.selection_id, `price_${index}`) }}>Cancel</Button>
                                                        <Button type="button" size="small" style={{ background: "green", color: '#fff' }} variant="contained" id="btn_30800505" onClick={() => { fancySettle(eventList.eventsDetail.event_id, row.selection_id, `price_${index}`) }}>Save</Button></TableCell>
                                                </TableRow>


                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    }
                </Grid>

                <Dialog open={resultDialougeOpen}
                    onClose={handleResultDialogeClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are You Sure You Want To update The result
                        </DialogContentText>
                        {params.event_type == 4 && ['Match Odds', 'Bookmaker'].includes(matchDataObj.marketName) ? <TextField
                            autoFocus
                            margin="dense"
                            id="resultpassword"
                            label="Result Password"
                            type="password"
                            fullWidth
                            onChange={handleResultPassword}
                            value={resultPassword}
                            required={true}
                        /> : ""

                        }
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleResultDialogeClose} color="secondary" >
                            Cancel
                        </Button>
                        <Button disabled={params.event_type == 4 && ['Match Odds', 'Bookmaker'].includes(matchDataObj.marketName) ? (resultPassword.length > 0 ? false : true) : false} variant="contained" onClick={confirmUpdateResult} color="primary" autoFocus>
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={dialougeOpen}
                    onClose={handleDialogeClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are You Sure You Want To {switchData.checkedB ? "Tie" : "UnTie"} This Match
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="tiepassword"
                            label="Tie Password"
                            type="password"
                            fullWidth
                            onChange={handleTiePassword}
                            value={tiePassword}
                            required={true}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleDialogeClose} color="secondary" >
                            Cancel
                        </Button>
                        <Button disabled={tiePassword.length > 0 ? false : true} variant="contained" onClick={confirmTie} color="primary" autoFocus>
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={revertMatchResultD}
                    onClose={handleRevertMatchDClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are You Sure You Want To revert this match result
                        </DialogContentText>
                        {params.event_type == 4 && ['Match Odds', 'Bookmaker'].includes(revertMatchDataObj.marketName) ? <TextField
                            autoFocus
                            margin="dense"
                            id="resultpassword"
                            label="Result Password"
                            type="password"
                            fullWidth
                            onChange={handleResultPassword}
                            value={resultPassword}
                            required={true}
                        /> : ""

                        }
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleRevertMatchDClose} color="secondary" >
                            Cancel
                        </Button>
                        <Button disabled={params.event_type == 4 && ['Match Odds', 'Bookmaker'].includes(revertMatchDataObj.marketName) ? (resultPassword.length > 0 ? false : true) : false} variant="contained" onClick={confirmRevertMatch} color="primary" autoFocus>
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={fResultDialougeOpen}
                    onClose={handleFResultDialogeClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are You Sure You Want To update The Fancy result
                        </DialogContentText>
                        {/* <TextField
                            autoFocus
                            margin="dense"
                            id="resultpassword"
                            label="Result Password"
                            type="password"
                            fullWidth
                            onChange={handleFancyResultPassword}
                            value={fancyResultPassword}
                            required={true}
                        /> */}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleFResultDialogeClose} color="secondary" >
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={confirmUpdateFResult} color="primary" autoFocus>
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={revertFancyResultD}
                    onClose={handleRevertFancyDClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are You Sure You Want To revert this Fancy result
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="resultpassword"
                            label="Result Password"
                            type="password"
                            fullWidth
                            onChange={handleFancyResultPassword}
                            value={fancyResultPassword}
                            required={true}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleRevertFancyDClose} color="secondary" >
                            Cancel
                        </Button>
                        <Button disabled={fancyResultPassword.length > 0 ? false : true} variant="contained" onClick={confirmRevertFancy} color="primary" autoFocus>
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={fcResultDialougeOpen}
                    onClose={handleFCResultDialogeClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Confirm</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are You Sure You Want To Cancel The Fancy
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="cancelpassword"
                            label="Cancel Password"
                            type="password"
                            fullWidth
                            onChange={handleCancelPassword}
                            value={cancelPassword}
                            required={true}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleFCResultDialogeClose} color="secondary" >
                            Cancel
                        </Button>
                        <Button disabled={cancelPassword.length > 0 ? false : true} variant="contained" onClick={cancelFancyResult} color="primary" autoFocus>
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
            <Footer />

        </div >
    );
}