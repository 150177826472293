import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, List, ListItem, TextareaAutosize, ListItemText, FormControl, NativeSelect, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from './Header'
import Footer from './Footer'
// import React, { useState, useEffect } from 'react';
import { Table, TableBody, MenuItem, InputLabel, Select, Menu, Checkbox, TableCell, TableContainer, TableHead, Paper, Dialog, TableRow, DialogActions, DialogTitle, DialogContentText, DialogContent, Button, Modal, Typography, Fade, Backdrop } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Appconfig from '../config/config';

import axios from 'axios';
import moment from 'moment';
import { spacing } from '@material-ui/system';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import './admin.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function MessageSetting() {


  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
  const [opens, setOpens] = React.useState(false);

  const [News, setNews] = React.useState([]);
  const [dialougeOpen, setDialougeOpen] = React.useState(false);
  const [deleteNewsid, setDeleteNewsid] = React.useState('');
  const [editopens, setEditOpens] = React.useState(false);
  const [formEditData, setEditFormData] = useState({
    site_id: "60fc5aca67e1e3799230a6f7",
    message: '',
    is_active: "Yes",
    is_delete: "No",
    id: ''
  });

  const handleUpdateInputChange = (event) => {


    setEditFormData({
      ...formEditData,
      [event.target.name]: event.target.value
    })
  }


  const editmodalClose = () => {
    setEditOpens(false);
  };
  const modalOpen = () => {
    setOpens(true);

  };


  const modalClose = () => {
    setOpens(false);
  };


  const [formData, setFormData] = useState({
    site_id: "60fc5aca67e1e3799230a6f7",
    message: '',
    is_active: "Yes",
    is_delete: "No"


  });


  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }
  function handleClickDialogeOpen() {
    setDialougeOpen(true);
  };

  const handleDialogeClose = () => {
    setDialougeOpen(false);
  };
  const handleSubmit = (e) => {

    e.preventDefault();

    var axios = require('axios');
    var data = JSON.stringify(formData);

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}news/addNews`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    console.log(data);
    axios(config)
      .then(function (response) {
        getNews();
        modalClose();
        if (response.data.result) {
          notify('success', response.data.resultMessage)
        }
        else {
          notify('error', response.data.resultMessage)
        }

      })
      .catch(function (error) {
        console.log(error);
      });


  }




  const handleUpdate = (e) => {

    e.preventDefault();


    var data = JSON.stringify({
      _id: formEditData.id,
      data: {
        message: formData.message,
        is_active: "Yes",
        is_delete: "No"
      }
    });

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}news/updateNews`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    console.log(data);
    axios(config)
      .then(function (response) {

        getNews();
        editmodalClose();
        if (response.data.result) {
          notify('success', response.data.resultMessage)
          setEditOpens(false);
          setFormData({ message: '' });
        }
        else {
          notify('error', response.data.resultMessage)
        }

      })
      .catch(function (error) {
        console.log(error);
      });


  }

  const classes = useStyles();

  useEffect(() => { window.scroll(0,0)
    getNews();
  }, []);

  function getNews() {

    var data = JSON.stringify({
      "site_id": "60fc5aca67e1e3799230a6f7"
    });

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}news/getNews`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setNews(response.data.resultData);
        let tmpNews = response.data.resultData;
        const newNews = tmpNews.map((news, index) => {
          tmpNews[index]['id'] = index + 1;
        });
        console.log('data', tmpNews);
        setNews(tmpNews);
        // console.log(News);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  function editNews(id, index) {

    console.log(News[index - 1].message);
    formData.message = News[index - 1].message
    formEditData.id = News[index - 1]._id
    setEditOpens(true);
  }

  function deleteNews(id) {
    setDeleteNewsid(id);
    handleClickDialogeOpen();
  }

  const confirmDelete = () => {
    var data = JSON.stringify({
      "_id": deleteNewsid
    });

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}news/deleteNews`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // setdeletechipid('');
        setDialougeOpen(false);
        console.log('Delete', response.data);
        if (response.data.result) {
          getNews();
          notify(response.data.resultMessage, 'success')
        }
        else {
          notify(response.data.resultMessage, 'error')
        }
      })
      .catch(function (error) {

        console.log(error);
      });
  }
  function notify(message, type) {
    toast.configure();
    if (type === 'error') {
      toast.error(message, {
        toastId: "error"
      });
    }
    else {
      toast.success(message, {
        toastId: "success"
      });
    }
  }

  const columns = [


    {
      field: 'id',
      headerName: 'S.NO',
      editable: true,
      flex: 1,
    },
    {
      field: '_id',
      headerName: 'Msg_ID',
      editable: true,
      flex: 1,
    },
    {
      field: 'message',
      headerName: 'Msg_Title',
      editable: true,
      flex: 1,
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      width: 130,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Button variant="contained" color="primary" onClick={() => editNews(params._id, params.id)}>
            Edit
          </Button>
        );
      }
    },
  ];



  return (
    <div className={classes.root}>
      <Header />
      <Grid className="bodypart custom-container grid-row">
        <Grid item lg={2} xs={2} className="xs_none tl-hide">
          <List component="nav" className="side_list" aria-label="secondary mailbox folders">
            <ListItemLink href="#simple-list" className="active">
              <ListItemText primary="Sports" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="In-Play" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="Cricket" />
            </ListItemLink>
          </List>
        </Grid>

        <Grid item lg={10} xs={12} className="report-section">
          <div style={{ height: 325, width: '100%' }}>
            <div className="datatable_heading">
              <span>
                Message Setting
              </span>
            </div>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <ValidatorForm

                  onSubmit={editopens ? handleUpdate : handleSubmit}
                  autoComplete="off"
                >
                  <Grid container spacing={12}>
                    <Grid item lg={12} xs={12}>

                      <TextareaAutosize
                        validators={['required']}
                        errorMessages={['this field is required']} name="message" onChange={handleInputChange} value={formData.message} style={{ width: '99%', height: '200px' }} aria-label="minimum height" minRows={3} placeholder="Message" />

                    </Grid>


                    <Grid item lg={6} xs={6}>
                      <Button variant="contained" type="submit" color="primary">Save Chages</Button>&nbsp;
                      {/* <Button variant="contained" color="secondary" onClick={modalClose}>Close</Button> */}
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </Grid>
            </Grid>
          </div>

          <div className="datatable_heading">

            Message List

            {/* <Grid item xs={4} sm={2}>
                  <FormControl className={classes.formControl}>
                    <NativeSelect
                      value={state.age}
                      onChange={handleChange}
                      name="age"
                      className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'age' }}
                    >
                      <option value={10}>Select Selection</option>
                      <option value={25}>Lock Message</option>
                      <option value={50}>Open Message</option>
                      <option value={100}>Delete Message</option>

                    </NativeSelect>
                  </FormControl>
                </Grid>

                <Grid item xs={4} sm={3}>
                  <Button size="small" variant="contained" color="primary">Action</Button>
                </Grid> */}


          </div>

          <div style={{ display: 'flex', height: '100vh' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                // components={{
                //   Toolbar: GridToolbar,
                // }}
                // filterModel={{
                //   items: [{ columnField: 'commodity', operatorValue: 'contains', value: 'rice' }],
                // }}
                rows={News}
                columns={columns}
                pageSize={5}
                disableSelectionOnClick
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Footer />

    </div >
  );
}