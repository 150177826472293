import React, { useEffect } from "react";
import "./admin.css";
import "./adminResponsive.css";
import Header from "./Header";
import MiniGames from "./MiniGames";
import Footer from "./Footer";

import {
  makeStyles,
  Paper,
  Grid,
  Tab,
  AppBar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

import open_teen from "../assets/open-teen.jpg";
import Cards_Live from "../assets/32-Cards-Live.jpg";
import live_teenpatti from "../assets/live-teenpatti.jpg";
import updown from "../assets/7updown.png";
import ander_baher from "../assets/ander-baher.jpg";
import dragon_tiger from "../assets/dragon-tiger.jpg";
import InPlay from "../assets/in-play.png";
import AddUser from "../assets/add-user.png";
import Home4 from "../assets/home-4.png";
import Timer from "../assets/timer.png";
import Logout from "../assets/logout.png";
import cricket from "../assets/sports-images/cric.jpg";
import soccer from "../assets/sports-images/soccer.jpg";
import tennis from "../assets/sports-images/tennis.jpg";
import virt from "../assets/sports-images/virt.jpg";
import ezugi from "../assets/sports-images/ezugi.jpg";

import CardCasino from "../assets/open-teen.jpg";
import amarAkbarAnothny from "../assets/amar_akbar_anthony.jpg";
import seven from "../assets/seven.jpg";
import eight from "../assets/eight.jpg";
import nine from "../assets/nine.png";
import ten from "../assets/ten.jpg";
import { useHistory } from "react-router-dom";
import { SportsSoccerTwoTone } from "@material-ui/icons";

import indian_casino from "../assets/sports-images/indian_casino.jpg";
import indian_casino_2 from "../assets/sports-images/indian_casino_2.jpg";
import slot_game from "../assets/sports-images/slot_game.jpg";
import axios from "axios";
import Appconfig from "../config/config";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function createData(
  event_id,
  name,
  value,
  action,
  calories,
  fat,
  carbs,
  protein
) {
  return { event_id, name, value, action, calories, fat, carbs, protein };
}

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const Casino = () => {
  const classes = useStyles();


  useEffect(() => { window.scroll(0,0)

  }, []);


  // const [nav, setNav] = React.useState(2);
  const [value, setValue] = React.useState(isMobile ? "0" : "1");

  const handleChange = (event, newValue) => {
    
    setValue(newValue);
  };


  const rows = [];

  const history = useHistory();
  const logout = () => {
    sessionStorage.clear();
    sessionStorage.setItem("loggedIn", false);
    history.push("/");
  };
  return (
    <div>
      <Header />
      <Grid className="bodypart custom-container grid-row">
        <Grid item lg={2} xs={2} className="xs_none tl-hide">
          <List
            component="nav"
            className="side_list"
            aria-label="secondary mailbox folders"
          >
            <ListItemLink href="#simple-list" className="active">
              <ListItemText primary="Sports" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="In-Play" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="Cricket" />
            </ListItemLink>
          </List>
        </Grid>
        <Grid item lg={5} xs={12} className="mini-games-grid">
          <TabContext value={value}>
            <AppBar position="static" className="box_none">
              <div className="mobile-card-tabs">
            
                  <>
                   
                    <div className="sport-heading-container">
                      <span className="sport-heading">Our Live Casino</span>
                    </div>
                    <TabList
                      onChange={handleChange}
                      className="maintabs mobile_tab"
                      variant="fullWidth"
                      aria-label="simple tabs example"
                    >
                      <Tab
                        className="main_tab"
                        icon={<MiniGames event="Ezugi" image={ezugi} />}
                        onClick={()=>history.push('/casino-list')}
                      />
                      <Tab
                        className="main_tab"
                        icon={<MiniGames event="Slot Game" image={slot_game} />}
                        onClick={()=>history.push('/casino-list')}
                      />
                      <Tab
                        className="main_tab"
                        icon={
                          <MiniGames
                            event="Indian Casino"
                            image={indian_casino}
                          />
                        }
                        onClick={()=>history.push('/casino-list')}
                      />
                      <Tab
                        className="main_tab"
                        icon={
                          <MiniGames
                            event="Indian Casino 2"
                            image={indian_casino_2}
                          />
                        }
                        onClick={()=>history.push('/casino-list')}
                      />
                    </TabList>
                  </>
               
              </div>
            </AppBar>

          </TabContext>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Casino;
