import React from "react";
import "./admin.css";
import "./adminResponsive.css";
import Header from "./Header";
import MiniGames from "./MiniGames";
import Footer from "./Footer";

import {
  makeStyles,
  Paper,
  Grid,
  Tab,
  AppBar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

import open_teen from "../assets/open-teen.jpg";
import Cards_Live from "../assets/32-Cards-Live.jpg";
import live_teenpatti from "../assets/live-teenpatti.jpg";
import updown from "../assets/7updown.png";
import ander_baher from "../assets/ander-baher.jpg";
import dragon_tiger from "../assets/dragon-tiger.jpg";
import InPlay from "../assets/in-play.png";
import AddUser from "../assets/add-user.png";
import Home4 from "../assets/home-4.png";
import Timer from "../assets/timer.png";
import Logout from "../assets/logout.png";
import cricket from "../assets/sports-images/cric.jpg";
import soccer from "../assets/sports-images/soccer.jpg";
import tennis from "../assets/sports-images/tennis.jpg";
import virt from "../assets/sports-images/virt.jpg";
import ezugi from "../assets/sports-images/ezugi.jpg";

import CardCasino from "../assets/open-teen.jpg";
import amarAkbarAnothny from "../assets/amar_akbar_anthony.jpg";
import seven from "../assets/seven.jpg";
import eight from "../assets/eight.jpg";
import nine from "../assets/nine.png";
import ten from "../assets/ten.jpg";
import { useHistory } from "react-router-dom";
import { SportsSoccerTwoTone } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function createData(name, value, action, calories, fat, carbs, protein) {
  return { name, value, action, calories, fat, carbs, protein };
}

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const Sports = () => {
  const classes = useStyles();

  // const [nav, setNav] = React.useState(2);
  const [value, setValue] = React.useState(isMobile ? "0" : "1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const rows = [
    createData("T20 Blast", "09 Jun 2021 17:30:0", "Inplay", 159, 6.0, 24, 4.0),
    createData(
      "Northern Knights v Munster Reds",
      "08 Jun 2021 17:30:0",
      "Inplay",
      237,
      9.0,
      37,
      4.3
    ),
    createData(
      "England Women v India Women",
      "07 Jun 2021 17:30:0",
      "Start in",
      262,
      16.0,
      24,
      6.0
    ),
    createData(
      "Birmingham Bears v Yorkshire",
      "10 Jun 2021 17:30:0",
      "Start in",
      305,
      3.7,
      67,
      4.3
    ),
    createData(
      "Northamptonshire v Durham",
      "19 Jun 2021 17:30:0",
      "Start in",
      356,
      16.0,
      49,
      3.9
    ),
  ];

  const history = useHistory();
  const logout = () => {
    sessionStorage.clear();
    sessionStorage.setItem("loggedIn", false);
    history.push("/");
  };
  return (
    <div>
      <Header />
      <Grid className="bodypart custom-container grid-row">
        <Grid item lg={2} xs={2} className="xs_none tl-hide">
          <List
            component="nav"
            className="side_list"
            aria-label="secondary mailbox folders"
          >
            <ListItemLink href="#simple-list" className="active">
              <ListItemText primary="Sports" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="In-Play" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="Cricket" />
            </ListItemLink>
          </List>
        </Grid>
        <Grid item lg={5} xs={12} className="mini-games-grid">
          <TabContext value={value}>
            <AppBar position="static" className="box_none">
              <div className="mobile-card-tabs">
                {value === "0" ? (
                  <>
                    <div className="sport-heading-container">
                      <span className="sport-heading">Real Sports Game</span>
                    </div>
                    <TabList
                      onChange={handleChange}
                      className="maintabs mobile_tab"
                      variant="fullWidth"
                      aria-label="simple tabs example"
                    >
                      <Tab
                        className="main_tab"
                        icon={<MiniGames event="Cricket" image={cricket} />}
                        value="1"
                      />
                      <Tab
                        className="main_tab"
                        icon={<MiniGames event="Tennis" image={tennis} />}
                        value="2"
                      />
                      <Tab
                        className="main_tab"
                        icon={<MiniGames event="Soccer" image={soccer} />}
                        value="3"
                      />
                      <Tab
                        className="main_tab"
                        icon={<MiniGames event="Virtual Games" image={virt} />}
                        value="4"
                      />
                    </TabList>
                  </>
                ) : (
                  ""
                )}
              </div>
            </AppBar>
            <TabPanel value="0" className="multitable"></TabPanel>
            <TabPanel value="1" className="multitable">
              <BrowserView>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className="tbl_head">
                        <TableCell>Cricket</TableCell>
                        <TableCell align="right" colSpan="2">
                          1
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          x
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          2
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tbl_body">
                      {rows.map((row) => (
                        <TableRow key={row.name} className="odd_even_clr">
                          <TableCell className="table_first_row">
                            <div className="text_left">
                              <p>{row.name}</p>
                              <p>
                                <strong>{row.value}</strong>
                              </p>
                            </div>
                            <div>
                              <p
                                className={
                                  row.action === "Inplay"
                                    ? "inplay"
                                    : "going-to-play"
                                }
                              >
                                {row.action}
                              </p>
                              {row.action !== "Inplay" ? (
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  04:12:30
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.calories}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.fat}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.carbs}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </BrowserView>
              <MobileView>
                {rows.map((row) => (
                  <div className="xs_tbl_block ">
                    <TableContainer component={Paper}>
                      <Link  to={"/event-detail/" + row.event_id}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow className="tbl_head">
                              <TableCell align="right" colSpan="1">
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  {row.action}
                                </p>
                                {row.action !== "Inplay" ? (
                                  <p
                                    className={
                                      row.action === "Inplay"
                                        ? "inplay"
                                        : "going-to-play"
                                    }
                                  >
                                    04:12:30
                                  </p>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell align="right" colSpan="4">
                                <div className="text_left">
                                  <p className="match-name">{row.name}</p>
                                  <p className="match-time">
                                    <strong>{row.value}</strong>
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell align="right" colSpan="1">
                                <div className="text_right">
                                  {" "}
                                  <span>F</span> <span>F1</span> <span>BM</span>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="tbl_body">
                            <TableRow key={row.name} className="xs_tbl_first">
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                1
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                X
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                2
                              </TableCell>
                            </TableRow>
                            <TableRow key={row.value} className="odd_even_clr">
                              <TableCell align="right">
                                <p>{row.calories}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.fat}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.carbs}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Link>
                    </TableContainer>
                  </div>
                ))}
              </MobileView>
            </TabPanel>
            <TabPanel value="2">
              <BrowserView>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className="tbl_head">
                        <TableCell>Cricket</TableCell>
                        <TableCell align="right" colSpan="2">
                          1
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          x
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          2
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tbl_body">
                      {rows.map((row) => (
                        <TableRow key={row.name} className="odd_even_clr">
                          <TableCell className="table_first_row">
                            <div className="text_left">
                              <p>{row.name}</p>
                              <p>
                                <strong>{row.value}</strong>
                              </p>
                            </div>
                            <div>
                              <p
                                className={
                                  row.action === "Inplay"
                                    ? "inplay"
                                    : "going-to-play"
                                }
                              >
                                {row.action}
                              </p>
                              {row.action !== "Inplay" ? (
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  04:12:30
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.calories}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.fat}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.carbs}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </BrowserView>
              <MobileView>
                {rows.map((row) => (
                  <div className="xs_tbl_block">
                    <TableContainer component={Paper}>
                      <Link  to={"/event-detail/" + row.event_id}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow className="tbl_head">
                              <TableCell align="right" colSpan="1">
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  {row.action}
                                </p>
                                {row.action !== "Inplay" ? (
                                  <p
                                    className={
                                      row.action === "Inplay"
                                        ? "inplay"
                                        : "going-to-play"
                                    }
                                  >
                                    04:12:30
                                  </p>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell align="right" colSpan="4">
                                <div className="text_left">
                                  <p>{row.name}</p>
                                  <p>
                                    <strong>{row.value}</strong>
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell align="right" colSpan="1">
                                F F1 BM
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="tbl_body">
                            <TableRow key={row.name} className="xs_tbl_first">
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                1
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                X
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                2
                              </TableCell>
                            </TableRow>
                            <TableRow key={row.value} className="odd_even_clr">
                              <TableCell align="right">
                                <p>{row.calories}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.fat}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.carbs}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Link>
                    </TableContainer>
                  </div>
                ))}
              </MobileView>
            </TabPanel>
            <TabPanel value="3">
              <BrowserView>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className="tbl_head">
                        <TableCell>Cricket</TableCell>
                        <TableCell align="right" colSpan="2">
                          1
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          x
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          2
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tbl_body">
                      {rows.map((row) => (
                        <TableRow key={row.name} className="odd_even_clr">
                          <TableCell className="table_first_row">
                            <div className="text_left">
                              <p>{row.name}</p>
                              <p>
                                <strong>{row.value}</strong>
                              </p>
                            </div>
                            <div>
                              <p
                                className={
                                  row.action === "Inplay"
                                    ? "inplay"
                                    : "going-to-play"
                                }
                              >
                                {row.action}
                              </p>
                              {row.action !== "Inplay" ? (
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  04:12:30
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.calories}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.fat}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.carbs}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </BrowserView>
              <MobileView>
                {rows.map((row) => (
                  <div className="xs_tbl_block">
                    <TableContainer component={Paper}>
                      <Link  to={"/event-detail/" + row.event_id}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow className="tbl_head">
                              <TableCell align="right" colSpan="1">
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  {row.action}
                                </p>
                                {row.action !== "Inplay" ? (
                                  <p
                                    className={
                                      row.action === "Inplay"
                                        ? "inplay"
                                        : "going-to-play"
                                    }
                                  >
                                    04:12:30
                                  </p>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell align="right" colSpan="4">
                                <div className="text_left">
                                  <p>{row.name}</p>
                                  <p>
                                    <strong>{row.value}</strong>
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell align="right" colSpan="1">
                                F F1 BM
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="tbl_body">
                            <TableRow key={row.name} className="xs_tbl_first">
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                1
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                X
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                2
                              </TableCell>
                            </TableRow>
                            <TableRow key={row.value} className="odd_even_clr">
                              <TableCell align="right">
                                <p>{row.calories}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.fat}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.carbs}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Link>
                    </TableContainer>
                  </div>
                ))}
              </MobileView>
            </TabPanel>
            <TabPanel value="4">
              <BrowserView>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className="tbl_head">
                        <TableCell>Cricket</TableCell>
                        <TableCell align="right" colSpan="2">
                          1
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          x
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          2
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tbl_body">
                      {rows.map((row) => (
                        <TableRow key={row.name} className="odd_even_clr">
                          <TableCell className="table_first_row">
                            <div className="text_left">
                              <p>{row.name}</p>
                              <p>
                                <strong>{row.value}</strong>
                              </p>
                            </div>
                            <div>
                              <p
                                className={
                                  row.action === "Inplay"
                                    ? "inplay"
                                    : "going-to-play"
                                }
                              >
                                {row.action}
                              </p>
                              {row.action !== "Inplay" ? (
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  04:12:30
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.calories}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.fat}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.carbs}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </BrowserView>
              <MobileView>
                {rows.map((row) => (
                  <div className="xs_tbl_block">
                    <TableContainer component={Paper}>
                      <Link  to={"/event-detail/" + row.event_id}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow className="tbl_head">
                              <TableCell align="right" colSpan="1">
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  {row.action}
                                </p>
                                {row.action !== "Inplay" ? (
                                  <p
                                    className={
                                      row.action === "Inplay"
                                        ? "inplay"
                                        : "going-to-play"
                                    }
                                  >
                                    04:12:30
                                  </p>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell align="right" colSpan="4">
                                <div className="text_left">
                                  <p>{row.name}</p>
                                  <p>
                                    <strong>{row.value}</strong>
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell align="right" colSpan="1">
                                F F1 BM
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="tbl_body">
                            <TableRow key={row.name} className="xs_tbl_first">
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                1
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                X
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                2
                              </TableCell>
                            </TableRow>
                            <TableRow key={row.value} className="odd_even_clr">
                              <TableCell align="right">
                                <p>{row.calories}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.fat}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.carbs}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Link>
                    </TableContainer>
                  </div>
                ))}
              </MobileView>
            </TabPanel>
            <TabPanel value="5">
              <BrowserView>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className="tbl_head">
                        <TableCell>Cricket</TableCell>
                        <TableCell align="right" colSpan="2">
                          1
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          x
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          2
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tbl_body">
                      {rows.map((row) => (
                        <TableRow key={row.name} className="odd_even_clr">
                          <TableCell className="table_first_row">
                            <div className="text_left">
                              <p>{row.name}</p>
                              <p>
                                <strong>{row.value}</strong>
                              </p>
                            </div>
                            <div>
                              <p
                                className={
                                  row.action === "Inplay"
                                    ? "inplay"
                                    : "going-to-play"
                                }
                              >
                                {row.action}
                              </p>
                              {row.action !== "Inplay" ? (
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  04:12:30
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.calories}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.fat}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.carbs}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </BrowserView>
              <MobileView>
                {rows.map((row) => (
                  <div className="xs_tbl_block">
                    <TableContainer component={Paper}>
                      <Link  to={"/event-detail/" + row.event_id}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow className="tbl_head">
                              <TableCell align="right" colSpan="1">
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  {row.action}
                                </p>
                                {row.action !== "Inplay" ? (
                                  <p
                                    className={
                                      row.action === "Inplay"
                                        ? "inplay"
                                        : "going-to-play"
                                    }
                                  >
                                    04:12:30
                                  </p>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell align="right" colSpan="4">
                                <div className="text_left">
                                  <p>{row.name}</p>
                                  <p>
                                    <strong>{row.value}</strong>
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell align="right" colSpan="1">
                                F F1 BM
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="tbl_body">
                            <TableRow key={row.name} className="xs_tbl_first">
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                1
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                X
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                2
                              </TableCell>
                            </TableRow>
                            <TableRow key={row.value} className="odd_even_clr">
                              <TableCell align="right">
                                <p>{row.calories}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.fat}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.carbs}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Link>
                    </TableContainer>
                  </div>
                ))}
              </MobileView>
            </TabPanel>
            <TabPanel value="6">
              <BrowserView>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className="tbl_head">
                        <TableCell>Cricket</TableCell>
                        <TableCell align="right" colSpan="2">
                          1
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          x
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          2
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tbl_body">
                      {rows.map((row) => (
                        <TableRow key={row.name} className="odd_even_clr">
                          <TableCell className="table_first_row">
                            <div className="text_left">
                              <p>{row.name}</p>
                              <p>
                                <strong>{row.value}</strong>
                              </p>
                            </div>
                            <div>
                              <p
                                className={
                                  row.action === "Inplay"
                                    ? "inplay"
                                    : "going-to-play"
                                }
                              >
                                {row.action}
                              </p>
                              {row.action !== "Inplay" ? (
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  04:12:30
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.calories}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.fat}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.carbs}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </BrowserView>
              <MobileView>
                {rows.map((row) => (
                  <div className="xs_tbl_block">
                    <TableContainer component={Paper}>
                      <Link  to={"/event-detail/" + row.event_id}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow className="tbl_head">
                              <TableCell align="right" colSpan="1">
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  {row.action}
                                </p>
                                {row.action !== "Inplay" ? (
                                  <p
                                    className={
                                      row.action === "Inplay"
                                        ? "inplay"
                                        : "going-to-play"
                                    }
                                  >
                                    04:12:30
                                  </p>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell align="right" colSpan="4">
                                <div className="text_left">
                                  <p>{row.name}</p>
                                  <p>
                                    <strong>{row.value}</strong>
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell align="right" colSpan="1">
                                F F1 BM
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="tbl_body">
                            <TableRow key={row.name} className="xs_tbl_first">
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                1
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                X
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                2
                              </TableCell>
                            </TableRow>
                            <TableRow key={row.value} className="odd_even_clr">
                              <TableCell align="right">
                                <p>{row.calories}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.fat}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.carbs}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Link>
                    </TableContainer>
                  </div>
                ))}
              </MobileView>
            </TabPanel>
            <TabPanel value="7">
              <BrowserView>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className="tbl_head">
                        <TableCell>Cricket</TableCell>
                        <TableCell align="right" colSpan="2">
                          1
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          x
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          2
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tbl_body">
                      {rows.map((row) => (
                        <TableRow key={row.name} className="odd_even_clr">
                          <TableCell className="table_first_row">
                            <div className="text_left">
                              <p>{row.name}</p>
                              <p>
                                <strong>{row.value}</strong>
                              </p>
                            </div>
                            <div>
                              <p
                                className={
                                  row.action === "Inplay"
                                    ? "inplay"
                                    : "going-to-play"
                                }
                              >
                                {row.action}
                              </p>
                              {row.action !== "Inplay" ? (
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  04:12:30
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.calories}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.fat}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.carbs}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </BrowserView>
              <MobileView>
                {rows.map((row) => (
                  <div className="xs_tbl_block">
                    <TableContainer component={Paper}>
                      <Link  to={"/event-detail/" + row.event_id}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow className="tbl_head">
                              <TableCell align="right" colSpan="1">
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  {row.action}
                                </p>
                                {row.action !== "Inplay" ? (
                                  <p
                                    className={
                                      row.action === "Inplay"
                                        ? "inplay"
                                        : "going-to-play"
                                    }
                                  >
                                    04:12:30
                                  </p>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell align="right" colSpan="4">
                                <div className="text_left">
                                  <p>{row.name}</p>
                                  <p>
                                    <strong>{row.value}</strong>
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell align="right" colSpan="1">
                                F F1 BM
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="tbl_body">
                            <TableRow key={row.name} className="xs_tbl_first">
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                1
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                X
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                2
                              </TableCell>
                            </TableRow>
                            <TableRow key={row.value} className="odd_even_clr">
                              <TableCell align="right">
                                <p>{row.calories}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.fat}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.carbs}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Link>
                    </TableContainer>
                  </div>
                ))}
              </MobileView>
            </TabPanel>
            <TabPanel value="8">
              <BrowserView>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className="tbl_head">
                        <TableCell>Cricket</TableCell>
                        <TableCell align="right" colSpan="2">
                          1
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          x
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          2
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tbl_body">
                      {rows.map((row) => (
                        <TableRow key={row.name} className="odd_even_clr">
                          <TableCell className="table_first_row">
                            <div className="text_left">
                              <p>{row.name}</p>
                              <p>
                                <strong>{row.value}</strong>
                              </p>
                            </div>
                            <div>
                              <p
                                className={
                                  row.action === "Inplay"
                                    ? "inplay"
                                    : "going-to-play"
                                }
                              >
                                {row.action}
                              </p>
                              {row.action !== "Inplay" ? (
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  04:12:30
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.calories}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.fat}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.carbs}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </BrowserView>
              <MobileView>
                {rows.map((row) => (
                  <div className="xs_tbl_block">
                    <TableContainer component={Paper}>
                      <Link  to={"/event-detail/" + row.event_id}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow className="tbl_head">
                              <TableCell align="right" colSpan="1">
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  {row.action}
                                </p>
                                {row.action !== "Inplay" ? (
                                  <p
                                    className={
                                      row.action === "Inplay"
                                        ? "inplay"
                                        : "going-to-play"
                                    }
                                  >
                                    04:12:30
                                  </p>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell align="right" colSpan="4">
                                <div className="text_left">
                                  <p>{row.name}</p>
                                  <p>
                                    <strong>{row.value}</strong>
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell align="right" colSpan="1">
                                F F1 BM
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="tbl_body">
                            <TableRow key={row.name} className="xs_tbl_first">
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                1
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                X
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                2
                              </TableCell>
                            </TableRow>
                            <TableRow key={row.value} className="odd_even_clr">
                              <TableCell align="right">
                                <p>{row.calories}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.fat}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.carbs}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Link>
                    </TableContainer>
                  </div>
                ))}
              </MobileView>
            </TabPanel>
            <TabPanel value="9">
              <BrowserView>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className="tbl_head">
                        <TableCell>Cricket</TableCell>
                        <TableCell align="right" colSpan="2">
                          1
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          x
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          2
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tbl_body">
                      {rows.map((row) => (
                        <TableRow key={row.name} className="odd_even_clr">
                          <TableCell className="table_first_row">
                            <div className="text_left">
                              <p>{row.name}</p>
                              <p>
                                <strong>{row.value}</strong>
                              </p>
                            </div>
                            <div>
                              <p
                                className={
                                  row.action === "Inplay"
                                    ? "inplay"
                                    : "going-to-play"
                                }
                              >
                                {row.action}
                              </p>
                              {row.action !== "Inplay" ? (
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  04:12:30
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.calories}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.fat}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.carbs}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </BrowserView>
              <MobileView>
                {rows.map((row) => (
                  <div className="xs_tbl_block">
                    <TableContainer component={Paper}>
                      <Link  to={"/event-detail/" + row.event_id}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow className="tbl_head">
                              <TableCell align="right" colSpan="1">
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  {row.action}
                                </p>
                                {row.action !== "Inplay" ? (
                                  <p
                                    className={
                                      row.action === "Inplay"
                                        ? "inplay"
                                        : "going-to-play"
                                    }
                                  >
                                    04:12:30
                                  </p>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell align="right" colSpan="4">
                                <div className="text_left">
                                  <p>{row.name}</p>
                                  <p>
                                    <strong>{row.value}</strong>
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell align="right" colSpan="1">
                                F F1 BM
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="tbl_body">
                            <TableRow key={row.name} className="xs_tbl_first">
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                1
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                X
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                2
                              </TableCell>
                            </TableRow>
                            <TableRow key={row.value} className="odd_even_clr">
                              <TableCell align="right">
                                <p>{row.calories}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.fat}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.carbs}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Link>
                    </TableContainer>
                  </div>
                ))}
              </MobileView>
            </TabPanel>
            <TabPanel value="10">
              <BrowserView>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow className="tbl_head">
                        <TableCell>Cricket</TableCell>
                        <TableCell align="right" colSpan="2">
                          1
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          x
                        </TableCell>
                        <TableCell align="right" colSpan="2">
                          2
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="tbl_body">
                      {rows.map((row) => (
                        <TableRow key={row.name} className="odd_even_clr">
                          <TableCell className="table_first_row">
                            <div className="text_left">
                              <p>{row.name}</p>
                              <p>
                                <strong>{row.value}</strong>
                              </p>
                            </div>
                            <div>
                              <p
                                className={
                                  row.action === "Inplay"
                                    ? "inplay"
                                    : "going-to-play"
                                }
                              >
                                {row.action}
                              </p>
                              {row.action !== "Inplay" ? (
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  04:12:30
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.calories}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.fat}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.carbs}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                          <TableCell align="right">
                            <div className="td-cell">{row.protein}</div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </BrowserView>
              <MobileView>
                {rows.map((row) => (
                  <div className="xs_tbl_block">
                    <TableContainer component={Paper}>
                      <Link  to={"/event-detail/" + row.event_id}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow className="tbl_head">
                              <TableCell align="right" colSpan="1">
                                <p
                                  className={
                                    row.action === "Inplay"
                                      ? "inplay"
                                      : "going-to-play"
                                  }
                                >
                                  {row.action}
                                </p>
                                {row.action !== "Inplay" ? (
                                  <p
                                    className={
                                      row.action === "Inplay"
                                        ? "inplay"
                                        : "going-to-play"
                                    }
                                  >
                                    04:12:30
                                  </p>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                              <TableCell align="right" colSpan="4">
                                <div className="text_left">
                                  <p>{row.name}</p>
                                  <p>
                                    <strong>{row.value}</strong>
                                  </p>
                                </div>
                              </TableCell>
                              <TableCell align="right" colSpan="1">
                                F F1 BM
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="tbl_body">
                            <TableRow key={row.name} className="xs_tbl_first">
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                1
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                X
                              </TableCell>
                              <TableCell
                                align="right"
                                colSpan="2"
                                className="text_center"
                              >
                                2
                              </TableCell>
                            </TableRow>
                            <TableRow key={row.value} className="odd_even_clr">
                              <TableCell align="right">
                                <p>{row.calories}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.fat}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.carbs}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                              <TableCell align="right">
                                <p>{row.protein}</p>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Link>
                    </TableContainer>
                  </div>
                ))}
              </MobileView>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Sports;
