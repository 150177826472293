import React, { useState, useEffect } from "react";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Grid,
  List,
  ListItem,
  Switch,
  TextareaAutosize,
  Chip,
  ListItemText,
  FormControl,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Header from "./Header";
import Footer from "./Footer";
// import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  MenuItem,
  InputLabel,
  Select,
  Menu,
  Checkbox,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Dialog,
  TableRow,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Button,
  Modal,
  Typography,
  Fade,
  Backdrop,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { ToastContainer, toast } from "react-toastify";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Appconfig from "../config/config";

import axios from "axios";
import moment from "moment";
import { spacing } from "@material-ui/system";
import IconButton from "@material-ui/core/IconButton";

import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import "./admin.css";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function WebSetting() {
  const classes = useStyles();

  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  const columns = [

    { field: 'id', width: 180, headerName: 'S.No.', width: 100 },
    {
      field: 'domain',
      headerName: 'Domain',
      editable: true,
      width: 180,
    },
    {
      field: 'site_title',
      headerName: 'Site Title',
      editable: true,
      width: 180,
    },
    {
      field: 'Logo',
      headerName: 'Logo',
      editable: true,
      width: 180,
      renderCell: (rowData) => {
        return (
          <img src="https://thumbs.dreamstime.com/b/demo-icon-sign-isolated-white-background-simple-vector-logo-demo-icon-sign-isolated-white-background-177413297.jpg" width="20%" alt="no-img" />
        );
      },
    },
    {
      field: 'background',
      headerName: 'Background',
      editable: true,
      width: 180,
      renderCell: (rowData) => {
        return (
          <img src="https://thumbs.dreamstime.com/b/demo-icon-sign-isolated-white-background-simple-vector-logo-demo-icon-sign-isolated-white-background-177413297.jpg" width="20%" alt="no-img" />
        );
      },
    },
    {
      field: 'close_website',
      headerName: 'Close Website',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (

          <Switch
            // checked={rowData.row.is_blocked === "Yes" ? false : true}
            // onChange={() =>
            //   blockUnblockEvent(rowData.row.event_id, rowData.row.event_type)
            // }
            color="secondary"
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />


        );
      },
    },
    {
      field: 'Delete',
      headerName: 'Delete',
      editable: false,
      width: 180,
      renderCell: (rowData) => {
        return (

          <IconButton aria-label="delete" onClick={() =>console.log('click')}>
            <DeleteIcon color="secondary" fontSize="medium" />
          </IconButton>


        );
      },
    },
  ];

  const rows = [
    { id: 1, domain: 'sghkd.com', site_title: 'sghkd', logo: '1000.00', background: '0.00', close_website: '1000' },
    { id: 2, domain: 'sghkd.com', site_title: 'sghkd', logo: '1000.00', background: '0.00', close_website: '1000' },
    { id: 3, domain: 'sghkd.com', site_title: 'sghkd', logo: '1000.00', background: '0.00', close_website: '1000' },
    { id: 4, domain: 'sghkd.com', site_title: 'sghkd', logo: '1000.00', background: '0.00', close_website: '1000' },
    { id: 5, domain: 'sghkd.com', site_title: 'sghkd', logo: '1000.00', background: '0.00', close_website: '1000' },

  ];

  return (
    <div className={classes.root}>
      <Header />
      <Grid className="bodypart custom-container grid-row">
        <Grid item lg={2} xs={2} className="xs_none tl-hide">
          <List component="nav" className="side_list" aria-label="secondary mailbox folders">
            <ListItemLink href="#simple-list" className="active">
              <ListItemText primary="Sports" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="In-Play" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="Cricket" />
            </ListItemLink>
          </List>
        </Grid>

        <Grid item lg={10} xs={12} className="report-section">
          <div style={{ height: 170, width: '100%' }}>
            <div className="datatable_heading" style={{ marginBottom: 15 }}>
              <span>
                Website Setting
              </span>
            </div>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <ValidatorForm

                  autoComplete="off"
                >
                  <Grid container spacing={12} >
                    <Grid item lg={6} xs={6}>

                      <TextValidator
                        variant="outlined"
                        size="small"
                        validators={['required']}
                        errorMessages={['this field is required']} label="Domain" name="domain" style={{ width: '99%', height: '70px' }} aria-label="minimum height" minRows={3} />

                    </Grid>
                    <Grid item lg={6} xs={6}>

                      <TextValidator
                        size="small"
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['this field is required']} label="Site Title Data" name="site_title_data" style={{ width: '99%', height: '70px' }} aria-label="minimum height" />

                    </Grid>


                    <Grid item lg={6} xs={6}>
                      <button type="submit" className="blue_button">Save Chages</button>&nbsp;
                      {/* <Button variant="contained" color="secondary" onClick={modalClose}>Close</Button> */}
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </Grid>
            </Grid>
          </div>
        

            <div className="datatable_sort">
              <div className="show_data">
                <span>Show</span>
                <span>
                  <FormControl className={classes.formControl}>
                    <NativeSelect
                      value={state.age}
                      onChange={handleChange}
                      name="age"
                      className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'age' }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </NativeSelect>
                  </FormControl>
                </span>
                <span>entries</span>
              </div>
              <div className="search_data">
                <span>Search : </span>
                <span>
                  <form className={classes.root} noValidate autoComplete="off">
                    <TextField id="standard-basic" />
                  </form>
                </span>
              </div>
            </div>
            <div style={{ display: 'flex', height: '100vh' }}>
            <div style={{ flexGrow: 1 }}>
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              filterModel={{
                items: [{ columnField: 'commodity', operatorValue: 'contains', value: 'rice' }],
              }}
              rows={rows}
              columns={columns}
              pageSize={5}
              disableSelectionOnClick
            />
          </div>
          </div>
        </Grid>
      </Grid>
      <Footer />

    </div >
  );
}