import React from 'react';
import { makeStyles, Grid, List, ListItem, ListItemText, FormControl, TableCell, Chip, TableRow, TableBody, TableContainer, Table, TableHead, NativeSelect, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from './Header'
import Footer from './Footer'
import Appconfig from '../config/config';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

import './admin.css';
import { Link, useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function Settled() {
    const classes = useStyles();
    const history = useHistory();
    const [eventList, SetEventList] = React.useState([]);
    const [fetchComplete, setFetchComplete] = React.useState(false);

    React.useEffect(() => {
        window.scroll(0, 0);
        getSettlementEventTypes();
    }, []);
    const [state, setState] = React.useState({
        age: '',
        name: 'hai',
    });

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }

    function getSettlementEventTypes() {

        setFetchComplete(true);
        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}settlement/getSettlementEventTypes`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({ "is_settled": true })
        };

        axios(config)
            .then(function (response) {
                //console.log(JSON.stringify(response.data));
                SetEventList(response.data.resultData);

            })
            .catch(function (error) {
                console.log(error);
            }).then(() => { setFetchComplete(false); });
    }

    return (
        <div className={classes.root}>
            <Header />
            <Grid className="bodypart custom-container grid-row">
                <Grid item lg={2} xs={2} className="xs_none tl-hide">
                    <List component="nav" className="side_list" aria-label="secondary mailbox folders">
                        <ListItemLink href="#simple-list" className="active">
                            <ListItemText primary="Sports" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="In-Play" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="Cricket" />
                        </ListItemLink>
                    </List>
                </Grid>

                <Grid item lg={10} xs={12} className="Settlement-section">
                    <div style={{ width: '100%' }}>
                        <div className="datatable_heading">
                            Settlement sport
                        </div>


                    </div>
                    {/* <TableContainer style={{padding:'5px'}}> */}
                    {fetchComplete ? <div className="set-loader"><CircularProgress /> </div> :
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>

                                    <TableCell component="th" align="left">S NO.</TableCell>
                                    <TableCell align="left" component="th" >Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    eventList.map((row, index) => (
                                        [1, 2, 4].includes(row.event_type) && <TableRow key={index}>

                                            <TableCell align="left" style={{ fontSize: 17 }}>{index + 1}</TableCell>
                                            <TableCell align="left">
                                                <div style={{ display: 'flex' }}>
                                                    <div ><Link onClick={() => history.push(`settled/${row.event_type}`)} style={{ fontSize: 17 }}>{row.name}</Link></div>
                                                    {/* {row.totalBets > 0 ? <div style={{ marginLeft: '10px' }}><Chip label={row.totalBets} size="small" style={{ backgroundColor: '#008000a1', color: 'white' }} /></div> : ''} */}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }


                            </TableBody>
                        </Table>
                    }
                    {/* </TableContainer> */}
                </Grid>
            </Grid>
            <Footer />

        </div >
    );
}