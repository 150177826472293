import React from "react";
import "./admin.css";
import "./adminResponsive.css";
import { DialogTitle, Dialog, DialogActions, DialogContentText, DialogContent, Chip, Switch, MenuItem, InputLabel, FormControlLabel, Select, Button, FormControl, TableCell, TableRow, TableBody, TableContainer, Table, TableHead, NativeSelect, TextField } from '@material-ui/core';
import InPlay from "../assets/in-play.png";
import AddUser from "../assets/add-user.png";
import Home4 from "../assets/home-4.png";
import Timer from "../assets/timer.png";
import settle from "../assets/settle.png";
import Appconfig from '../config/config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Logout from "../assets/logout.png";
import { useHistory } from "react-router";
import {
  makeStyles,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Footer = () => {
  const classes = useStyles();
  const [dialougeOpen, setDialougeOpen] = React.useState(false);
  const [nav, setNav] = React.useState(2);
  const [password, setPassword] = React.useState("");
  const history = useHistory();

  const logout = () => {
    sessionStorage.clear();
    sessionStorage.setItem("loggedIn", false);
    history.push("/");
  };

  const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
  const handleDialogeClose = () => {
    setDialougeOpen(false);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  }
  const confirmPassword = () => {
    accessBetPage();
  }
  function accessBetPage() {
    const data = JSON.stringify({
      "password": password,
    });

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}settlement/accessBetPage`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        if (response.data.result) {
          handleDialogeClose();
          history.push("/betdelete")
        }
        else {
          notify(response.data.resultMessage, 'error');
        }

      })
      .catch(function (error) {
        console.log(error);
      })
  }
  function notify(message, type) {
    toast.configure();
    if (type === 'error') {
        toast.error(message, {
            toastId: "error"
        });
    }
    else {
        toast.success(message, {
            toastId: "success"
        });
    }
}
  return (
    <>
      <div className="bottom_nav">
        <BottomNavigation
          value={nav}
          onChange={(event, newValue) => {
            setNav(newValue);
          }}
          showLabels
          className={classes.root}
        >
          {/* <BottomNavigationAction
            onClick={() => history.push("/inplay")}

            label="Inplay"
            icon={<img alt="sgBetImage" src={InPlay} />}
            className="Bmenubtn"
          /> */}

          {/* {userInfo.user_type == "User" ? (
            <BottomNavigationAction
              label="Edit Stake"
              icon={<img alt="sgBetImage" src={edit_stack} />}
              className="Bmenubtn"
              onClick={() => history.push("/chips/0/" + userInfo.user_type)}
            />
          ) : (
            <BottomNavigationAction
              label="Users"
              icon={<img alt="sgBetImage" src={AddUser} />}
              className="Bmenubtn"
              onClick={() => history.push("/userlist/0/user")}
            />
          )} */}
          {/* 
          <BottomNavigationAction
            label="HOME"
            icon={<img alt="sgBetImage" src={Home4} />}
            className="Bmenubtn"
            onClick={() => {
              history.push("/dashboard")
            
            }}

          /> */}
          <BottomNavigationAction
            label="Open Bets"
            icon={<img alt="sgBetImage" src={settle} />}
            className="Bmenubtn"
            onClick={() => {
              history.push("/settlement")

            }}

          />
          <BottomNavigationAction
            label="Settled"
            icon={<img alt="sgBetImage" src={settle} />}
            className="Bmenubtn"
            onClick={() => {
              history.push("/settled")

            }}

          />
          <BottomNavigationAction
            label="Bettings"
            icon={<img alt="sgBetImage" src={Timer} />}
            className="Bmenubtn"
            onClick={() => setDialougeOpen(true)}
          />
          <BottomNavigationAction
            label="Logout"
            icon={<img alt="sgBetImage" src={Logout} />}
            className="Bmenubtn"
            onClick={logout}
          />
        </BottomNavigation>
      </div>

      <Dialog open={dialougeOpen}
        onClose={handleDialogeClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Access !</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter access password for bet page
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="betpassword"
            label="Bet Page Password"
            type="password"
            fullWidth
            onChange={handlePassword}
            value={password}
            required={true}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleDialogeClose} color="secondary" >
            Cancel
          </Button>
          <Button disabled={password.length > 0 ? false : true} variant="contained" onClick={confirmPassword} color="primary" autoFocus>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Footer;
