
import React, { useState, useEffect } from 'react';
import { Table, TableBody, MenuItem, FormControl, Link, InputLabel, Select, Menu, NativeSelect, Checkbox, TableCell, TableContainer, TableHead, Paper, Dialog, TableRow, DialogActions, DialogTitle, DialogContentText, DialogContent, Grid, Button, List, ListItemText, Modal, Typography, ListItem, Fade, Backdrop, TextField } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Appconfig from '../config/config';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import moment from 'moment';
import { spacing } from '@material-ui/system';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useParams, useHistory } from "react-router-dom";


import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import CancelIcon from '@material-ui/icons/Cancel';
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}
const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 340,
    },
    table: {
        marginTop: 10,
        margin: 10,
    },
    container: {
        // padding: 5,
        // marginTop:8
    },
    root: {
        width: '100%',
        marginTop: 140,
        margin: 10,

    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10
    },
    headerdiv: {
        // marginTop: 10,
        padding: 15,

    },
    button: {
        margin: theme.spacing(1),
    },
}));

const ChipSummary = () => {
    const params = useParams();
    const history = useHistory();
    const userId = params.user_id;
    const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
    const [opens, setOpens] = React.useState(false);

    const [summarys, setSummarys] = React.useState([]);
    const [dialougeOpen, setDialougeOpen] = React.useState(false);
    const [deleteNewsid, setDeleteNewsid] = React.useState('');
    const [editopens, setEditOpens] = React.useState(false);
    const [formEditData, setEditFormData] = useState({
        site_id: "60fc5aca67e1e3799230a6f7",
        message: '',
        is_active: "Yes",
        is_delete: "No",
        id: ''
    });
    const [newUpdated, SetIsBalanceUpdated] = React.useState(false);
    const [parentPartnerShip, setParentPartnerShip] = React.useState(Number);
    const [formData, setFormData] = useState({
        chips: "",
        remarks: "",
        type: "",
        p_l: "",
        user_id: ""
    });
    const handleUpdateInputChange = (event) => {


        setEditFormData({
            ...formEditData,
            [event.target.name]: event.target.value
        })
    }


    const editmodalClose = () => {
        setEditOpens(false);
    };
    function modalOpen(user_id, type, p_l) {
        formData.user_id = user_id
        formData.type = type
        formData.p_l = p_l
        setOpens(true);

    };


    const modalClose = () => {
        setOpens(false);
    };




    const handleInputChange = (event) => {

        if (event.target.name == "chips") {
            ValidatorForm.removeValidationRule('chipsLimit');
            ValidatorForm.addValidationRule('chipsLimit', (value) => {
                if (event.target.value > Math.abs(formData.p_l)) {
                    return false;
                }
                return true;
            });
        }
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
        console.log(formData);
    }
    function handleClickDialogeOpen() {
        setDialougeOpen(true);
    };

    const handleDialogeClose = () => {
        setDialougeOpen(false);
    };

    const classes = useStyles();

    useEffect(() => { window.scroll(0,0)
        getChipSummary();
    }, [userId]);

    function getChipSummary() {
        let id = userInfo._id;
        if (userId != 0) {
            id = userId;
        }
        var data = JSON.stringify({
            "user_id": id
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}reports/chipSummary`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setSummarys(response.data.resultData.reports);
                setParentPartnerShip(response.data.resultData.parentPartnership)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }

    const handleSubmit = (e) => {

        e.preventDefault();
        let master_id = userInfo._id;
        if (userId != 0) {
            master_id = userId;
        }
        var axios = require('axios');
        var data = JSON.stringify({
            "user_id": formData.user_id,
            "master_id": master_id,
            "type": formData.type,
            "amount": formData.chips,
            "remarks": formData.remarks
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}ledger/chipSummarySettlement`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        // console.log(data); console.log(formData);
        axios(config)
            .then(function (response) {
                getChipSummary();
                modalClose();

                if (response.data.result) {
                    notify('success', response.data.resultMessage)
                    formData["chips"] = "";
                    formData["remarks"] = "";
                }
                else {
                    notify('error', response.data.resultMessage)
                }

            })
            .catch(function (error) {
                console.log(error);
            });


    }

    return (
        <>
            <div >
                <Header isBalanceUpdated={newUpdated} />
                <Grid className="bodypart custom-container grid-row">
                    <Grid item sm={2} xs={2} className="xs_none tl-hide">
                        <List component="nav" className="side_list" aria-label="secondary mailbox folders">
                            <ListItemLink href="#simple-list" className="active">
                                <ListItemText primary="Sports" />
                            </ListItemLink>
                            <ListItemLink href="#simple-list">
                                <ListItemText primary="In-Play" />
                            </ListItemLink>
                            <ListItemLink href="#simple-list">
                                <ListItemText primary="Cricket" />
                            </ListItemLink>
                        </List>
                    </Grid>

                    <Grid item sm={10} xs={12} className="report-section">

                        <div className="datatable_heading">
                            Chip Summary of Line 365 exchange Super admin
                            <div style={{ width: "70%" }}><button className="extra-sm-btn" onClick={() => history.goBack()}>Back</button></div>
                        </div>


                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Table className="chip-summary-tbl" size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow className="summary-ac-head">

                                            <TableCell colSpan={4}>PLUS ACCOUNT</TableCell>

                                        </TableRow>
                                        <TableRow className="summary-tblhead">

                                            <TableCell component="th" align="left">Name</TableCell>
                                            <TableCell align="left" component="th" >Account</TableCell>
                                            <TableCell align="left" component="th" >Chips</TableCell>
                                            <TableCell align="left" component="th" > </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {
                                            summarys.map((row, index) => (
                                                row.p_l > 0 &&
                                                <TableRow key={index}>

                                                    <TableCell align="left"  >
                                                        <Link style={{ wordBreak: "break-all" }} onClick={() => history.push(`/chipsummary/${row.user_id}`)}>{row.name}</Link>
                                                    </TableCell>
                                                    <TableCell align="left" style={{ wordBreak: "break-all" }}>
                                                        {row.user_name}
                                                    </TableCell>
                                                    <TableCell align="left" className="chip-summary-pl" data-value={row.p_l}>
                                                        {row.p_l.toFixed(2)}
                                                    </TableCell>
                                                    <TableCell align="left" >
                                                        <Button variant="contained" size="small" className="summary-h-btn" onClick={() => history.push(`/accountinfo/${row.user_id}`)}>history</Button>&nbsp;
                                                        <Button variant="contained" size="small" className="summary-s-btn" onClick={() => modalOpen(row.user_id, "Debit", row.p_l)}>Settlement</Button>

                                                    </TableCell>
                                                </TableRow>


                                            ))
                                        }
                                        {parentPartnerShip > 0 &&
                                            <TableRow >

                                                <TableCell align="left" >
                                                    Parent Partnership
                                                </TableCell>
                                                <TableCell align="left" >
                                                    Parent Partnership
                                                </TableCell>
                                                <TableCell align="left" className="chip-summary-pl" data-value={parentPartnerShip}>
                                                    {parentPartnerShip}
                                                </TableCell>
                                                <TableCell align="left" >


                                                </TableCell>
                                            </TableRow>
                                        }

                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Table className="chip-summary-tbl" size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow className="summary-ac-head">

                                            <TableCell colSpan={4}>MINUS ACCOUNT</TableCell>

                                        </TableRow>
                                        <TableRow className="summary-tblhead">

                                            <TableCell component="th" align="left">Name</TableCell>
                                            <TableCell align="left" component="th" >Account</TableCell>
                                            <TableCell align="left" component="th" >Chips</TableCell>
                                            <TableCell align="left" component="th" > </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {
                                            summarys.map((row, index) => (
                                                row.p_l < 0 &&
                                                <TableRow key={index}>

                                                    <TableCell align="left" >
                                                        <Link style={{ wordBreak: "break-all" }} onClick={() => history.push(`/chipsummary/${row.user_id}`)}>{row.name}</Link>
                                                    </TableCell>
                                                    <TableCell style={{ wordBreak: "break-all" }} align="left" >
                                                        {row.user_name}
                                                    </TableCell>
                                                    <TableCell align="left" className="chip-summary-pl" data-value={row.p_l}>
                                                        {Math.abs(row.p_l.toFixed(2))}
                                                    </TableCell>
                                                    <TableCell align="left" >
                                                        <Button variant="contained" size="small" className="summary-h-btn" onClick={() => history.push(`/accountinfo/${row.user_id}`)}>history</Button>&nbsp;
                                                        <Button variant="contained" size="small" className="summary-s-btn" onClick={() => modalOpen(row.user_id, "Credit", row.p_l)}>Settlement</Button>

                                                    </TableCell>
                                                </TableRow>


                                            ))
                                        }
                                        {parentPartnerShip < 0 &&
                                            <TableRow >

                                                <TableCell align="left" >
                                                    Parent Partnership
                                                </TableCell>
                                                <TableCell align="left" >
                                                    Parent Partnership
                                                </TableCell>
                                                <TableCell align="left" className="chip-summary-pl" data-value={parentPartnerShip}>
                                                    {parentPartnerShip}
                                                </TableCell>
                                                <TableCell align="left" >


                                                </TableCell>
                                            </TableRow>
                                        }


                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                className={classes.modal}
                                open={opens}
                                onClose={modalClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                <Fade in={opens}>
                                    <div className="user_modal">

                                        <Grid container spacing={2}>
                                            <Grid item lg={11} xs={10}>
                                                <h2 id="transition-modal-title">User Name Commission A/c || {Math.abs(formData.p_l)}</h2>
                                            </Grid>
                                            <Grid item lg={1} xs={1}>
                                                <IconButton aria-label="delete" onClick={modalClose} color="secondary" className={classes.margin}>
                                                    <CancelIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <ValidatorForm

                                            onSubmit={handleSubmit}
                                            autoComplete="off"
                                        >
                                            <Grid container spacing={12}>
                                                <Grid item lg={6} xs={6}>
                                                    <TextValidator
                                                        value={formData.chips}
                                                        size="small"
                                                        validators={['chipsLimit', 'required']}
                                                        errorMessages={["over limit", "this field is required"]}
                                                        onChange={handleInputChange}
                                                        id="outlined-basic2" label="Chips" name="chips" variant="outlined" />
                                                </Grid>
                                                <Grid item lg={6} xs={6}>
                                                    <TextField
                                                        value={formData.remarks}
                                                        size="small"
                                                       
                                                        onChange={handleInputChange}
                                                        id="outlined-basic2" label="Remarks" name="remarks" variant="outlined" />
                                                </Grid>


                                                <Grid item lg={12} xs={12}>
                                                    <Button variant="contained" color="secondary" onClick={modalClose}>Close</Button>&nbsp;
                                                    <Button variant="contained" type="submit" color="primary">Save Chages</Button>



                                                </Grid>
                                            </Grid>
                                        </ValidatorForm>

                                    </div>
                                </Fade>
                            </Modal>

                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        </>
    );
}

export default ChipSummary;