import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

import "./admin.css";
import "./adminResponsive.css";

import {
    makeStyles,
    Grid,
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    Modal,
    Backdrop,
    Fade,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import axios from "axios";
import Appconfig from "../config/config";
import { ToastContainer, toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function createTbl(name, type, calories, fat, carbs, protein, suspended) {
    return { name, type, calories, fat, carbs, protein, suspended };
}

const rows = [
    createTbl("Surrey", "0 216", 159, 6.0, 1.67, 1.7),
    createTbl("Glamorgan", "0 200", 237, 9.0, 1.67, 1.7),
];

const datas = [
    createTbl("Surrey", "0 216", 159, 6.0, 1.67, 1.7),
    createTbl("Glamorgan", "0 200", 237, 9.0, 1.67, 1.7, "suspended"),
];

const fancy = [
    createTbl("5 Over Run", "0 216", 159, 6.0, 35, 37, ""),
    createTbl("10 Over Run", "0 200", 237, 9.0, 35, 37, ""),
    createTbl("Sachin Tendulkar Runs", "0 452", 15, 16.0, 35, 37, "suspended"),
    createTbl("Total Middal Overs", "0 100", 305, 3.7, 35, 37, ""),
    createTbl("First Inning total Run", "0 500", 48.5, 16.0, 35, 37, "suspended"),
    createTbl("20 Over Run", "0 500", 48.5, 16.0, 35, 37, ""),
    createTbl("5 Over Run", "0 500", 48.5, 16.0, 35, 37, ""),
];

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

const CasinoBets = () => {
    const classes = useStyles();
    const params = useParams();
    const casinoType = params.casino_type
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [EventData, setEventData] = React.useState([]);
    const [BetPlaceData, setBetPlaceData] = React.useState({
        event_id: "",
        market_id: "",
        is_back: "",
        price: "",
        is_fancy: "",
        selection_id: "",
        runner_name: "",
    });
    const [StakeValue, setStakeValue] = React.useState(0);
    const [ProfitValue, setProfitValue] = React.useState(0);
    const [LossValue, setLossValue] = React.useState(0);
    const [betSlipList, setBetSlipList] = React.useState([]);
    const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
    useEffect(() => { window.scroll(0,0)
        // console.log('hello');
        getEventData();
        getBetSlip();
    }, []);

    function getEventData() {

        // const event_id = params.event_id;
        const event_id = 30458779;

        console.log("userInfo", userInfo);
        var data = JSON.stringify({
            user_id: userInfo._id,
            event_id: event_id,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}eventsDashboard/getDashboardDataByeventId`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                console.log("setEventData", response.data.resultData);
                setEventData(response.data.resultData);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function betPlace() {

        console.log("userInfo", userInfo);
        var data = JSON.stringify({
            user_id: userInfo._id,
            match_id: BetPlaceData.event_id,
            selection_id: BetPlaceData.selection_id,
            is_back: BetPlaceData.is_back,
            stake: StakeValue,
            price_val: BetPlaceData.price,
            market_id: BetPlaceData.market_id,
            is_fancy: BetPlaceData.is_fancy == 1 ? "Yes" : "No",
            market_name: "Match odds",
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}betting/addBetting`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setOpen(false);
                setBetPlaceData({
                    event_id: "",
                    market_id: "",
                    is_back: "",
                    price: "",
                    is_fancy: "",
                    selection_id: "",
                    runner_name: "",
                });

                setProfitValue(0);
                setStakeValue(0);

                if (response.data.result == 0) {
                    notify("error", response.data.resultMessage);
                } else {
                    notify("success", "Betting Placed Successfully");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
          toast.error(message, {
                toastId: "error"
            });
        }
        else {
          toast.success(message, {
                toastId: "success"
            });
        }
      }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleOpen = (
        event_id,
        market_id,
        is_back,
        price,
        is_fancy,
        selection_id,
        runner_name
    ) => {
        const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));

        if (userInfo.user_type == "User") {
            setBetPlaceData({
                event_id: event_id,
                market_id: market_id,
                is_back: is_back,
                price: price,
                is_fancy: is_fancy,
                selection_id: selection_id,
                runner_name: runner_name,
            });
            setOpen(true);

            console.log("Bet Place Data", BetPlaceData);
        }
    };

    const handleClose = () => {
        setOpen(false);

        setBetPlaceData({
            event_id: "",
            market_id: "",
            is_back: "",
            price: "",
            is_fancy: "",
            selection_id: "",
            runner_name: "",
        });
    };

    const placeStakeValue = (stake) => {
        setStakeValue(stake);
        let profit = parseFloat(BetPlaceData.price) * parseFloat(stake) - stake;
        setProfitValue(profit);
    };
    function getBetSlip() {
        const eventId = params.event_id;
        var data = JSON.stringify({
            "user_id": userInfo._id,
            "event_id": eventId
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}betting/getBettingsByUserAndEventId`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setBetSlipList(response.data.resultData);
                console.log(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    return (
        <div>
            <Header />
            

            <Grid container className="bodypart">
                <Grid item lg={2} xs={2} className="xs_none tl-hide">
                    <List
                        component="nav"
                        className="side_list"
                        aria-label="secondary mailbox folders"
                    >
                        <ListItemLink href="#simple-list" className="active">
                            <ListItemText primary="Sports" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="In-Play" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="Cricket" />
                        </ListItemLink>
                    </List>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <iframe src={`${Appconfig.videoUrl[casinoType]}`} style={{ width: "100%" }} title="casinotv"></iframe>
                    <TableContainer component={Paper} className="event_tbl">
                        {EventData.length> 0 && EventData.map((event, index) =>
                            event.marketTypes.map((market, index) =>
                                market.marketRunners.length > 0 ? (
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow className="tblhead darktblhead">
                                                <TableCell colSpan="7">{market.market_name}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="tbl_body">
                                            <TableRow key="1" className="yelloBG">
                                                <TableCell className="table_first_row colorDanger"></TableCell>
                                                <TableCell
                                                    align="right"
                                                    className="xs_none"
                                                ></TableCell>
                                                <TableCell
                                                    align="right"
                                                    className="xs_none"
                                                ></TableCell>
                                                <TableCell
                                                    align="right"
                                                    className="xs_none"
                                                ></TableCell>
                                                <TableCell
                                                    align="right"
                                                    className="xs_none"
                                                ></TableCell>
                                                <TableCell align="right" className="bg_odd">
                                                    Back
                                                </TableCell>
                                                <TableCell align="right" className="bg_even">
                                                    Lay
                                                </TableCell>
                                            </TableRow>
                                            {market.marketRunners.map((runner, i) => (
                                                <TableRow key={i}>
                                                    <TableCell className="table_first_row">
                                                        <div className="text_left">
                                                            <p>{i / 2 === 0 ? 'Player A' : 'Player B'}</p>
                                                            <p>&nbsp;</p>
                                                        </div>
                                                        {/* <div>
                                                    <p className="xs_none">{row.type}</p>
                                                    <p className="xs_none">&nbsp;</p>
                                                </div> */}
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        onClick={() =>
                                                            handleOpen(
                                                                event.event_id,
                                                                market.market_id,
                                                                true,
                                                                runner.back_1_price,
                                                                false,
                                                                runner.selection_id,
                                                                runner.runner_name
                                                            )
                                                        }
                                                        className="xs_none"
                                                    ></TableCell>
                                                    <TableCell
                                                        align="right"
                                                        onClick={() =>
                                                            handleOpen(
                                                                event.event_id,
                                                                market.market_id,
                                                                true,
                                                                runner.back_1_price,
                                                                false,
                                                                runner.selection_id,
                                                                runner.runner_name
                                                            )
                                                        }
                                                        className="xs_none"
                                                    ></TableCell>
                                                    <TableCell
                                                        align="right"
                                                        onClick={() =>
                                                            handleOpen(
                                                                event.event_id,
                                                                market.market_id,
                                                                true,
                                                                runner.back_1_price,
                                                                false,
                                                                runner.selection_id,
                                                                runner.runner_name
                                                            )
                                                        }
                                                        className="xs_none"
                                                    ></TableCell>
                                                    <TableCell
                                                        align="right"
                                                        onClick={() =>
                                                            handleOpen(
                                                                event.event_id,
                                                                market.market_id,
                                                                true,
                                                                runner.back_1_price,
                                                                false,
                                                                runner.selection_id,
                                                                runner.runner_name
                                                            )
                                                        }
                                                        className="xs_none"
                                                    ></TableCell>
                                                    <TableCell
                                                        align="right"
                                                        onClick={() =>
                                                            handleOpen(
                                                                event.event_id,
                                                                market.market_id,
                                                                true,
                                                                runner.back_1_price,
                                                                false,
                                                                runner.selection_id,
                                                                runner.runner_name
                                                            )
                                                        }
                                                        className="bg_odd sus-parent"
                                                    >
                                                        <div className="td-cell">
                                                            {runner.back_1_price}
                                                            <p className="eventP">{runner.back_1_size}</p>
                                                        </div>
                                                        {runner.status === "suspended" ? (
                                                            <div className="suspended-cell">Suspended</div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        onClick={() =>
                                                            handleOpen(
                                                                event.event_id,
                                                                market.market_id,
                                                                false,
                                                                runner.lay_1_price,
                                                                false,
                                                                runner.selection_id,
                                                                runner.runner_name
                                                            )
                                                        }
                                                        className="bg_even"
                                                    >
                                                        <div className="td-cell">
                                                            {runner.lay_1_price}

                                                            <p className="eventP">{runner.lay_1_size}</p>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                ) : null
                            )
                        )}

                        <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                      <TableRow className="tblhead darktblhead">
                        <TableCell colSpan="7">Fancy</TableCell>
                      </TableRow>
                      <TableRow className="">
                        <TableCell className="table_first_head "> &nbsp;Min:0  Max: 0 </TableCell>
                        <TableCell align="right" className="xs_none"></TableCell>
                        <TableCell align="right" className="xs_none"></TableCell>
                        <TableCell align="right" className="xs_none"></TableCell>
                        <TableCell align="right" className="xs_none"></TableCell>
                        <TableCell align="right" className="bg_even fancy_th_bg_even" style={{ textAlign: 'center' }}>No</TableCell>
                        <TableCell align="right" className="bg_odd  fancy_th_bg_odd" style={{ textAlign: 'center' }}>Yes</TableCell>
                      </TableRow>
                    </TableHead>
                            <TableBody className="tbl_body">
                               

                                {EventData.length> 0 && EventData.map((event, index) =>
                                    event.fancy.map((fancy, i) => (
                                        <TableRow key={i}>
                                            <TableCell className="table_first_row">
                                                <div className="text_left">
                                                    <p>{fancy.runner_name}</p>
                                                    <p>&nbsp;</p>
                                                </div>
                                                {/* <div>
                                                <p className="xs_none">{row.type}</p>
                                                <p className="xs_none">&nbsp;</p>
                                            </div> */}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                onClick={() =>
                                                    handleOpen(event.event_id, "", true, "", false)
                                                }
                                                className="xs_none"
                                            ></TableCell>
                                            <TableCell
                                                align="right"
                                                onClick={() => handleOpen("1", "", true, "", false)}
                                                className="xs_none"
                                            ></TableCell>
                                            <TableCell
                                                align="right"
                                                onClick={() => handleOpen("1", "", true, "", false)}
                                                className="xs_none"
                                            ></TableCell>
                                            <TableCell
                                                align="right"
                                                onClick={() => handleOpen("1", "", true, "", false)}
                                                className="xs_none"
                                            ></TableCell>
                                            <TableCell
                                                align="right"
                                                onClick={() =>
                                                    handleOpen(
                                                        event.event_id,
                                                        "",
                                                        true,
                                                        fancy.back_price1,
                                                        true
                                                    )
                                                }
                                                className="bg_odd sus-parent"
                                            >
                                                <div className="td-cell">
                                                    {fancy.back_price1}
                                                    <p className="eventP">{fancy.back_size1}</p>
                                                </div>
                                                {fancy.game_status === "SUSPENDED" ? (
                                                    <div className="suspended-cell">Suspended</div>
                                                ) : (
                                                    ""
                                                )}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                onClick={() =>
                                                    handleOpen(
                                                        event.event_id,
                                                        "",
                                                        false,
                                                        fancy.lay_price1,
                                                        true
                                                    )
                                                }
                                                className="bg_even"
                                            >
                                                <div className="td-cell">
                                                    {fancy.lay_price1}
                                                    <p className="eventP">{fancy.lay_size1}</p>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="betTab">
                        <AppBar position="static">
                            <Tabs
                                value={value}
                                variant="fullWidth"
                                onChange={handleChange}
                                aria-label="simple tabs example"
                            >
                                <Tab label="All Bet (0)" {...a11yProps(0)} />
                                <Tab label="Match Odds (0)" {...a11yProps(1)} />
                                <Tab label="Fancy Bet (0)" {...a11yProps(2)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel className="tabBlock" value={value} index={0}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="darktblhead">
                                            <TableCell>No.</TableCell>
                                            <TableCell>Runner</TableCell>
                                            <TableCell>Odds</TableCell>
                                            <TableCell>Stack</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {betSlipList.map((row) => (
                                            <TableRow key={row.match_id}>
                                                <TableCell>{row.match_id}</TableCell>
                                                <TableCell>{row.runner_name}</TableCell>
                                                <TableCell>{row.odds}</TableCell>
                                                <TableCell>{row.stack}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel className="tabBlock" value={value} index={1}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="darktblhead">
                                            <TableCell>No.</TableCell>
                                            <TableCell>Runner</TableCell>
                                            <TableCell>Odds</TableCell>
                                            <TableCell>Stack</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {betSlipList.map((row) => (
                                            <TableRow key={row.match_id}>
                                                <TableCell>{row.match_id}</TableCell>
                                                <TableCell>{row.runner_name}</TableCell>
                                                <TableCell>{row.odds}</TableCell>
                                                <TableCell>{row.stack}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        <TabPanel className="tabBlock" value={value} index={2}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="darktblhead">
                                            <TableCell>No.</TableCell>
                                            <TableCell>Runner</TableCell>
                                            <TableCell>Odds</TableCell>
                                            <TableCell>Stack</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {betSlipList.map((row) => (
                                            <TableRow key={row.match_id}>
                                                <TableCell>{row.match_id}</TableCell>
                                                <TableCell>{row.runner_name}</TableCell>
                                                <TableCell>{row.odds}</TableCell>
                                                <TableCell>{row.stack}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                    </div>
                </Grid>
                <Grid item lg={4} xs={12} className="event-single-popup">
                    {/* <form className="xs_none" noValidate autoComplete="off">
                        <Grid container >
                            <Grid item xs={6} sm={3}>
                                <p>Back (Bet For) </p>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <p>Profit </p>
                                <p className="colorDanger">0 </p>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <p>Loss </p>
                                <p className="colorSuccess">0 </p>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <p>Surrey </p>
                            </Grid>
                            <Grid className="flex-row">                
                                <Grid className="colmun">
                                    <TextField label="Size" id="outlined-size-small" variant="outlined" size="small" />
                                </Grid>
                                <Grid className="colmun">
                                    <TextField label="Stake" id="outlined-size-small" variant="outlined" size="small" />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} className="modal_number">
                                <Button variant="contained" className="eventSingleBtn">500</Button>
                                <Button variant="contained" className="eventSingleBtn">1000</Button>
                                <Button variant="contained" className="eventSingleBtn">5000</Button>
                                <Button variant="contained" className="eventSingleBtn">10000</Button>
                                <Button variant="contained" className="eventSingleBtn">25000</Button>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Grid container className="space-col">
                                    <Grid item xs={6} sm={6}>
                                        <Button variant="contained" className="eventSingleCancelBtn">CANCEL</Button>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Button variant="contained" className="eventSingleSuccessBtn">PLACE BET</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form> */}
                </Grid>
            </Grid>
            {/* <button type="button" onClick={handleOpen}>
                react-transition-group
            </button> */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="xs_modal place-bat-model">
                        <form className={classes.root} noValidate autoComplete="off">
                            <div className="top_head">
                                <Button>BET SLIP</Button>
                            </div>
                            <div className="modal_head">
                                <Grid container>
                                    <Grid item xs={3} sm={3}>
                                        <p className="left-txt">Back </p>
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                        <p>Odds </p>
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                        <p>Stake </p>
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                        <p className="right-txt">Profit </p>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="modal_body">
                                <Grid className="flex-row">
                                    <Grid item xs={6} sm={6}>
                                        <strong className="white-txt">
                                            {BetPlaceData.runner_name}
                                        </strong>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <TextField
                                            label="Size"
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small"
                                            value={BetPlaceData.price}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <TextField
                                            label="Stake"
                                            id="outlined-size-small"
                                            variant="outlined"
                                            size="small"
                                            value={StakeValue}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <strong className="white-txt right-txt">
                                            {ProfitValue}
                                        </strong>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="modal_footer">
                                <Grid container>
                                    <Grid item xs={12} sm={12} className="modal_number">
                                        <Button variant="contained" className="eventSingleBtn">
                                            1
                                        </Button>
                                        <Button variant="contained" className="eventSingleBtn">
                                            2
                                        </Button>
                                        <Button variant="contained" className="eventSingleBtn">
                                            3
                                        </Button>
                                        <Button variant="contained" className="eventSingleBtn">
                                            4
                                        </Button>
                                        <Button variant="contained" className="eventSingleBtn">
                                            5
                                        </Button>
                                        <Button variant="contained" className="eventSingleBtn">
                                            6
                                        </Button>
                                        <Button variant="contained" className="eventSingleBtn">
                                            7
                                        </Button>
                                        <Button variant="contained" className="eventSingleBtn">
                                            8
                                        </Button>
                                        <Button variant="contained" className="eventSingleBtn">
                                            9
                                        </Button>
                                        <Button variant="contained" className="eventSingleBtn">
                                            0
                                        </Button>
                                        <Button variant="contained" className="eventSingleBtn">
                                            00
                                        </Button>
                                        <Button variant="contained" className="eventSingleBtn">
                                            {"<"}
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12} sm={12} className="modal_btn">
                                        <Button
                                            onClick={() => placeStakeValue(100)}
                                            variant="contained"
                                            className="eventSingleBtn"
                                        >
                                            100
                                        </Button>
                                        <Button
                                            onClick={() => placeStakeValue(200)}
                                            variant="contained"
                                            className="eventSingleBtn"
                                        >
                                            200
                                        </Button>
                                        <Button
                                            onClick={() => placeStakeValue(500)}
                                            variant="contained"
                                            className="eventSingleBtn"
                                        >
                                            500
                                        </Button>
                                        <Button
                                            onClick={() => placeStakeValue(1000)}
                                            variant="contained"
                                            className="eventSingleBtn"
                                        >
                                            1K
                                        </Button>
                                        <Button
                                            onClick={() => placeStakeValue(5000)}
                                            variant="contained"
                                            className="eventSingleBtn"
                                        >
                                            5K
                                        </Button>
                                        <Button
                                            onClick={() => placeStakeValue(10000)}
                                            variant="contained"
                                            className="eventSingleBtn"
                                        >
                                            10K
                                        </Button>
                                        <Button
                                            onClick={() => placeStakeValue(50000)}
                                            variant="contained"
                                            className="eventSingleBtn"
                                        >
                                            50K
                                        </Button>
                                        <Button
                                            onClick={() => placeStakeValue(100000)}
                                            variant="contained"
                                            className="eventSingleBtn"
                                        >
                                            1Peti
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6} sm={6}>
                                                <Button
                                                    onClick={() => handleClose()}
                                                    variant="contained"
                                                    className="eventSingleCancelBtn"
                                                >
                                                    CANCEL
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <Button
                                                    variant="contained"
                                                    className="eventSingleSuccessBtn"
                                                    onClick={() => betPlace()}
                                                >
                                                    PLACE BET
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </div>
                </Fade>
            </Modal>
            <Footer />
        </div>
    );
};

export default CasinoBets;
