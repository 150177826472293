
import React, { useState, useEffect } from 'react';
import { Table, TableBody, MenuItem, FormControl, InputLabel, Select, Menu, NativeSelect, Checkbox, TableCell, TableContainer, TableHead, Paper, Dialog, TableRow, DialogActions, DialogTitle, DialogContentText, DialogContent, Grid, Button, List, ListItemText, Modal, Typography, ListItem, Fade, Backdrop, TextField } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Appconfig from '../config/config';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import moment from 'moment';
import { spacing } from '@material-ui/system';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';


import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import CancelIcon from '@material-ui/icons/Cancel';
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}
const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 340,
    },
    table: {
        marginTop: 10,
        margin: 10,
    },
    container: {
        // padding: 5,
        // marginTop:8
    },
    root: {
        width: '100%',
        marginTop: 140,
        margin: 10,

    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10
    },
    headerdiv: {
        // marginTop: 10,
        padding: 15,

    },
    button: {
        margin: theme.spacing(1),
    },
}));

const News = () => {
    const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
    const [opens, setOpens] = React.useState(false);

    const [News, setNews] = React.useState([]);
    const [dialougeOpen, setDialougeOpen] = React.useState(false);
    const [deleteNewsid, setDeleteNewsid] = React.useState('');
    const [editopens, setEditOpens] = React.useState(false);
    const [formEditData, setEditFormData] = useState({
        site_id: "60fc5aca67e1e3799230a6f7",
        message: '',
        is_active: "Yes",
        is_delete: "No",
        id: ''
    });
    const [newUpdated, SetIsBalanceUpdated] = React.useState(false);

    const handleUpdateInputChange = (event) => {


        setEditFormData({
            ...formEditData,
            [event.target.name]: event.target.value
        })
    }


    const editmodalClose = () => {
        setEditOpens(false);
    };
    const modalOpen = () => {
        setOpens(true);

    };


    const modalClose = () => {
        setOpens(false);
    };


    const [formData, setFormData] = useState({
        site_id: "60fc5aca67e1e3799230a6f7",
        message: '',
        is_active: "Yes",
        is_delete: "No"


    });


    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        })
    }
    function handleClickDialogeOpen() {
        setDialougeOpen(true);
    };

    const handleDialogeClose = () => {
        setDialougeOpen(false);
    };
    const handleSubmit = (e) => {

        e.preventDefault();

        var axios = require('axios');
        var data = JSON.stringify(formData);

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}news/addNews`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        console.log(data);
        axios(config)
            .then(function (response) {
                getNews();
                modalClose();
                SetIsBalanceUpdated(!newUpdated);
                if (response.data.result) {
                    notify('success', response.data.resultMessage)
                    formData["message"] = "";
                }
                else {
                    notify('error', response.data.resultMessage)
                }

            })
            .catch(function (error) {
                console.log(error);
            });


    }




    const handleUpdate = (e) => {

        e.preventDefault();


        var data = JSON.stringify({
            _id: formEditData.id,
            data: {
                message: formEditData.message,
                is_active: "Yes",
                is_delete: "No"
            }
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}news/updateNews`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        console.log(data);
        axios(config)
            .then(function (response) {

                getNews();
                editmodalClose();
                SetIsBalanceUpdated(!newUpdated);
                if (response.data.result) {
                    notify('success', response.data.resultMessage)
                }
                else {
                    notify('error', response.data.resultMessage)
                }

            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const classes = useStyles();

    useEffect(() => { window.scroll(0,0)
        getNews();
    }, []);

    function getNews() {

        var data = JSON.stringify({
            "site_id": "60fc5aca67e1e3799230a6f7"
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}news/getNews`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setNews(response.data.resultData);

                let tmpUserList = response.data.resultData;
                const newUserList = tmpUserList.map((News, index) => {
                    tmpUserList[index]["id"] = index + 1;
                });
                console.log(tmpUserList);
                setNews(tmpUserList);

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    function editNews(id, index) {
        console.log(News[index].message);
        formEditData.message = News[index].message
        formEditData.id = News[index]._id
        setEditOpens(true);
    }

    function deleteNews(id) {
        setDeleteNewsid(id);
        handleClickDialogeOpen();
    }

    const confirmDelete = () => {
        var data = JSON.stringify({
            "_id": deleteNewsid
        });

        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}news/deleteNews`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                // setdeletechipid('');
                setDialougeOpen(false);
                console.log('Delete', response.data);
                if (response.data.result) {
                    getNews();
                    notify(response.data.resultMessage, 'success')
                }
                else {
                    notify(response.data.resultMessage, 'error')
                }
            })
            .catch(function (error) {

                console.log(error);
            });
    }
    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }
    const columns = [


        {
            field: 'id',
            headerName: 'S.NO',
            editable: true,
            width: 91
        },

        {
            field: 'message',
            headerName: 'Msg_Title',
            editable: true,
            width: 180
        },
        {
            field: "edit",
            headerName: "Edit",
            sortable: false,
            width: 91,
            disableClickEventBubbling: true,
            renderCell: (rowData) => {
                return (
                    <IconButton aria-label="delete" onClick={() => editNews(rowData.row._id, rowData.row.id - 1)}>
                        <EditIcon color="primary" fontSize="medium" />
                    </IconButton>
                );
            }
        },
        {
            field: "delete",
            headerName: "Delete",
            sortable: false,
            width: 91,
            disableClickEventBubbling: true,
            renderCell: (rowData) => {
                return (

                    <IconButton aria-label="delete" onClick={() => deleteNews(rowData.row._id)}>
                        <DeleteIcon color="secondary" fontSize="medium" />
                    </IconButton>
                );
            }
        }

    ];
    return (
        <>
            <div >
                <Header isBalanceUpdated={newUpdated} />
                <Grid className="bodypart custom-container grid-row">
                    <Grid item lg={2} xs={2} className="xs_none tl-hide">
                        <List component="nav" className="side_list" aria-label="secondary mailbox folders">
                            <ListItemLink href="#simple-list" className="active">
                                <ListItemText primary="Sports" />
                            </ListItemLink>
                            <ListItemLink href="#simple-list">
                                <ListItemText primary="In-Play" />
                            </ListItemLink>
                            <ListItemLink href="#simple-list">
                                <ListItemText primary="Cricket" />
                            </ListItemLink>
                        </List>
                    </Grid>

                    <Grid item lg={10} xs={12} className="report-section">

                        <div className="datatable_heading">
                            <Grid container>
                                <Grid item xs={8} sm={8} >
                                    Message Setting
                                </Grid>
                                <Grid item sm={4} xs={4} ><Button className="btn-for-add" onClick={modalOpen} >Add Message</Button></Grid>


                            </Grid>
                        </div>
                        <div style={{ display: 'flex', height: '100vh' }}>
                            <div style={{ flexGrow: 1 }}>
                                <DataGrid

                                    rows={News}
                                    columns={columns}
                                    disableSelectionOnClick
                                />
                            </div>
                        </div>


                        <Grid container>
                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                className={classes.modal}
                                open={opens}
                                onClose={modalClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                <Fade in={opens}>
                                    <div className="user_modal">

                                        <Grid container spacing={2}>
                                            <Grid item lg={11} xs={10}>
                                                <h2 id="transition-modal-title">Add Message</h2>
                                            </Grid>
                                            <Grid item lg={1} xs={1}>
                                                <IconButton aria-label="delete" onClick={modalClose} color="secondary" className={classes.margin}>
                                                    <CancelIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <ValidatorForm

                                            onSubmit={handleSubmit}
                                            autoComplete="off"
                                        >
                                            <Grid container spacing={12}>
                                                <Grid item lg={12} xs={12}>
                                                    <TextValidator
                                                        value={formData.message}
                                                        size="small"
                                                        validators={['required']}
                                                        errorMessages={['this field is required']}
                                                        onChange={handleInputChange}
                                                        id="outlined-basic2" label="Description" name="message" variant="outlined" />
                                                </Grid>


                                                <Grid item lg={12} xs={12}>
                                                <Button variant="contained" type="submit" color="primary">Save Chages</Button>&nbsp;
                                                    <Button variant="contained" color="secondary" onClick={modalClose}>Close</Button>
                                                   

                                                </Grid>
                                            </Grid>
                                        </ValidatorForm>
                                    </div>
                                </Fade>
                            </Modal>
                            <Dialog
                                open={dialougeOpen}
                                onClose={handleDialogeClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >

                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Are You Sure You Want To Delete This News
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant="contained" onClick={handleDialogeClose} color="secondary" >
                                        Cancel
                                    </Button>
                                    <Button variant="contained" onClick={confirmDelete} color="primary" autoFocus>
                                        Accept
                                    </Button>
                                </DialogActions>
                            </Dialog>


                            <Modal
                                aria-labelledby="transition-modal-title"
                                aria-describedby="transition-modal-description"
                                className={classes.modal}
                                open={editopens}
                                onClose={editmodalClose}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                    timeout: 500,
                                }}
                            >
                                <Fade in={editopens}>
                                    <div className="user_modal">
                                        <Grid container spacing={2}>
                                            <Grid item lg={11} xs={10}>
                                                <h2 id="transition-modal-title">Edit Message</h2>
                                            </Grid>
                                            <Grid item lg={1} xs={1}>
                                                <IconButton aria-label="delete" onClick={editmodalClose} color="secondary" className={classes.margin}>
                                                    <CancelIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>

                                        <ValidatorForm

                                            onSubmit={handleUpdate}
                                            autoComplete="off"
                                        >
                                            <Grid container spacing={2}>

                                                <Grid item lg={12} xs={12}>
                                                    <TextValidator
                                                        value={formEditData.message}
                                                        size="small"
                                                        validators={['required']}
                                                        errorMessages={['this field is required']}
                                                        onChange={handleUpdateInputChange}
                                                        id="outlined-basic2" label="Description" name="message" variant="outlined" />
                                                </Grid>


                                                <Grid item sm={12} xs={12}>
                                                <Button variant="contained" color="secondary" onClick={editmodalClose}>Close</Button>&nbsp;
                                                    <Button variant="contained" type="submit" color="primary">Save Chages</Button>
                                                    


                                                </Grid>
                                            </Grid>
                                        </ValidatorForm>
                                    </div>
                                </Fade>
                            </Modal>
                        </Grid>
                    </Grid>
                </Grid>
                <Footer />
            </div>
        </>
    );
}

export default News;