import React from 'react';
import { makeStyles, Grid, List, TextField, ListItem, InputLabel, ListItemText, Select, FormControl, NativeSelect, Button, MenuItem, } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Header from './Header'
import Footer from './Footer'
import Appconfig from '../config/config';
import axios from 'axios';
import './admin.css';
import moment from 'moment';
import { useParams } from "react-router-dom";
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Bethistory() {
  const params = useParams();
  const userId = params.user_id;
  const eventId = params.event_id;
  const selectionId = params.selection_id;
  const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
  const [pageCount, setPageCount] = React.useState(10);
  const [startStopLoader, setStartStopLoader] = React.useState(false);
  const [formData, setFormData] = React.useState({
    "event_type": 4,
    "user_id": userInfo._id,
    'status': "Settled",
    'from_date': moment().subtract(1, 'days').format("YYYY-MM-DD"),
    'to_date': moment().format("YYYY-MM-DD"),
  })
  const [betHistoryList, SetBetHistoryList] = React.useState([]);
  React.useEffect(() => {
    window.scroll(0, 0);
    getBetHistory(formData.event_type);
  }, [eventId]);
  const [searched, setSearched] = React.useState("");
  const [FilterList, setFilterList] = React.useState([]);

  const handleFormDataChange = (event) => {

    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }
  const classes = useStyles();


  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  const columns1 = [

    { field: 'id', width: 91, headerName: 'S.No.' },
    // {
    //   field: 'user_id',
    //   headerName: 'Client',
    //   editable: false,
    //   width: 102,

    // },
    {
      field: 'createdAt',
      headerName: 'Datetime',
      editable: true,
      width: 145,
      renderCell: rowData => {
        return (
          moment(rowData.row.createdAt).format("DD-MMM-YYYY hh:mm:ss")

        );
      }

    },
    // {
    //   field: 'user_ids',
    //   headerName: 'Super Admin',
    //   editable: false,
    //   width: 180,
    // },
    // {
    //   field: 'user_dfid',
    //   headerName: 'Sub Admin',
    //   editable: false,
    //   width: 180,
    // },
    // {
    //   field: 'user_reid',
    //   headerName: 'Super Super',
    //   editable: false,
    //   width: 180,
    // },
    // {
    //   field: 'user_reifdd',
    //   headerName: 'Super Master',
    //   editable: false,
    //   width: 180,
    // },
    // {
    //   field: 'aad',
    //   headerName: 'Master',
    //   editable: false,
    //   width: 180,
    // },
    {
      field: 'event_name',
      headerName: 'Description',
      editable: false,
      width: 250,
      renderCell: rowData => {
        return (
          rowData.row.event_name + (rowData.row.status == "Open" ? "" : " (Win : " + rowData.row.result_name + ")")

        );
      }


    },
    {
      field: 'runner_name',
      headerName: 'Selection',
      editable: false,
      width: 200,
    },
    {
      field: 'is_back',
      headerName: 'Type',
      editable: true,
      width: 87,
      renderCell: rowData => {
        return (
          rowData.row.is_back ? "Back" : "Lay"

        );
      }
    },
    {
      field: 'price_val',
      headerName: 'Odds',
      editable: false,
      width: 90,
    },
    {
      field: 'stake',
      headerName: 'Stake',
      editable: false,
      width: 92,
    },

    {
      field: 'p_l',
      headerName: 'P_L',
      editable: false,
      renderCell: rowData => {
        return (
          rowData.row.betting_type == "Fancy" ? (rowData.row.profit).toFixed(2) : (rowData.row.p_l).toFixed(2)
        );
      }
      // width: 180,
    },
    {
      field: 'profit',
      headerName: 'Profit',
      editable: false,
      renderCell: rowData => {
        return (
          rowData.row.status == "Open" ? "0" : (rowData.row.bet_result == "Plus" ? rowData.row.profit : "0")

        );
      }
      // width: 180,
    },
    {
      field: 'loss',
      headerName: 'Loss',
      editable: false,
      width: 106,
      renderCell: rowData => {
        return (
          rowData.row.status == "Open" ? "0" : (rowData.row.bet_result == "Minus" ? rowData.row.loss : "0")

        );
      }
    },
    {
      field: 'betting_type',
      headerName: 'Bet Type',
      editable: false,
      width: 111,
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      // width: 180,
    },
    {
      field: 'ip_address',
      headerName: 'IP',
      editable: false,
      width: 180,

      renderCell: rowData => {
        return (
          rowData.row.ip_address.replace("::ffff:", "")

        );

      }
      // width: 180,
    },
    {
      field: 'profFDSit',
      headerName: 'Device',
      editable: false,
      renderCell: rowData => {
        return (
          <PhoneIphoneIcon />

        );

      }
    }

  ];

  const columns2 = [

    { field: 'id', width: 91, headerName: 'S.No.' },
    {
      field: 'createdAt',
      headerName: 'Datetime',
      editable: true,
      width: 145,
      renderCell: rowData => {
        return (
          moment(rowData.row.createdAt).format("DD-MMM-YYYY hh:mm:ss")

        );
      }

    },
    {
      field: 'user_name',
      headerName: 'Client',
      editable: false,
      width: 130,
    },

    {
      field: 'event_name',
      headerName: 'Description',
      editable: false,
      width: 250,
      renderCell: rowData => {
        return (
          rowData.row.event_name + (rowData.row.status == "Open" ? "" : " (Win : " + rowData.row.result_name + ")")

        );
      }


    },
    {
      field: 'runner_name',
      headerName: 'Selection',
      editable: false,
      width: 200,
    },
    {
      field: 'is_back',
      headerName: 'Type',
      editable: true,
      width: 87,
      renderCell: rowData => {
        return (
          rowData.row.is_back ? "Back" : "Lay"

        );
      }
    },
    {
      field: 'price_val',
      headerName: 'Odds',
      editable: false,
      width: 90,
    },
    {
      field: 'stake',
      headerName: 'Stake',
      editable: false,
      width: 92,
    },

    {
      field: 'p_l',
      headerName: 'P_L',
      editable: false,
      renderCell: rowData => {
        return (
          rowData.row.betting_type == "Fancy" ? (rowData.row.profit).toFixed(2) : (rowData.row.p_l).toFixed(2)
        );
      }
      // width: 180,
    },
    {
      field: 'profit',
      headerName: 'Profit',
      editable: false,
      renderCell: rowData => {
        return (
          rowData.row.status == "Open" ? "0" : (rowData.row.bet_result == "Plus" ? rowData.row.profit : "0")

        );
      }
      // width: 180,
    },
    {
      field: 'loss',
      headerName: 'Loss',
      editable: false,
      width: 106,
      renderCell: rowData => {
        return (
          rowData.row.status == "Open" ? "0" : (rowData.row.bet_result == "Minus" ? rowData.row.loss : "0")

        );
      }
    },
    {
      field: 'betting_type',
      headerName: 'Bet Type',
      editable: false,
      width: 111,
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      // width: 180,
    },
    {
      field: 'ip_address',
      headerName: 'IP',
      editable: false,
      width: 180,

      renderCell: rowData => {
        return (
          rowData.row.ip_address.replace("::ffff:", "")

        );

      }
      // width: 180,
    },
    {
      field: 'profFDSit',
      headerName: 'Device',
      editable: false,
      renderCell: rowData => {
        return (
          <PhoneIphoneIcon />

        );

      }
    }

  ];

  let columns = userInfo.user_type != "User" ? columns2 : columns2
  const [state, setState] = React.useState(" ");

  const handleFilterSubmit = () => {
    getBetHistory(formData.event_type);
  }
  const handleChange = (event) => {
    const name = event.target.name;
    setState(event.target.value);
  };

  function getBetHistory(event_type) {
    let tmpAccountList;
    setStartStopLoader(true)
    formData.event_type = event_type;
    if (eventId == 0) {
      formData.from_date = moment(formData.from_date).format('YYYY-MM-DD');
      formData.to_date = moment(formData.to_date).format('YYYY-MM-DD')
    }
    else {
      formData.from_date = "";
      formData.to_date = "";
      formData.status = "Settled";
    }

    formData.matchUnmatch = state;
    formData.event_id = eventId;
    formData.selection_id = selectionId;

    let id = userInfo._id;
    if (userId != 0) {
      id = userId;
    }
    formData.user_id = id
    var data = JSON.stringify(formData);

    var config = {
      method: 'post',
      url: `${Appconfig.apiUrl}reports/betHistory`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // //console.log(JSON.stringify(response.data));
        SetBetHistoryList(response.data.resultData);
        tmpAccountList = response.data.resultData;
        const newChipList = tmpAccountList.map((betHistoryList, index) => {
          tmpAccountList[index]["id"] = index + 1;
        });
        SetBetHistoryList(tmpAccountList);
        setFilterList(tmpAccountList);
        // //console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      }).then(() => {
        setStartStopLoader(false);
        if (!tmpAccountList.length) {
          // console.log("sadfd", document.getElementsByClassName("MuiDataGrid-window"));
          document.getElementsByClassName("MuiDataGrid-window")[0].innerHTML = "<div class='no-row'><span>No Rows</span></div>"
        }

      });
  }

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    // console.log("searchedVal",searchedVal);
    const filteredRows = betHistoryList.filter((row) => {
      return row.user_name.toLowerCase().includes(searchedVal.toLowerCase())||row.event_name.toLowerCase().includes(searchedVal.toLowerCase())||row.runner_name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setFilterList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  return (
    <div className={classes.root}>
      <Header />
      <Grid className="bodypart custom-container grid-row">
        <Grid item lg={2} xs={2} className="xs_none tl-hide">
          <List component="nav" className="side_list" aria-label="secondary mailbox folders">
            <ListItemLink href="#simple-list" className="active">
              <ListItemText primary="Sports" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="In-Play" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="Cricket" />
            </ListItemLink>
          </List>
        </Grid>

        <Grid item lg={10} xs={12} className="report-section">

          <div className="datatable_heading">
            Bet History &nbsp;

          </div>
          {eventId == 0 ?
            <Grid container spacing={0} >
              <Grid item sm={3} xs={6} md>
                <TextField
                  id="date"
                  size="small"
                  variant="outlined"
                  label="From Date"
                  type="date"
                  name="from_date"
                  onChange={handleFormDataChange}
                  value={formData.from_date}
                  className="dates"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

              </Grid>
              <Grid item sm={3} xs={6} md>
                <TextField
                  id="date"
                  size="small"
                  variant="outlined"
                  label="To Date"
                  type="date"
                  name="to_date"
                  className="dates"
                  onChange={handleFormDataChange}
                  value={formData.to_date}

                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item sm={3} xs={6} md>
                <FormControl style={{ width: "97%" }} className={classes.formControl} variant="outlined" size="small" >
                  <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={formData.status}
                    onChange={handleFormDataChange}
                    name="status"
                    className="gametype"
                    inputProps={{ 'aria-label': 'status' }}
                    label="Game Type"
                  >
                    <MenuItem value="Open">Open</MenuItem>
                    <MenuItem value="Settled">Settled</MenuItem>
                  </Select>
                </FormControl>
              </Grid>



              {/* <Grid item sm={3} xs={6} md>
              <FormControl variant="outlined" size="small" className={classes.formControl} style={{ width: "97%" }}>
                <InputLabel id="demo-simple-select-outlined-label">Match/Unmatch</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state}
                  onChange={handleChange}
                  name="age"
                  label="Match/Unmatch"

                >
                  <MenuItem value=" ">Select</MenuItem>
                  <MenuItem value="Match">Match</MenuItem>
                  <MenuItem value="Unmatch">Unmatch</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
              <Grid item sm={3} xs={6} md>
                <FormControl variant="outlined" size="small" className={classes.formControl} style={{ width: "97%" }}>
                  <InputLabel id="demo-simple-select-outlined-label">Game Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={formData.event_type}
                    onChange={handleFormDataChange}
                    name="event_type"
                    label="Game Type"

                  >
                    <MenuItem value=" ">All</MenuItem>
                    <MenuItem value={4}>Cricket</MenuItem>
                    <MenuItem value={999}>Fancy</MenuItem>

                    <MenuItem value={2}>Tennis</MenuItem>

                    <MenuItem value={1}>Soccer</MenuItem>
                    <MenuItem value={1001}>Casino</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item sm={3} xs={12} style={{ textAlign: "right" }} md>
                <Button variant="contained" color="secondary">
                  Clear
                </Button>
                &nbsp;
                <Button variant="contained" color="primary" onClick={handleFilterSubmit}>
                  Filter
                </Button>


              </Grid>

            </Grid>

            : ""}
          {/* <div className="datatable_filter profit_filter bethistory"> */}
          {/* <Grid container spacing="0" className="betHistory-btns">

              <Grid item sm={2} xs={4} >
                <button className="square-btn" onClick={() => getBetHistory("")}>
                  All
                </button>
              </Grid>
              <Grid item sm={2} xs={4} >
                <button className="square-btn" onClick={() => getBetHistory(1)}>
                  Soccer
                </button>
              </Grid>
              <Grid item sm={2} xs={4} onClick={() => getBetHistory(2)}>
                <button className="square-btn">
                  Tennis
                </button>
              </Grid>
              <Grid item sm={2} xs={4} >
                <button className="square-btn" onClick={() => getBetHistory(4)}>
                  Cricket
                </button>
              </Grid>
              <Grid item sm={2} xs={4} >
                <button className="square-btn" onClick={() => getBetHistory(1001)}>
                  Fancy
                </button>
              </Grid>
              <Grid item sm={2} xs={4} >
                <button className="square-btn active" onClick={() => getBetHistory(1001)}>
                  Casino
                </button>
              </Grid>
            </Grid> */}
          {/* </div> */}
          <Grid container spacing={0}>
            <Grid item sm={6} xs={6} md>
              <div className="show_data">
                <span className="showentries">Show</span>
                <span>
                  <FormControl className={classes.formControl}>
                    <NativeSelect
                      value={pageCount}
                      onChange={(e) => setPageCount(e.target.value)}
                      name="age"
                      className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'age' }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </NativeSelect>
                  </FormControl>
                </span>
                <span className="showentries">entries</span>
              </div>
            </Grid>
            <Grid item sm={6} xs={6} md>
              <div className="search_data" style={{ display: 'flex', placeItems: 'center' }}>
                <span className="showentries">Search:</span>
                <span>
                  <form className={classes.root} noValidate autoComplete="off">
                    <TextField value={searched} onChange={(searchVal) => requestSearch(searchVal.target.value)} variant="outlined" size="small" id="standard-basic" />
                  </form>
                </span>
              </div>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', height: '100vh' }}>
            <div style={{ flexGrow: 1 }}>
              {startStopLoader ? <div className="loaderDiv" > <CircularProgress /></div> : <DataGrid
                // components={{
                //   Toolbar: GridToolbar,
                // }}
                // filterModel={{
                //   items: [{ columnField: 'commodity', operatorValue: 'contains', value: 'rice' }],
                // }}
                rows={FilterList}
                columns={columns}
                pageSize={pageCount}
                disableSelectionOnClick
                getRowClassName={(params) =>
                  `${params.row.is_back == 1 ? 'back-row' : 'lay-row'}`
                }
              />
              }
            </div>
          </div>
        </Grid>
      </Grid>

      <Footer />
    </div >
  );
}