import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useHistory } from "react-router-dom";
import "./admin.css";
import "./adminResponsive.css";
import MiniGames from "./MiniGames";
import {
    makeStyles,
    Grid,
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Button,
    List,
    ListItem,
    ListItemText,
    Modal,
    Backdrop,
    Fade,


} from "@material-ui/core";

import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import { useParams } from "react-router-dom";
import axios from "axios";
import Appconfig from "../config/config";
import { ToastContainer, toast } from "react-toastify";



import openTeen from "../assets/casino-match/open-teen.jpg";
import liveTeenpatti from "../assets/casino-match/live-teenpatti.jpg";
import dragonTiger from "../assets/casino-match/dragon-tiger.jpg";
import anderBaher from "../assets/casino-match/ander-baher.jpg";
import cardsLive from "../assets/casino-match/32-Cards-Live.jpg";
import sevenUpDown from "../assets/casino-match/7updown.jpg";
import amarAkbarAnothny from "../assets/casino-match/warli-matka.jpg";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function createTbl(name, type, calories, fat, carbs, protein, suspended) {
    return { name, type, calories, fat, carbs, protein, suspended };
}

const rows = [
    createTbl("Surrey", "0 216", 159, 6.0, 1.67, 1.7),
    createTbl("Glamorgan", "0 200", 237, 9.0, 1.67, 1.7),
];

const datas = [
    createTbl("Surrey", "0 216", 159, 6.0, 1.67, 1.7),
    createTbl("Glamorgan", "0 200", 237, 9.0, 1.67, 1.7, "suspended"),
];

const fancy = [
    createTbl("5 Over Run", "0 216", 159, 6.0, 35, 37, ""),
    createTbl("10 Over Run", "0 200", 237, 9.0, 35, 37, ""),
    createTbl("Sachin Tendulkar Runs", "0 452", 15, 16.0, 35, 37, "suspended"),
    createTbl("Total Middal Overs", "0 100", 305, 3.7, 35, 37, ""),
    createTbl("First Inning total Run", "0 500", 48.5, 16.0, 35, 37, "suspended"),
    createTbl("20 Over Run", "0 500", 48.5, 16.0, 35, 37, ""),
    createTbl("5 Over Run", "0 500", 48.5, 16.0, 35, 37, ""),
];

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}
const CasinoList = () => {
    const history = useHistory();
    const handleChange = () => {

    }
    return (<div>
        <Header />
        <Grid className="bodypart custom-container grid-row">
            <Grid item lg={2} xs={2} className="xs_none tl-hide">
                <List
                    component="nav"
                    className="side_list"
                    aria-label="secondary mailbox folders"
                >
                    <ListItemLink href="#simple-list" className="active">
                        <ListItemText primary="Sports" />
                    </ListItemLink>
                    <ListItemLink href="#simple-list">
                        <ListItemText primary="In-Play" />
                    </ListItemLink>
                    <ListItemLink href="#simple-list">
                        <ListItemText primary="Cricket" />
                    </ListItemLink>
                </List>
            </Grid>
            <Grid item lg={5} xs={12} className="mini-games-grid">

                <AppBar position="static" className="box_none">
                    <div className="mobile-card-tabs">

                        <>
                            <div className="sport-heading-container">
                                <span className="sport-heading">Our Live Casino</span>
                            </div>
                            <TabContext>
                                <TabList
                                    onChange={handleChange}
                                    className="maintabs mobile_tab"
                                    variant="fullWidth"
                                    aria-label="simple tabs example"
                                >
                                    <Tab
                                        className="main_tab"
                                        icon={<MiniGames event="Teen Patti T20" image={openTeen} />}
                                        onClick={() => history.push('/casino-bets/t20')}
                                    />
                                    <Tab
                                        className="main_tab"
                                        icon={<MiniGames event="Teen Patti 1Day" image={liveTeenpatti} />}
                                        onClick={() => history.push('/casino-bets/ltp')}
                                    />
                                    <Tab
                                        className="main_tab"
                                        icon={
                                            <MiniGames
                                                event="7 Up Down"
                                                image={sevenUpDown}
                                            />
                                        }
                                        onClick={() => history.push('/casino-bets/7ud')}
                                    />
                                    <Tab
                                        className="main_tab"
                                        icon={
                                            <MiniGames
                                                event="Ander Bahar"
                                                image={anderBaher}
                                            />
                                        }
                                        onClick={() => history.push('/casino-bets/ab')}
                                    />

                                    <Tab
                                        className="main_tab"
                                        icon={
                                            <MiniGames
                                                event="Dragon Tiger"
                                                image={dragonTiger}
                                            />
                                        }
                                        onClick={() => history.push('/casino-bets/dt20')}
                                    />
                                    <Tab
                                        className="main_tab"
                                        icon={
                                            <MiniGames
                                                event="32 Cards"
                                                image={cardsLive}
                                            />
                                        }
                                        onClick={() => history.push('/casino-bets/32c')}
                                    />

                                    <Tab
                                        className="main_tab"
                                        icon={
                                            <MiniGames
                                                event="Amar Akbar Anthony"
                                                image={amarAkbarAnothny}
                                            />
                                        }
                                        onClick={() => history.push('/casino-bets/aaa')}
                                    />
                                </TabList>

                            </TabContext>
                        </>
                    </div>
                </AppBar>


            </Grid>
        </Grid>
        <Footer />
    </div>);
}

export default CasinoList;