
import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, List, ListItem, Switch, TextareaAutosize, Chip, ListItemText, FormControl, NativeSelect, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Header from './Header'
import Footer from './Footer'
// import React, { useState, useEffect } from 'react';
import { Table, TableBody, MenuItem, InputLabel, Select, Menu, Checkbox, TableCell, TableContainer, TableHead, Paper, Dialog, TableRow, DialogActions, DialogTitle, DialogContentText, DialogContent, Button, Modal, Typography, Fade, Backdrop } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ToastContainer, toast } from 'react-toastify';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Appconfig from '../config/config';

import axios from 'axios';
import moment from 'moment';
import { spacing } from '@material-ui/system';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { useParams, useHistory } from "react-router-dom";

import './admin.css';
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function BetDeleteEventsBet() {
    const history = useHistory();
    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }
    const params = useParams();
    const [event_id, setEventId] = React.useState(params.event_id);
    const [EventMatchList, setEventMatchList] = React.useState([]);
    const userInfo = JSON.parse(window.sessionStorage.getItem('userData'));
    const [EventMatchId, setEventMatchId] = React.useState('');
    const [marketList, setmarketList] = React.useState([]);
    const [selectedIndex, setSelectedUserIndex] = React.useState([]);
    const [chekeduser, setSelectedUser] = React.useState(Array);
    const [dialougeOpen, setDialougeOpen] = React.useState(false);
    const [deleteOrCancel, setDeleteOrCancel] = React.useState('');
    useEffect(() => {
        eventMarket(params.event_id)
    }, [])
    const columns = [

        {
            field: 'id',
            headerName: 'S.NO',
            editable: true,
            width: 58
        },
        {
            field: 'user_name',
            headerName: 'User',
            sortable: false,
            width: 67,
            disableClickEventBubbling: true,
            renderCell: rowData => {
                return (rowData.row.userdetail[0].user_name)
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: false,
            width: 67,
            disableClickEventBubbling: true,
        },
        {
            field: 'market_name',
            headerName: 'Market',
            sortable: false,
            width: 90,
            disableClickEventBubbling: true,
        },
        {
            field: 'runner_name',
            headerName: 'Name',
            sortable: false,
            width: 130,
            disableClickEventBubbling: true,
        },
    ];
    function eventMarket(eventId) {

        let tmpEventList;
        var data = JSON.stringify({
            // "user_id": userInfo._id,
            "event_id": eventId
        });


        var config = {
            method: 'post',
            url: `${Appconfig.apiUrl}betting/getBettingByEventId`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setmarketList(response.data.resultData);
                console.log(response.data.resultData)
                tmpEventList = response.data.resultData;
                const newEventMatchList = tmpEventList.map((marketList, index) => {
                    tmpEventList[index]['id'] = index + 1;
                });
                console.log('after match odds click', response.data.resultData);
                setmarketList(tmpEventList)

                //  console.log(EventList);


                // console.log(EventList);
            })
            .catch(function (error) {
                console.log(error);
            }).then(() => {

                if (!tmpEventList.length) {
                    document.getElementsByClassName("MuiDataGrid-window")[0].innerHTML = "<div class='no-row'><span>No Rows</span></div>"
                }

            });


    }

    const classes = useStyles();
    function setSelectedUsers(newUserList) {
        let tmpSelectUserList = [...newUserList];

        console.log("tmpSelectUserList", tmpSelectUserList);
        setSelectedUser(tmpSelectUserList);
        console.log("select", chekeduser);
    }
    function deleteEventList() {
        if (deleteOrCancel == "delete") {
            handleAction();
        }
        if (deleteOrCancel == "cancel") {
            handleCancelAction();
        }
    }
    const handleAction = () => {


        var axios = require("axios");
        var data = JSON.stringify({
            bet_id: chekeduser,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}betting/deletebets`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.result) {
                    eventMarket(params.event_id)
                    notify(response.data.resultMessage, "success");
                    setSelectedUserIndex([]);
                    handleClickDialogeClose();
                }
                else {
                    notify(response.data.resultMessage, "error");
                    //console.log(JSON.stringify(response.data));
                }

            })
            .catch(function (error) {
                notify("Some Error Occured", "error");
                console.log(error);
            });

    };

    const handleCancelAction = () => {

        var axios = require("axios");
        var data = JSON.stringify({
            bet_id: chekeduser,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}settlement/cancelBets`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.result) {
                    eventMarket(params.event_id)
                    notify(response.data.resultMessage, "success");
                    setSelectedUserIndex([]);
                    handleClickDialogeClose()
                }
                else {
                    notify(response.data.resultMessage, "error");
                    //console.log(JSON.stringify(response.data));
                }

            })
            .catch(function (error) {
                notify("Some Error Occured", "error");
                console.log(error);
            });

    };

    function confirmDelete(type) {
        setDeleteOrCancel(type);
        setDialougeOpen(true);
    }
    function handleClickDialogeOpen() {
        setDialougeOpen(true);
    }
    function handleClickDialogeClose() {
        setDialougeOpen(false);
    }

    function notify(message, type) {
        toast.configure();
        if (type === 'error') {
            toast.error(message, {
                toastId: "error"
            });
        }
        else {
            toast.success(message, {
                toastId: "success"
            });
        }
    }

    return (
        <div className={classes.root}>
            <Header />
            <Grid className="bodypart custom-container grid-row">
                <Grid item lg={2} xs={2} className="xs_none tl-hide">
                    <List component="nav" className="side_list" aria-label="secondary mailbox folders">
                        <ListItemLink href="#simple-list" className="active">
                            <ListItemText primary="Sports" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="In-Play" />
                        </ListItemLink>
                        <ListItemLink href="#simple-list">
                            <ListItemText primary="Cricket" />
                        </ListItemLink>
                    </List>
                </Grid>

                <Grid item lg={10} xs={12} className="report-section">

                    <div className="datatable_heading">
                        Bettings Listing
                        <div style={{ width: "45%" }}>
                            <Button style={{ float: "right" }} className="btn-sm-danger" disabled={chekeduser.length > 0 ? false : true} onClick={() => confirmDelete('delete')}>
                                Delete
                            </Button>
                        </div>
                        <div style={{ width: "25%" }}>
                            <Button style={{ float: "right" }} className="btn-sm-danger" disabled={chekeduser.length > 0 ? false : true} onClick={() => confirmDelete('cancel')}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', height: '100vh' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                enableSelection={true}
                                checkboxSelection
                                className="block-market"
                                onSelectionModelChange={(e) => {
                                    const tmpUserList = e;
                                    const newUserList = tmpUserList.map((value, index) => {
                                        return marketList[value - 1]._id;
                                    });

                                    setSelectedUsers(newUserList);
                                    setSelectedUserIndex(e);
                                    console.log("fsadf", selectedIndex);
                                }}
                                selectionModel={selectedIndex}
                                rows={marketList}
                                columns={columns}
                                pageSize={100}
                                disableSelectionOnClick
                            />
                        </div>
                    </div>

                </Grid>
            </Grid>
            <Dialog
                open={dialougeOpen}
                onClose={handleClickDialogeClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to {deleteOrCancel} the bet?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClickDialogeClose} color="secondary" >
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={deleteEventList} color="primary" autoFocus>
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>

            <Footer />
        </div >
    );
}