import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./Header";
import Footer from "./Footer"
import {
  Table,
  TableBody,
  CardContent,
  Card,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Dialog,
  TableRow,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Grid,
  Button,
  List,
  ListItemText,
  Modal,
  Typography,
  ListItem,
  Fade,
  Backdrop,
  TextField,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { ToastContainer, toast } from "react-toastify";
import Appconfig from "../config/config";
import axios from "axios";

import CancelIcon from "@material-ui/icons/Cancel";
const useStyles = makeStyles({
  table: {
    marginTop: 10,
    margin: 10,
  },
  container: {
    // padding: 5,
    // marginTop:8
  },
  root: {
    width: "100%",
    // marginTop: 140,
    margin: 10,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
  },
  headerdiv: {
    // marginTop: 10,
    padding: 15,
  },
  horizontal: {
    display: "flex",
    flexDirection: "row",
  },
});
function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function AdminFund() {
  const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));
  const [chipList, setchipList] = React.useState([]);
  const [opens, setOpens] = React.useState(false);
  const [editopens, setEditOpens] = React.useState(false);
  const [dialougeOpen, setDialougeOpen] = React.useState(false);
  const [deletechipid, setdeletechipid] = React.useState("");
  const [formData, setFormData] = useState({
    amount: "",
    password: "",
    remarks: "",
  });

  const [currentBalance, setCurrentBalance] = React.useState(0);
  const [newBalance, setNewBalance] = React.useState(0);

  const [formEditData, setEditFormData] = useState({
    chip_name: "",
    chip_value: "",
    chip_id: "",
  });
  const handleInputChange = (event) => {
    if (event.target.name == "amount") {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
      let tempBalance = parseFloat(newBalance) + parseFloat(event.target.value);

      console.log(tempBalance);

      if (tempBalance == NaN) {
      } else {
        setNewBalance(tempBalance);
      }
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleUpdateInputChange = (event) => {
    setEditFormData({
      ...formEditData,
      [event.target.name]: event.target.value,
    });
  };
  const modalOpen = () => {
    setOpens(true);
  };

  const modalClose = () => {
    setOpens(false);
  };

  const editmodalClose = () => {
    setEditOpens(false);
  };

  const classes = useStyles();

  useEffect(() => { window.scroll(0,0)
    getBalance();
  }, []);

  function getBalance() {
    var data = JSON.stringify({
      user_id: userInfo._id,
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}ledger/getUserBalance`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.result == 0) {
        } else {
          setCurrentBalance(response.data.resultData.balance);
          setNewBalance(response.data.resultData.balance);
        }
        console.log(chipList);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function addFund(id) {
    var data = JSON.stringify({
      user_id: userInfo._id,
      password: formData.password,
      amount: formData.amount,
      remarks: formData.remarks,
    });

    console.log("Add Fund Api Call", data);
    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}ledger/addFund`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        setOpens(false);
        // getChips();
        setFormData({
          amount: "",
          password: "",
          remarks: "",
        });
        getBalance()
        if (response.data.result == 0) {
          notify(response.data.message, "error");
        } else {
          notify("Fund Deposit successfully", "success");
        }
      })
      .catch(function (error) {
        notify("error occured", "error");
        console.log(error);
      });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    addFund();
    console.log();
  };

  function notify(message, type) {
    toast.configure();
    if (type === 'error') {
      toast.error(message, {
        toastId: "error"
      });
    }
    else {
      toast.success(message, {
        toastId: "success"
      });
    }
  }

  return (
    <>
      <Header />


      <Grid className="bodypart custom-container grid-row">
        <Grid item lg={2} xs={2} className="xs_none tl-hide">
          <List
            component="nav"
            className="side_list"
            aria-label="secondary mailbox folders"
          >
            <ListItemLink href="#simple-list" className="active">
              <ListItemText primary="Sports" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="In-Play" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="Cricket" />
            </ListItemLink>
          </List>
        </Grid>

        <Grid container style={{ display: "block" }}>
          <Card className="report-card">
            <div className="datatable_heading">Admin Funds</div>
            <CardContent>
              <ValidatorForm onSubmit={handleSubmit} autoComplete="off">
                <div className={classes.root}>
                  <Grid container justifyContent="center"  spacing={3}>
                    <Grid item sm={6} xs={12}>
                      <Typography style={{ marginRight: 7 }}>
                        Current Balance
                        &nbsp;{currentBalance.toFixed(2)}</Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextValidator
                        size="small"
                        type="text"
                        value={formData.amount}
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                        onChange={handleInputChange}
                        id="outlined-basic"
                        label="Deposit Fund *"
                        name="amount"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextValidator
                        size="small"
                        type="password"
                        value={formData.password}
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                        onChange={handleInputChange}
                        id="outlined-basic"
                        label="Password *"
                        name="password"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextValidator
                        size="small"
                        type="text"
                        value={formData.remarks}
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                        onChange={handleInputChange}
                        id="outlined-basic"
                        label="Remark"
                        name="remarks"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography style={{ marginRight: 7 }}>
                        Total Balance
                        &nbsp;{newBalance.toFixed()}</Typography>
                    </Grid>
                    <Grid item sm={6} xs={12} >
                     <div>
                      <Button variant="contained" color="primary">
                        Reset
                      </Button>
                      &nbsp;
                      <Button type="submit" variant="contained" color="secondary">
                        Submit
                      </Button>
                      </div>
                    </Grid>

                  </Grid>
                </div>



              </ValidatorForm>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
