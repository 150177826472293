import React, { useState, useEffect } from "react";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import {
  RadioGroup,
  Link,
  FormControlLabel,
  Radio,
  makeStyles,
  Grid,
  List,
  ListItem,
  Switch,
  TextareaAutosize,
  Chip,
  ListItemText,
  FormControl,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Header from "./Header";
import Footer from "./Footer";
// import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  MenuItem,
  InputLabel,
  Select,
  Menu,
  Checkbox,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Dialog,
  TableRow,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Button,
  Modal,
  Typography,
  Fade,
  Backdrop,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { ToastContainer, toast } from "react-toastify";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Appconfig from "../config/config";

import axios from "axios";
import moment from "moment";
import { spacing } from "@material-ui/system";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import "./admin.css";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function RunningMarketAnalysis() {
  const history = useHistory();
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  const userInfo = JSON.parse(window.sessionStorage.getItem("userData"));

  const [EventList, setEventList] = React.useState([]);

  const [pageCount, setPageCount] = React.useState(10);

  const classes = useStyles();

  useEffect(() => { window.scroll(0,0)
    getEventList();
  }, []);

  function getEventList() {
    var data = JSON.stringify({
      user_id: userInfo._id,
    });

    var config = {
      method: "post",
      url: `${Appconfig.apiUrl}betting/runningMarketAnalysis`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setEventList(response.data.resultData);
        let tmpEventList = response.data.resultData;
        const newEventList = tmpEventList.map((EventList, index) => {
          tmpEventList[index]["id"] = index + 1;
        });
        console.log("data", tmpEventList);
        setEventList(tmpEventList);
        // console.log(EventList);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const columns = [

    { field: 'id', width: 91, headerName: 'S.No.' },
    {
      field: 'event_name',
      headerName: 'Match Name',
      editable: false,
      width: 252,
      renderCell: rowData => {
        return (<Link component="button"
          onClick={() => history.push(`event-detail/${rowData.row.event_id}`)}>{rowData.row.event_name}</Link>)
      }
    },
    {
      field: 'event_type',
      headerName: 'Sport Name',
      editable: true,
      width: 133,

    },

    {
      field: 'status',
      headerName: 'Match Status',
      editable: true,
      width: 141,

    },
    {
      field: 'team_a',
      headerName: 'Team A',
      editable: true,
      width: 105,
      renderCell: rowData => {
        return (
          (rowData.row.team_a).toFixed(2)

        );
      }
    },
    {
      field: 'team_b',
      headerName: 'Team B',
      editable: true,
      width: 105,
      renderCell: rowData => {
        return (
          (rowData.row.team_b).toFixed(2)

        );
      }
    },
    {
      field: 'draw',
      headerName: 'draw',
      editable: true,
      width: 105,

    },

  ];

  return (
    <div >
      <Header />
      <Grid className="bodypart custom-container grid-row">
        <Grid item lg={2} xs={2} className="xs_none tl-hide">
          <List
            component="nav"
            className="side_list"
            aria-label="secondary mailbox folders"
          >
            <ListItemLink href="#simple-list" className="active">
              <ListItemText primary="Sports" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="In-Play" />
            </ListItemLink>
            <ListItemLink href="#simple-list">
              <ListItemText primary="Cricket" />
            </ListItemLink>
          </List>
        </Grid>

        <Grid item lg={10} xs={12} className="report-section">


          <div className="datatable_heading">
            My Market
            <div style={{ width: "80%" }}>
              <button
                className="extra-sm-btn"
                onClick={() => history.goBack()}

              >
                Back
              </button>
            </div>
          </div>

          <Grid container spacing={0}>
            <Grid item sm={6} xs={6} md>
              <div className="show_data">
                <span className="showentries">Show</span>
                <span>
                  <FormControl className={classes.formControl}>
                    <NativeSelect
                      value={pageCount}
                      onChange={(e) => setPageCount(e.target.value)}
                      name="age"
                      className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'age' }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </NativeSelect>
                  </FormControl>
                </span>
                <span className="showentries">entries</span>
              </div>
            </Grid>
            <Grid item sm={6} xs={6} md>
              <div className="search_data" style={{ display: 'flex', placeItems: 'center' }}>
                <span className="showentries">Search:</span>
                <span>
                  <form className={classes.root} noValidate autoComplete="off">
                    <TextField variant="outlined" size="small" id="standard-basic" />
                  </form>
                </span>
              </div>
            </Grid>
          </Grid>
          <div style={{ display: 'flex', height: '100vh' }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                //  components={{
                //   Toolbar: GridToolbar,
                // }}
                // filterModel={{
                //   items: [{ columnField: 'commodity', operatorValue: 'contains', value: 'rice' }],
                // }}
                rows={EventList}
                columns={columns}
              // pageSize={pageCount}
              // disableSelectionOnClick
              />
            </div>
          </div>

        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}


